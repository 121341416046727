<template>
    <div>
        <Initial v-if="step === 'initial'" />
        <SignUp v-if="step === 'signUp'" />
        <PasswordLogin v-if="step === 'passwordLogin'" />
    </div>
</template>

<script>
	import { provide, ref } from 'vue'
    import Initial from './Initial.vue';
    import SignUp from './SignUp.vue';
    import PasswordLogin from './PasswordLogin.vue'

    import { useFormValidator } from '../../lib/formValidator';

    export default {
	    props: [],
        components: {
            Initial,
            SignUp,
            PasswordLogin
        },
	    setup(props) {
            const { validationErrors } = useFormValidator()
            const step = ref('initial')
            const auth = ref({
                first_name: null,
                last_name: null,
                email: null,
                dob: null,
                password: null,
                optin: true
            })

            provide('step', step)
            provide('auth', auth)
            provide('validationErrors', validationErrors)

            return {
                step,
                auth,
                validationErrors
            }
		}
	}
</script>
