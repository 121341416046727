<template>
    <div class="space-y-8 py-5">
        <h1 class="text-5xl font-bold text-center">Layout Features</h1>

        <div v-if="['house', 'apartment'].includes(listing.features.construction.property_type)" class="space-y-8">
            <div class="space-y-2">
                <h2 class="font-bold">Number of rooms</h2>

                <div class="border w-full rounded-lg flex items-center overflow-hidden">
                    <input type="number" class="p-5 w-full rounded-lg"  v-model="listing.features.layout.numbers_of_rooms" />
                    <div class="px-3 text-lg">room(s)</div>
                </div>
            </div>

            <div class="space-y-2">
                <h2 class="font-bold">Numbers of bedrooms</h2>

                <div class="border w-full rounded-lg flex items-center overflow-hidden">
                    <input type="number" class="p-5 w-full rounded-lg"  v-model="listing.features.layout.numbers_of_bedrooms" />
                    <div class="px-3 text-lg">bedroom(s)</div>
                </div>
            </div>

            <div class="space-y-2">
                <h2 class="font-bold">Numbers of bath rooms</h2>

                <div class="border w-full rounded-lg flex items-center overflow-hidden">
                    <input type="number" class="p-5 w-full rounded-lg"  v-model="listing.features.layout.numbers_of_bath_rooms" />
                    <div class="px-3 text-lg whitespace-nowrap">bath room(s)</div>
                </div>
            </div>

            <div class="space-y-2">
                <h2 class="font-bold">Bathroom facilities</h2>

                <button class="w-full bg-primary p-3 text-white font-bold rounded-lg" @click="$refs.bathroomFacilityModal.openModal()">Add bathroom facility</button>
            </div>

            <div class="space-y-2">
                <h2 class="font-bold">Numbers of stories</h2>

                <div class="border w-full rounded-lg flex items-center overflow-hidden">
                    <input type="number" class="p-5 w-full rounded-lg"  v-model="listing.features.layout.numbers_of_stories" />
                    <div class="px-3 text-lg whitespace-nowrap">stories</div>
                </div>
            </div>

            <div class="space-y-2">
                <h2 class="font-bold">Has Basement</h2>

                <div class="flex space-x-3">
                    <button class="w-1/2 border rounded-lg p-3 hover:border-primary hover:text-primary" @click="listing.features.layout.has_basement = true" v-bind:class="{'border-primary text-primary font-semibold': listing.features.layout.has_basement === true}">Yes</button>
                    <button class="w-1/2 border rounded-lg p-3 hover:border-primary hover:text-primary" @click="listing.features.layout.has_basement = false" v-bind:class="{'border-primary text-primary font-semibold': listing.features.layout.has_basement === false}">No</button>
                </div>
            </div>

            <div class="space-y-2">
                <h2 class="font-bold">Facilities</h2>

                <button class="w-full bg-primary p-3 text-white font-bold rounded-lg" @click="$refs.facilityModal.openModal()">Add facility</button>
            </div>
        </div>

    </div>

    <BathroomFacilityModal ref="bathroomFacilityModal" />
    <FacilityModal ref="facilityModal" />
</template>

<script>
	import { ref, inject } from 'vue'
    import ToggleSwitch from '../ToggleSwitch.vue';
    import BathroomFacilityModal from './BathroomFacilityModal.vue'
    import FacilityModal from './FacilityModal.vue'

    export default {
	    props: [],
        components: {
            ToggleSwitch,
            BathroomFacilityModal,
            FacilityModal
        },
	    setup(props) {
            const listing = inject('listing')

            const validate = () => {

            }

            return {
                listing,
                validate
            }
        }
    }
</script>
