<template>
    <li class="p-3 flex items-center space-x-5">
        <div class="text-lg font-semibold grow">
            {{ facility.name }}
        </div>


        <div>
            <div class="border rounded-lg divide-x flex items-center hover:shadow-lg w-32 h-12 justify-between overflow-hidden">
                <button class="w-1/2 h-full flex justify-center items-center" @click="setHas(false)" v-bind:class="{'bg-primary text-white': has === false}">
                    <i class="fi fi-rr-circle-xmark text-2xl flex items-center"></i>
                </button>
                <button class="w-1/2 h-full flex justify-center items-center" @click="setHas(true)" v-bind:class="{'bg-primary text-white': has === true}">
                    <i class="fi fi-rr-check-circle text-2xl flex items-center"></i>
                </button>
            </div>
        </div>
    </li>
</template>

<script>
    import { ref } from 'vue'

    export default {
        props: ['facility', 'modelValue'],
        watch: {
            has(value) {
                if(this.$props.modelValue === null || this.$props.modelValue.length === 0) {
                    this.$emit('update:modelValue', [{
                        name: this.$props.facility.name,
                        has: value
                    }])

                    return
                }

                const facility = this.$props.modelValue.find(f => f.name === this.$props.facility.name)

                if(facility) {
                    this.$emit('update:modelValue', this.$props.modelValue.map((f) => {
                        if(f.name === this.$props.facility.name) {
                            f.has = value
                        }

                        return f
                    }))

                    return
                }

                this.$props.modelValue.push({
                    name: this.$props.facility.name,
                    has: value
                })

                this.$emit('update:modelValue', this.$props.modelValue)
            }
        },
	    setup(props, { emit }) {
            const has = ref((props.modelValue && props.modelValue.find(f => f.name === props.facility.name))? props.modelValue.find(f => f.name === props.facility.name).has : null)

            const setHas = (state) => {
                has.value = state
            }

            return {
                has,
                setHas
            }
        }
    }
</script>
