<template>
    <input v-model="inputValue" />
</template>


<script>
	import { ref } from 'vue'
    import { usePreferences } from '../lib/preferences'

    export default {
	    props: ['modelValue'],
        components: {

        },
        watch: {
            inputValue(value) {
                this.inputValue = value.toString().replace(/\D/g,'').replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, this.preferences.currency.thousand_separator)
                this.$emit('update:modelValue', value.toString().replace(/\D/g,''))
            },
            'preferences.currency'(value) {
                if(this.inputValue) {
                    this.inputValue = this.inputValue.toString().replace(/\D/g,'').replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, value.thousand_separator)
                }
            }
        },
	    setup(props) {
            const { preferences } = usePreferences()

            const inputValue = ref((props.modelValue)? props.modelValue.toString().replace(/\D/g,'').replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, preferences.value.currency.thousand_separator) : '')

            return {
                inputValue,
                preferences
            }
		}
	}
</script>
