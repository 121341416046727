<template>
    <div>
        <dialog class="block relative w-full z-50 p-0 z-10" v-if="modalShown">
            <div class="md:p-7 flex justify-center items-start overflow-x-hidden overflow-y-auto fixed left-0 top-0 w-full h-full bg-gray-900 bg-opacity-50 z-50">
                <div class="flex 2xl:w-1/3 xl:w-1/2 md:w-2/3 w-full relative items-center">
                    <div class="bg-white w-full md:rounded-xl shadow-xl relative z-10 overflow-hidden">
                        <div class="flex flex-col items-start w-full">
                            <div class="md:px-5 md:py-5 p-3 flex items-center w-full border-b">

                                <button @click="modalShown = false" class="absolute w-8 h-8 hover:bg-gray-100 rounded-full flex justify-center items-center">
                                    <i class="fi fi-rr-cross-small text-xl flex items-center"></i>
                                </button>

                                <div class="text-center w-full space-y-1">
                                    <div class="text-center text-lg font-bold">
                                        <span>Contact seller</span>
                                    </div>

                                    <div class="text-gray-500 text-sm">
                                        {{ $props.listing.info.title }}
                                    </div>
                                </div>

                            </div>

                            <div class="w-full p-5">
                                <div class="space-y-8">

                                    <div v-if="user" class="space-y-8">
                                        <div class="space-y-1">
                                            <label class="text-sm font-semibold text-gray-600">Name</label>
                                            <div>
                                                {{ user.first_name }} {{ user.last_name }}
                                            </div>
                                        </div>

                                        <div class="space-y-1">
                                            <label class="text-sm font-semibold text-gray-600">Email</label>
                                            <div>
                                                {{ user.email }}
                                            </div>
                                        </div>
                                    </div>

                                    <div v-else class="space-y-8">
                                        <div class="space-y-1">
                                            <div class="border border-gray-300 rounded-lg overflow-hidden divide-x flex">
                                                <div class="relative w-1/2">
                                                    <input id="first_name" ref="firstName" type="text" class="block px-2.5 pb-2.5 pt-5 w-full text-gray-900 bg-white appearance-none focus:border-2 focus:border-gray-800 focus:outline-none focus:ring-0 peer rounded-lg" placeholder=" " v-model="contactForm.first_name" v-bind:class="{'border-red-500 focus:border-red-500 border-2 bg-red-100': hasValidationErrors('firstName') }" />
                                                    <label for="first_name" class="absolute text-gray-500 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] start-2.5 peer-focus:text-primary  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto" v-bind:class="{'text-red-500 peer-focus:text-red-500': hasValidationErrors('firstName') }">First name</label>
                                                </div>

                                                <div class="relative w-1/2">
                                                    <input id="last_name" type="text" class="block px-2.5 pb-2.5 pt-5 w-full text-gray-900 bg-white appearance-none focus:border-2 focus:border-gray-800 focus:outline-none focus:ring-0 peer rounded-lg" placeholder=" " v-model="contactForm.last_name" v-bind:class="{'border-red-500 border-2 focus:border-red-500 border-2 bg-red-100': hasValidationErrors('lastName') }" />
                                                    <label for="last_name" class="absolute text-gray-500 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] start-2.5 peer-focus:text-primary  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto" v-bind:class="{'text-red-500 peer-focus:text-red-500': hasValidationErrors('lastName') }">Last name</label>
                                                </div>
                                            </div>

                                            <div v-if="hasValidationErrors('firstName') || hasValidationErrors('lastName')" class="text-sm flex items-center space-x-1 text-red-500">
                                                <i class="fi fi-rr-exclamation flex items-center"></i>
                                                <span>Your name is not valid.</span>
                                            </div>
                                        </div>

                                        <div class="space-y-2">
                                            <div class="space-y-1">
                                                <div class="relative">
                                                    <input id="email" type="text" class="block rounded-lg px-2.5 pb-2.5 pt-5 w-full text-gray-900 bg-white border border-gray-300 appearance-none focus:border-2 focus:border-gray-800 focus:outline-none focus:ring-0 peer" placeholder=" " v-model="contactForm.email" v-bind:class="{'border-red-500 border-2 focus:border-red-500 border-2 bg-red-100': hasValidationErrors('email') || !validator.isEmpty(contactForm.email ?? '') && !validator.isEmail(contactForm.email ?? '') }" />
                                                    <label for="email" class="absolute text-gray-500 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] start-2.5 peer-focus:text-primary  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto" v-bind:class="{'text-red-500 peer-focus:text-red-500': hasValidationErrors('email') || !validator.isEmpty(contactForm.email ?? '') && !validator.isEmail(contactForm.email ?? '') }">Email</label>
                                                </div>
                                                <div class="text-sm flex items-center space-x-1 text-red-500" v-if="hasValidationErrors('email')" >
                                                    <i class="fi fi-rr-exclamation flex items-center"></i>
                                                    <span>Enter a valid email.</span>
                                                </div>
                                            </div>

                                            <label v-if="!userIsKnown" class="flex items-center space-x-5 cursor-pointer">
                                                <input type="checkbox" class="w-5 h-5 accent-gray-800" v-model="userWantToCreateAccount">
                                                <span class="text-xs"><strong>Create an account</strong> to personalize your property search, manage listings, and connect directly with sellers for exclusive access and updates.</span>
                                            </label>
                                        </div>

                                        <CreateAccountPassword ref="passwordInput" v-if="userWantToCreateAccount" :firstName="contactForm.first_name" :lastName="contactForm.last_name" :email="contactForm.email" v-model="contactForm.password" />

                                    </div>

                                    <div v-if="contactForm.ticket_messagable_type === null" class="space-y-1">
                                        <div class="relative">
                                            <textarea id="message" type="text" class="block rounded-lg px-2.5 pb-2.5 pt-5 w-full text-gray-900 bg-white border border-gray-300 appearance-none focus:border-2 focus:border-gray-800 focus:outline-none focus:ring-0 peer" placeholder=" " v-model="contactForm.message" rows="10" v-bind:class="{'border-red-500 border-2 focus:border-red-500 border-2 bg-red-100': hasValidationErrors('message') }"></textarea>
                                            <label for="message" class="absolute text-gray-500 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] start-2.5 peer-focus:text-primary  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto" v-bind:class="{'text-red-500 peer-focus:text-red-500': hasValidationErrors('message') }">Message</label>
                                        </div>

                                        <div class="text-sm flex items-center space-x-1 text-red-500" v-if="hasValidationErrors('message')" >
                                            <i class="fi fi-rr-exclamation flex items-center"></i>
                                            <span>Enter a message.</span>
                                        </div>
                                    </div>

                                    <div v-if="contactForm.ticket_messagable_type == 'Appointment'" class="space-y-5" v-bind:class="{'border-2 border-red-500 rounded p-3': hasValidationErrors('appointment_datetime')}">
                                        <div class="w-full">
                                            <ul class="flex justify-between h-18">
                                                <li class=" px-3 py-1 bg-gray-100 flex flex-col items-center justify-center rounded-md" v-bind:class="{'cursor-not-allowed text-gray-400': addDays == 0, 'hover:cursor-pointer': addDays > 0}" @click="addDays -= 7">
                                                    <i class="fi fi-rr-angle-small-left"></i>
                                                </li>

                                                <li v-for="x in 7" class=" px-3 py-1 bg-gray-100 flex flex-col items-center rounded-md hover:border hover:cursor-pointer" v-bind:class="{'border border-primary': selectedAppointmentDateTime.date && dayjs().add(x + addDays, 'day').isSame(selectedAppointmentDateTime.date, 'day') }" @click="selectedAppointmentDateTime.date = dayjs().add(x + addDays, 'day')">
                                                    <div class="text-xs">{{ dayjs().add(x + addDays, 'day').format('ddd') }}</div>
                                                    <div class="text-xl font-bold">{{ dayjs().add(x + addDays, 'day').format('DD') }}</div>
                                                    <div class="text-xs">{{ dayjs().add(x + addDays, 'day').format('MMM') }}</div>
                                                </li>

                                                <li class=" px-3 py-1 bg-gray-100 flex flex-col items-center justify-center rounded-md hover:cursor-pointer" @click="addDays += 7">
                                                    <i class="fi fi-rr-angle-small-right"></i>
                                                </li>
                                            </ul>
                                        </div>

                                        <div v-if="selectedAppointmentDateTime.date" class="space-y-5">
                                            <button v-if="addTime > 0" class="w-full bg-gray-100 rounded-md text-center py-2 hover:border text-sm flex items-center justify-center space-x-1" @click="addTime -= 5">
                                                <span>Earlier</span>
                                                <i class="fi fi-rr-caret-up flex items-center"></i>
                                            </button>

                                            <ul class="grid grid-cols-2 gap-4">
                                                <li v-for="x in 10" class="bg-gray-100 rounded-md text-center py-2 hover:border hover:cursor-pointer" @click="selectedAppointmentDateTime.time = dayjs().hour(9).minute(0).add((x / 2) + addTime - 0.5, 'hour')" v-bind:class="{'border border-primary': selectedAppointmentDateTime.time && selectedAppointmentDateTime.time.isSame(dayjs().hour(9).minute(0).add((x / 2) + addTime - 0.5, 'hour'), 'hour') && selectedAppointmentDateTime.time.isSame(dayjs().hour(9).minute(0).add((x / 2) + addTime - 0.5, 'hour'), 'minute') }">
                                                    {{ dayjs().hour(9).minute(0).add((x / 2) + addTime - 0.5, 'hour').format('HH:mm') }}
                                                </li>
                                            </ul>

                                            <button v-if="addTime < 5" class="w-full bg-gray-100 rounded-md text-center py-2 hover:border text-sm flex items-center justify-center space-x-1" @click="addTime += 5">
                                                <span>Later</span>
                                                <i class="fi fi-rr-caret-down flex items-center"></i>
                                            </button>
                                        </div>

                                    </div>

                                    <button v-if="!loading" class="bg-primary w-full rounded-lg text-white font-semibold py-3 px-5 hover:shadow-xl" @click="sendMessage()">
                                        <span v-if="contactForm.ticket_messagable_type === null">Send message</span>
                                        <span v-if="contactForm.ticket_messagable_type == 'Appointment'">Request appointment</span>
                                    </button>
                                    <button v-else class="bg-gray-300 w-full rounded-lg text-white font-semibold py-3 px-5">
                                        <loading color="text-white" />
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div v-if="contactForm.ticket_messagable_type === null && mesageIsSent" class="absolute w-full h-full bg-black bg-opacity-80 top-0 z-10 flex flex-col items-center justify-center space-y-5">
                            <h1 class="text-white font-semibold text-2xl">Message sent!</h1>

                            <p class="w-2/3 text-white">
                                Thank you for reaching out! Your message has been sent successfully. A seller or real estate agent will contact you shortly to provide further details and answer any questions you may have. We appreciate your interest and look forward to assisting you in finding your ideal property.
                            </p>

                            <button class="w-2/3 py-3 bg-thirdly rounded text-white font-semibold" @click="finished()">Close</button>
                        </div>

                        <div v-if="contactForm.ticket_messagable_type == 'Appointment' && mesageIsSent" class="absolute w-full h-full bg-black bg-opacity-80 top-0 z-10 flex flex-col items-center justify-center space-y-5">
                            <h1 class="text-white font-semibold text-2xl">Appointment request sent!</h1>

                            <p class="w-2/3 text-white">
                                Thank you for requesting an appointment with the real estate agent! Your request has been successfully submitted. The real estate agent review your preferences and get back to you shortly to confirm the date and time of your appointment. Please check your email for further details. We look forward to assisting you with your real estate needs and helping you find your perfect home!
                            </p>

                            <button class="w-2/3 py-3 bg-thirdly rounded text-white font-semibold" @click="finished()">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </dialog>
    </div>
</template>

<script>
	import { ref, provide } from 'vue'
    import { useFormValidator } from '../lib/formValidator';
    import validator from 'validator';
    import CreateAccountPassword from './CreateAccountPassword.vue'
    import { useUser } from '../lib/user';
    import { useApi } from '../lib/api';
    import dayjs from 'dayjs'

    export default {
	    props: ['listing'],
        components: {
            CreateAccountPassword
        },
        watch: {
            'contactForm.email'(value) {
                if(validator.isEmail(this.contactForm.email ?? '')) {
                    this.checkKnownUser()
                }
            },
            selectedAppointmentDateTime: {
                handler(value) {
                    if(value.date) {
                        this.contactForm.ticket_messagable.date_time = value.date.format('YYYY-MM-DD HH:mm')
                    }

                    if(value.date && value.time) {
                        this.contactForm.ticket_messagable.date_time = value.date.hour(value.time.format('HH')).minute(value.time.format('mm')).format('YYYY-MM-DD HH:mm')
                    }
                },
                deep: true
            },
            addDays(value) {
                if(value < 0) {
                    this.addDays = 0
                }
            }
        },
	    setup(props) {
            const { validationErrors, hasValidationErrors, resetErrors } = useFormValidator()
            const { user, getUser } = useUser()
            const { post, data, loading } = useApi('ticket/listing/send-message')

            const modalShown = ref(false)
            const mesageIsSent = ref(false)
            const userIsKnown = ref(true)
            const passwordInput = ref(null)

            const selectedAppointmentDateTime = ref(null)

            const addDays = ref(0)
            const addTime = ref(0)

            const placeholderMessage = ref('Hello,\n\nI came across your property listing and am very interested. Could you please provide more details about the home?\n\nI have the following questions:\n\nThank you!')

            const userWantToCreateAccount = ref(false)

            const contactForm = ref(null)

            const resetMessage = (() => {
                selectedAppointmentDateTime.value = {
                    date: null,
                    time: null
                }

                contactForm.value = {
                    ticket_messagable_type: null,
                    ticket_messagable_id: null,
                    listing_id: props.listing.id,
                    first_name: null,
                    last_name: null,
                    email: null,
                    password: null,
                    message: placeholderMessage.value,
                    ticket_messagable: {
                        date_time: null
                    }
                }
            })

            const openModal = () => {
                contactForm.value.ticket_messagable_type = null

                modalShown.value = true
            }

            const planAView = () => {
                contactForm.value.ticket_messagable_type = 'Appointment'

                modalShown.value = true
            }

            const finished = () => {
                if(userWantToCreateAccount.value) {
                    getUser()
                }

                modalShown.value = false
                mesageIsSent.value = false
                userIsKnown.value = true
                userWantToCreateAccount.value = false

                resetMessage()
            }

            const sendMessage = () => {
                resetErrors()

                if(!user.value) {
                    if(validator.isEmpty(contactForm.value.first_name ?? '')) {
                        validationErrors.value.push('firstName')
                    }

                    if(userWantToCreateAccount.value && validator.isEmpty(contactForm.value.last_name ?? '')) {
                        validationErrors.value.push('lastName')
                    }

                    if(validator.isEmpty(contactForm.value.email ?? '')) {
                        validationErrors.value.push('email')
                    }

                    if(userWantToCreateAccount.value && !passwordInput.value.passwordIsOkay) {
                        validationErrors.value.push('password')
                    }
                }

                if(contactForm.value.ticket_messagable_type === null && validator.isEmpty(contactForm.value.body ?? '') && contactForm.value.message == placeholderMessage.value) {
                    validationErrors.value.push('message')
                }

                if(contactForm.value.ticket_messagable_type == 'Appointment') {
                    if(contactForm.value.ticket_messagable.date_time == null) {
                        validationErrors.value.push('appointment_datetime')
                    }
                }

                if(validationErrors.value.length === 0) {
                    post(contactForm.value).then(() => {
                        if(data.value.status) {
                            mesageIsSent.value = true
                        }
                    })
                }
            }

            const checkKnownUser = () => {
                const { post, data } = useApi('user/is-known')

                post({
                    email: contactForm.value.email
                }).then(() => {
                    if(data.value.status) {
                        userIsKnown.value = data.value.is_known
                    }
                }).catch(() => {
                    loading.value = false
                })
            }

            const setAppointmentTime = (time) => {
                contactForm.value.selectedAppointmentDateTime = contactForm.value.selectedAppointmentDateTime.hour(time.format('HH')).minute(time.format('mm'))
            }

            resetMessage()

            return {
                modalShown,
                openModal,
                hasValidationErrors,
                contactForm,
                validator,
                sendMessage,
                userWantToCreateAccount,
                user,
                loading,
                mesageIsSent,
                finished,
                checkKnownUser,
                userIsKnown,
                passwordInput,
                planAView,
                dayjs,
                addDays,
                addTime,
                setAppointmentTime,
                selectedAppointmentDateTime
            }
		}
	}
</script>


