<template>
    <div class="space-y-8 py-5">
        <h1 class="text-5xl font-bold text-center">Storage Features</h1>
        <div class="space-y-2 p-5">
            <h2 class="font-bold">Shed / Storage</h2>

            <ul class="border rounded-lg divide-y">
                <FacilityBooleanRow v-for="storageType in storageTypes" :facility="storageType" v-model="listing.features.storage.types" />
            </ul>
        </div>

        <div v-if="listing.features.storage.types && listing.features.storage.types.filter(f => f.has).length" class="space-y-8">
            <div class="space-y-2 p-5">
                <h2 class="font-bold">Storage has electricity?</h2>

                <div class="flex justify-between border rounded-lg divide-x overflow-hidden">
                    <button class="w-1/2 p-5 font-semibold" @click="listing.features.storage.has_electricity = true" v-bind:class="{'bg-primary text-white': listing.features.storage.has_electricity === true}">Yes</button>
                    <button class="w-1/2 p-5 font-semibold" @click="listing.features.storage.has_electricity = false" v-bind:class="{'bg-primary text-white': listing.features.storage.has_electricity === false}">No</button>
                </div>
            </div>

            <div class="space-y-2 p-5">
                <h2 class="font-bold">Storage is insulated?</h2>

                <div class="flex justify-between border rounded-lg divide-x overflow-hidden">
                    <button class="w-1/2 p-5 font-semibold" @click="listing.features.storage.is_insulated = true" v-bind:class="{'bg-primary text-white': listing.features.storage.is_insulated === true}">Yes</button>
                    <button class="w-1/2 p-5 font-semibold" @click="listing.features.storage.is_insulated = false" v-bind:class="{'bg-primary text-white': listing.features.storage.is_insulated === false}">No</button>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
	import { ref, inject } from 'vue'
    import FacilityBooleanRow from './FacilityBooleanRow.vue'

    export default {
	    props: [],
        components: {
            FacilityBooleanRow
        },
	    setup(props) {
            const listing = inject('listing')

            if(listing.value.features.storage.types === undefined) {
                listing.value.features.storage.types = []
            }

            const storageTypes = ref([
                { name: "detached_brick_storage"},
                { name: "attached_brick_storage"},
                { name: "detached_wooden_storage"},
                { name: "outside_plastic_storage_cabinet"},
                { name: "storage_box"}
            ])

            const validate = () => {

            }

            return {
                listing,
                validate,
                storageTypes
            }
        }
    }
</script>
