<template>
    <div class="relative overflow-hidden">
        <div class="h-full flex relative">
            <ImageCarouselSlide v-for="(image, x) in images" ref="imageSlide" class="flex-none h-full w-full absolute" :itemKey="x" @previousSlide="previousSlide" @nextSlide="nextSlide()" @click="$emit('openGallery', x)" >
                <img :src="image" class="h-full w-full object-cover" />
            </ImageCarouselSlide>
        </div>

        <button @click="previousSlide()" class="absolute top-1/2 left-3 bg-white z-30 bg-opacity-60 w-10 h-10 rounded-full flex justify-center items-center">
            <i class="fi fi-rr-angle-small-left flex items-center text-gray-800"></i>
        </button>
        <button @click="nextSlide()" class="absolute top-1/2 right-3 bg-white z-30 bg-opacity-60 w-10 h-10 rounded-full flex justify-center items-center">
            <i class="fi fi-rr-angle-small-right flex items-center text-gray-800"></i>
        </button>

        <div class="absolute bottom-3 right-3 z-20 justify-center space-x-2 bg-black bg-opacity-30 text-white inline-block text-sm p-1 rounded">
            {{ (activeSlide + 1) }} / {{ images.length }}
        </div>
    </div>
</template>

<script>
	import { ref, provide } from 'vue'
    import ImageCarouselSlide from './ImageCarouselSlide.vue'

    export default {
	    props: ['images'],
        components: {
            ImageCarouselSlide
        },
        watch: {
            activeSlide(value) {
                this.$emit('slideKeyUpdated', value)
            }
        },
	    setup(props) {
            const activeSlide = ref(0)
            const images = ref(props.images ?? [])
            const draggedX = ref(0)
            const imageSlide = ref([])

            provide('activeSlide', activeSlide)
            provide('totalImages', images.value.length)
            provide('draggedX', draggedX)

            const previousSlide = () => {
                imageSlide.value.forEach((i) => {
                    i.startDragging = false
                })

                if(activeSlide.value === 0) {
                    activeSlide.value = images.value.length - 1

                    return
                }

                activeSlide.value -= 1
            }

            const nextSlide = () => {
                imageSlide.value.forEach((i) => {
                    i.startDragging = false
                })

                if(activeSlide.value + 1 === images.value.length) {
                    activeSlide.value = 0

                    return
                }

                activeSlide.value += 1
            }

            return {
                activeSlide,
                images,
                previousSlide,
                nextSlide,
                draggedX,
                imageSlide
            }
		}
	}
</script>
