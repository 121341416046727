<template>
    <div class="space-y-8 py-5">
        <h1 class="text-5xl font-bold text-center">Address of listing</h1>

        <div class="p-5" v-bind:class="{'rounded-lg border-red-500 border-2': validationErrors.includes('address')}">
            <div class="border rounded-xl overflow-hidden">
                <div class="flex items-center px-5">
                    <input type="text" class="py-5 text-xl w-full focus:outline-none" v-model="addressQuery" placeholder="Type in the full address" />
                    <div v-if="!loading" class=" text-fourthly w-12 h-12 rounded-lg block flex items-center justify-center">
                        <i class="fi fi-rr-search flex items-center"></i>
                    </div>
                    <loading v-else color="text-fourthly" />
                </div>

                <ul class="max-h-32 overflow-y-auto divide-y border-t" v-if="results && results.predictions.length > 0">
                    <li v-for="prediction in results.predictions" class="py-3 px-8 text-lg hover:bg-gray-100 hover:cursor-pointer" @click="selectPlace(prediction.place_id)">
                        {{  prediction.description }}
                    </li>
                </ul>
            </div>
        </div>

        <div class="space-y-3 p-5"  v-if="listing.address && (listing.address.location || (listing.address.coordinates && listing.address.coordinates.coordinates.length))">
            <div class="space-y-2">
                <div class="w-full rounded-xl overflow-hidden shadow-lg">
                    <Maps height="300px" width="100%" :location_data="listing.address.location" :disableUI="false" ref="mapsEl" @mapClicked="mapClicked" :center="getMapCenter" :zoom="10">
                        <div v-if="mapsEl">
                            <MapMarker v-if="listing.address.location && listing.address.location.coordinates && listing.address.location.coordinates.coordinates.length" :coordinates="{
                                lat: listing.address.location.coordinates.coordinates[1],
                                lng: listing.address.location.coordinates.coordinates[0]
                            }" />

                            <MapMarker v-if="listing.address.coordinates && listing.address.coordinates.coordinates.length" :coordinates="{
                                lat: listing.address.coordinates.coordinates[1],
                                lng: listing.address.coordinates.coordinates[0]
                            }" />
                        </div>
                    </Maps>
                </div>

                <div class="bg-blue-300 text-sm text-gray-700 p-1 rounded inline-block space-x-2 relative">
                    <div class="absolute -top-2 left-2 w-0 h-0 border-l-[12px] border-l-transparent border-b-[10px] border-b-blue-300 border-r-[12px] border-r-transparent"></div>

                    <div class="flex space-x-1 items-center">
                        <i class="fi fi-rr-info flex items-center"></i>
                        <div>Select a location on the map by clicking on it.</div>
                    </div>
                </div>
            </div>

            <div class="flex items-center space-x-3">
                <div class="space-x-1">
                    <span v-if="listing.address.street">{{ listing.address.street }}</span>
                    <span v-if="listing.address.house_number">{{ listing.address.house_number }}</span>
                    <span v-if="listing.address.city">, {{ listing.address.city }}</span>
                    <span v-if="listing.address.zipcode">, {{ listing.address.zipcode }}</span>
                    <span v-if="listing.address.country">, {{ $t(`countries.${listing.address.country.name}`) }}</span>
                </div>

                <button class="border border-primary text-primary rounded px-2 py-1 text-sm flex space-x-2 items-center hover:bg-primary hover:text-white" @click="$refs.addressManualInput.showModal = true">
                    <i class="fi fi-rr-pencil text-xs flex items-center"></i>
                    <span>Edit Manually</span>
                </button>
            </div>
        </div>

    </div>

    <AddressManualInput ref="addressManualInput" />
</template>


<script>
	import { ref, inject, computed } from 'vue'
    import { useApi } from '../../lib/api';
    import { useDebounceFn } from '@vueuse/core'
    import Maps from '../Maps/Maps.vue'
    import MapMarker from '../Maps/MapMarker.vue'
    import AddressManualInput from './AddressManualInput.vue'
    import { useCountries } from '../../lib/countries'

	export default {
        props: [],
        components: {
            Maps,
            MapMarker,
            AddressManualInput
        },
        watch: {
            addressQuery(value) {
                this.autocompleteAddress()
            },
            'listing.address.country_id'(value) {
                this.listing.address.country = this.countries.find(c => c.id === value)
            }
        },
        setup(props) {
            const { get, data, loading } = useApi('google/maps/places/autocomplete')
            const listing = inject('listing')
            const validationErrors = inject('validationErrors')
            const results = ref(null)
            const addressQuery = ref(null)
            const mapsEl = ref(null)
            const displayLocation = ref(null)

            const { countries } = useCountries()

            const autocompleteAddress = useDebounceFn(() => {
                if(addressQuery.value) {
                    get({
                        input: addressQuery.value
                    }).then(() => {
                        if(data.value.status) {
                            results.value = data.value.results
                        }
                    })
                }
            }, 1000)

            const selectPlace = (place_id) => {
                results.value = null

                const { get, data } = useApi('google/maps/place/detail')

                get({
                    place_id: place_id
                }).then(() => {
                    if(data.value.status) {
                        displayLocation.value = data.value.location
                        listing.value.address.location = data.value.location
                        listing.value.address.location_id = data.value.location.id

                        listing.value.address.street = findAddressComponents(data.value.location.data.address_components, 'route', 'long_name')
                        listing.value.address.apartment = findAddressComponents(data.value.location.data.address_components, 'subpremise', 'long_name')
                        listing.value.address.house_number = findAddressComponents(data.value.location.data.address_components, 'street_number', 'long_name')
                        listing.value.address.district = findAddressComponents(data.value.location.data.address_components, 'sublocality_level_1', 'long_name')
                        listing.value.address.city = findAddressComponents(data.value.location.data.address_components, 'administrative_area_level_2', 'long_name')
                        listing.value.address.zipcode = findAddressComponents(data.value.location.data.address_components, 'postal_code', 'long_name')
                        listing.value.address.state = findAddressComponents(data.value.location.data.address_components, 'administrative_area_level_1', 'long_name')
                        listing.value.address.country = data.value.location.country
                        listing.value.address.country_id = data.value.location.country_id
                        listing.value.address.coordinates = {
                            coordinates: []
                        }
                    }
                })
            }

            const findAddressComponents = (addressComponents, type, keyValue) => {
                const addressComponent = addressComponents.find(ac => ac.types.includes(type))

                if(addressComponent) {
                    return addressComponent[keyValue]
                }

                return null
            }

            const mapClicked = (googleMapEvent) => {
                if(googleMapEvent.placeId) {
                    return selectPlace(googleMapEvent.placeId)
                }

                listing.value.address = {
                    location: null,
                    location_id: null,
                    street: null,
                    house_number: null,
                    apartment: null,
                    city: null,
                    zipcode: null,
                    district: null,
                    state: null,
                    country_id: null,
                    country: {
                        iso_code_2: null,
                        iso_code_3: null
                    },
                    coordinates: {
                        coordinates: [
                            googleMapEvent.latLng.lng(),
                            googleMapEvent.latLng.lat()
                        ]
                    }
                }
            }

            const getMapCenter = computed(() => {
                if(listing.value.address.coordinates && listing.value.address.coordinates.coordinates.length > 0) {
                    return { lat: listing.value.address.coordinates.coordinates[1], lng: listing.value.address.coordinates.coordinates[0] }
                }

                if(listing.value.address.location && listing.value.address.location.coordinates && listing.value.address.location.coordinates.coordinates.length > 0) {
                    return { lat: listing.value.address.location.coordinates.coordinates[1], lng: listing.value.address.location.coordinates.coordinates[0] }
                }

                return null
            })

            const validate = () => {
                if(listing.value.address.location === null && listing.value.address.coordinates.coordinates.length === 0) {
                    validationErrors.value.push('address')
                }
            }

            return {
                listing,
                loading,
                addressQuery,
                autocompleteAddress,
                results,
                selectPlace,
                mapsEl,
                countries,
                mapClicked,
                validate,
                validationErrors,
                displayLocation,
                getMapCenter
            };
        }
    }
</script>
