<template>
    <div>
        <dialog class="block relative w-full z-50 p-0 z-10" v-if="showModal">
            <div class="md:p-7 flex justify-center items-start overflow-x-hidden overflow-y-auto fixed left-0 top-0 w-full h-full bg-gray-900 bg-opacity-50 z-50">
                <div class="flex 2xl:w-1/3 xl:w-1/2 md:w-2/3 w-full relative items-center">
                    <div class="bg-white w-full md:rounded-xl shadow-xl relative z-10">
                        <div class="flex flex-col items-start w-full">
                            <div class="md:px-5 md:pt-5 pt-3 space-y-3 w-full border-b">
                                <button @click="showModal = false" class="w-8 h-8 hover:bg-gray-100 rounded-full flex justify-center items-center">
                                    <i class="fi fi-rr-cross-small text-xl flex items-center"></i>
                                </button>

                                <ul class="flex space-x-5">
                                    <li class="py-1 border-gray-700" v-bind:class="{'border-b-2': mode === 'language'}">
                                        <div class="hover:bg-gray-100 py-2 px-3 rounded-lg hover:cursor-pointer font-semibold" v-bind:class="{'text-gray-700': mode === 'language', 'text-gray-600': mode !== 'language'}" @click="mode = 'language'">
                                            Language
                                        </div>
                                    </li>
                                    <li class="py-1 border-gray-700" v-bind:class="{'border-b-2': mode === 'currency'}">
                                        <div class="hover:bg-gray-100 py-2 px-3 rounded-lg hover:cursor-pointer font-semibold" v-bind:class="{'text-gray-700': mode === 'currency', 'text-gray-600': mode !== 'currency'}" @click="mode = 'currency'">
                                            Currency
                                        </div>
                                    </li>
                                </ul>
                            </div>

                            <div v-if="mode == 'language'" class="w-full space-y-3 p-5">
                                <ul class="grid grid-cols-3 gap-4">
                                    <li v-for="language in languages">
                                        <button class="p-3 hover:bg-gray-100 rounded-xl flex items-top space-x-2 text-left w-full" v-bind:class="{'border border-gray-600': language.id === selected.language.id}" @click="selectLanguage(language)">
                                            <div class="pt-1">
                                                <img :src="`/images/flags/${ language.icon }`" class="w-5" />
                                            </div>
                                            <div>
                                                <div class="text-lg">{{ language.name }}</div>
                                                <div class="text-gray-400 text-sm">{{ language.locale.toUpperCase() }}</div>
                                            </div>
                                        </button>
                                    </li>
                                </ul>
                            </div>

                            <div v-if="mode == 'currency'" class="w-full space-y-3 p-5">
                                <ul class="grid grid-cols-3 gap-4">
                                    <li v-for="currency in currencies">
                                        <button :href="`?currency=${ currency.symbol }`" class="p-3 block hover:bg-gray-100 rounded-xl text-left w-full" v-bind:class="{'border border-gray-600': currency.id === selected.currency.id}" @click="selectCurrency(currency)">
                                            <div>{{ $t(`currencies.${ currency.name }`) }}</div>
                                            <div class="text-gray-400 text-sm">{{ currency.symbol }} - {{ currency.symbol_icon }}</div>
                                        </button>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </dialog>
    </div>
</template>

<script>
	import { ref, inject } from 'vue'
    import { useCurrencies } from '../lib/currencies';
    import { useLanguages } from '../lib/languages'

    export default {
	    props: [
            'model-value-language',
            'model-value-currency'
        ],
        components: {

        },
	    setup(props, { emit }) {
            const showModal = ref(false)
            const mode = ref('language')

            const selected = ref({
                language: props.modelValueLanguage.language,
                currency: props.modelValueCurrency
            })

            const { currencies } = useCurrencies()
            const { languages } = useLanguages()

            const openModal = (type) => {
                if(type) {
                    mode.value = type
                }

                showModal.value = true
            }

            const selectLanguage = (language) => {
                selected.value.language = language
                mode.value = 'currency'
            }

            const selectCurrency = (currency) => {
                selected.value.currency = currency

                window.location.href = `?locale=${ selected.value.language.locale }&currency=${ selected.value.currency.symbol }`;
            }

            return {
                showModal,
                currencies,
                openModal,
                mode,
                languages,
                selected,
                selectLanguage,
                selectCurrency
            }
		}
	}
</script>
