<template>
    <div class="relative">
        <Maps ref="maps" gestureHandling="greedy" height="100%" width="100%" :center="{
            lat: 0,
            lng: 0
        }" :disableUI="false" :zoom="2" @mapClicked="selectedListing = null" @mapMounted="mapMounted">
            <AdvanceMarker v-for="listing in markableListings" :identifier="listing.id" :coordinates="getCoordinates(listing.address)" @markerClicked="markerClicked(listing)" @mouseover="$emit('mouseover', listing)" @mouseout="$emit('mouseout', listing)">
                <ListingMarkerContent :listing="listing" :listingIsSelected="selectedListing && selectedListing.id === listing.id "></ListingMarkerContent>
            </AdvanceMarker>
        </Maps>
        <div v-if="loading" class="absolute top-0 bg-black w-full h-full bg-opacity-75 flex flex-col space-y-3 justify-center items-center">
            <loading color="text-white" />
            <div class="text-white">Loading...</div>
        </div>
    </div>
</template>

<script>
	import { ref, computed } from 'vue'
    import { useApi } from '../lib/api'
    import Maps from './Maps/Maps.vue'
    import AdvanceMarker from './Maps/AdvanceMarker.vue'
    import ListingMarkerContent from './ListingMarkerContent.vue'
    import { useSearch } from '../lib/search'

    export default {
	    props: ['listings', 'uri'],
        components: {
            Maps,
            AdvanceMarker,
            ListingMarkerContent
        },
        watch: {
            '$props.listings'(value) {
                this.listings = value
            },
            listings(value) {
                if(this.markableListings.length > 0) {
                    const coordinates = value.map((l) => {
                        if(l.address.coordinates) {
                            return [
                                l.address.coordinates.coordinates[1],
                                l.address.coordinates.coordinates[0]
                            ]
                        }

                        if(l.address.location) {
                            return [
                                l.address.location.coordinates.coordinates[1],
                                l.address.location.coordinates.coordinates[0]
                            ]
                        }

                        return null
                    }).filter(x => !!x)

                    const bounds = this.calcBoundsFromCoordinates(coordinates)

                    this.$refs.maps.googleMap.fitBounds({
                        lat: bounds[0][0],
                        lng: bounds[0][1]
                    }, {
                        lat: bounds[1][0],
                        lng: bounds[1][1]
                    })
                }
            }
        },
	    setup(props) {
            const { getUrlQuery } = useSearch()

            const selectedListing = ref(null)
            const markers = ref([])
            const maps = ref(null)
            const listings = ref(props.listings ?? [])

            let controller = new AbortController()

            const { get, data, loading } = useApi(props.uri)

            const getMarkers = () => {
                controller.abort()
                controller = new AbortController()

                markers.value = []

                get(getUrlQuery(),{
                        signal: controller.signal
                    }).then(() => {
                    if(data.value.status) {
                        listings.value = data.value.listings
                    }
                })
            }

            const markableListings = computed(() => {
                if(listings.value) {
                    return listings.value.filter((l) => {
                        if(l.address.coordinates || l.address.location) {
                            return l
                        }
                    })
                }

                return []
            })

            const getCoordinates = (address) => {
                if(address.coordinates) {
                    return {
                        lat: address.coordinates.coordinates[1],
                        lng: address.coordinates.coordinates[0]
                    }
                }

                return {
                    lat: address.location.coordinates.coordinates[1],
                    lng: address.location.coordinates.coordinates[0]
                }
            }

            const getSWCoordinates = (coordinatesCollection) => {
                const lowestLng = Math.min(
                    ...coordinatesCollection.map((coordinates) => coordinates[0])
                );
                const lowestLat = Math.min(
                    ...coordinatesCollection.map((coordinates) => coordinates[1])
                );

                return [lowestLng, lowestLat];
            }

            const getNECoordinates = (coordinatesCollection) => {
                const highestLng = Math.max(
                    ...coordinatesCollection.map((coordinates) => coordinates[0])
                );
                const highestLat = Math.max(
                    ...coordinatesCollection.map((coordinates) => coordinates[1])
                );

                return [highestLng, highestLat];
            }

            const calcBoundsFromCoordinates = (coordinatesCollection) => {
                return [
                    getSWCoordinates(coordinatesCollection),
                    getNECoordinates(coordinatesCollection),
                ];
            }

            const markerClicked = (listing) => {
                selectedListing.value = listing

                maps.value.googleMap.markers.forEach((m) => {
                    m.zIndex = null

                    console.log(m.id)
                    console.log(listing.id)
                    if(m.id == listing.id) {
                        console.log('joiwerjiowjre')
                        m.zIndex = 99
                    }
                })

                if(listing.address.coordinates) {
                    maps.value.googleMap.panTo({
                        lat: listing.address.coordinates.coordinates[1],
                        lng: listing.address.coordinates.coordinates[0],
                    })

                    return
                }

                if(listing.address.location) {
                    maps.value.googleMap.panTo({
                        lat: listing.address.location.coordinates.coordinates[1],
                        lng: listing.address.location.coordinates.coordinates[0],
                    })

                    return
                }
            }

            const mapMounted = () => {
                const renderer = {
                    render: ({ markers, _position: position }) => {
                        const content = document.createElement("div")

                        content.classList.add("property");
                        content.innerHTML = `<div class="bg-primary rounded-full py-1 px-2 border-2 border-white"><span class="text-white font-semibold">${ markers.length }</span></div>`;

                        return new google.maps.marker.AdvancedMarkerElement({
                            position: {
                                lat: position.lat(),
                                lng: position.lng(),
                            },
                            content: content
                        });
                    },
                }

                maps.value.googleMap.activateMarkerClusterer(renderer)
            }


            if(props.uri) {
                getMarkers()
            }

            return {
                selectedListing,
                markers,
                maps,
                listings,
                markableListings,
                getCoordinates,
                calcBoundsFromCoordinates,
                markerClicked,
                loading,
                getMarkers,
                mapMounted
            }
		}
	}
</script>

