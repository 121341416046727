<template>
    <div>
        <dialog class="block relative w-full z-50 p-0 z-10" v-if="showModal">
            <div class="md:p-7 flex justify-center items-start overflow-x-hidden overflow-y-auto fixed left-0 top-0 w-full h-full bg-gray-900 bg-opacity-50 z-50">
                <div class="flex 2xl:w-1/3 xl:w-1/2 md:w-2/3 w-full relative items-center">
                    <div class="bg-white w-full md:rounded-xl shadow-xl relative z-10">
                        <div class="flex flex-col items-start w-full">
                            <div class="md:px-5 md:py-5 p-3 flex items-center w-full border-b">
                                <button @click="showModal = false" class="w-8 h-8 hover:bg-gray-100 rounded-full flex justify-center items-center">
                                    <i class="fi fi-rr-cross-small text-xl flex items-center"></i>
                                </button>

                                <div class="text-center text-lg font-bold w-full">
                                    <span>Edit Address</span>
                                </div>
                            </div>

                            <div class="w-full space-y-3 p-5">
                                <div class="border border-gray-300 rounded-lg divide-y divide-gray-300">
                                    <div class="flex divide-x divide-gray-300">
                                        <div class="relative w-3/4">
                                            <input id="street" type="text" class="block rounded-lg px-2.5 pb-2.5 pt-5 w-full text-gray-900 bg-white appearance-none focus:border-2 focus:border-gray-800 focus:outline-none focus:ring-0 peer" placeholder=" " v-model="listing.address.street"  />
                                            <label for="street" class="absolute text-gray-500 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] start-2.5 peer-focus:text-primary  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">Street</label>
                                        </div>
                                        <div class="relative w-1/4">
                                            <input id="house_number" type="text" class="block rounded-lg px-2.5 pb-2.5 pt-5 w-full text-gray-900 bg-whiteappearance-none focus:border-2 focus:border-gray-800 focus:outline-none focus:ring-0 peer" placeholder=" " v-model="listing.address.house_number"  />
                                            <label for="house_number" class="absolute text-gray-500 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] start-2.5 peer-focus:text-primary  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">House number</label>
                                        </div>
                                    </div>

                                    <div class="relative w-full">
                                        <input id="apartment" type="text" class="block rounded-lg px-2.5 pb-2.5 pt-5 w-full text-gray-900 bg-whiteappearance-none focus:border-2 focus:border-gray-800 focus:outline-none focus:ring-0 peer" placeholder=" " v-model="listing.address.apartment"  />
                                        <label for="apartment" class="absolute text-gray-500 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] start-2.5 peer-focus:text-primary  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">Apartment</label>
                                    </div>

                                    <div class="relative w-full">
                                        <input id="district" type="text" class="block rounded-lg px-2.5 pb-2.5 pt-5 w-full text-gray-900 bg-whiteappearance-none focus:border-2 focus:border-gray-800 focus:outline-none focus:ring-0 peer" placeholder=" " v-model="listing.address.district"  />
                                        <label for="district" class="absolute text-gray-500 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] start-2.5 peer-focus:text-primary  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">District</label>
                                    </div>

                                    <div class="flex divide-x divide-gray-300">
                                        <div class="relative w-1/2">
                                            <input id="zipcode" type="text" class="block rounded-lg px-2.5 pb-2.5 pt-5 w-full text-gray-900 bg-white appearance-none focus:border-2 focus:border-gray-800 focus:outline-none focus:ring-0 peer" placeholder=" " v-model="listing.address.zipcode"  />
                                            <label for="zipcode" class="absolute text-gray-500 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] start-2.5 peer-focus:text-primary  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">Zipcode</label>
                                        </div>
                                        <div class="relative w-1/2">
                                            <input id="city" type="text" class="block rounded-lg px-2.5 pb-2.5 pt-5 w-full text-gray-900 bg-whiteappearance-none focus:border-2 focus:border-gray-800 focus:outline-none focus:ring-0 peer" placeholder=" " v-model="listing.address.city"  />
                                            <label for="city" class="absolute text-gray-500 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] start-2.5 peer-focus:text-primary  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">City</label>
                                        </div>
                                    </div>

                                    <div class="relative w-full">
                                        <select class="block rounded-lg px-2.5 pb-2.5 pt-5 w-full text-gray-900 bg-whiteappearance-none focus:border-2 focus:border-gray-800 focus:outline-none focus:ring-0 peer" v-model="listing.address.country_id">
                                            <option v-for="country in countries" :value="country.id">{{ $t(`countries.${country.name}`) }}</option>
                                        </select>
                                    </div>
                                </div>

                                <button v-if="!loading && !placeDetailLoading" class="bg-primary w-full rounded-lg text-white font-semibold py-3 px-5 hover:shadow-xl" @click="getLocation()">Save</button>
                                <button v-else class="bg-gray-300 w-full rounded-lg text-white font-semibold py-3 px-5">
                                    <loading color="text-white" />
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </dialog>
    </div>
</template>

<script>
	import { ref, inject } from 'vue'
    import { useCountries } from '../../lib/countries';
    import { useApi } from '../../lib/api';

    export default {
	    props: [],
        components: {

        },
	    setup(props) {
            const { get, data, loading } = useApi('google/maps/places/text-search')
            const { get: getPlaceDetail, data: placeDetailData, loading: placeDetailLoading } = useApi('google/maps/place/detail')

            const showModal = ref(false)
            const listing = inject('listing')

            const { countries } = useCountries()

            const getLocation = () => {
                get({
                    query: [listing.value.address.street, listing.value.address.house_number, listing.value.address.apartment,listing.value.address.district, listing.value.address.zipcode, listing.value.address.city, listing.value.address.country.iso_code_2].filter(n => n).join(',')
                }).then(() => {
                    if(data.value.status && data.value.results.results.length) {

                        getPlaceDetail({
                            place_id: data.value.results.results[0].place_id
                        }).then(() => {
                            if(placeDetailData.value.status) {
                                listing.value.address.location = placeDetailData.value.location
                                listing.value.address.location_id = placeDetailData.value.location.id

                                showModal.value = false
                            }
                        })
                    }
                })
            }

            return {
                showModal,
                listing,
                countries,
                getLocation,
                loading,
                placeDetailLoading
            }
		}
	}
</script>
