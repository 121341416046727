<template>
    <div class="space-y-8">
        <h1 class="text-5xl font-bold text-center">Photos</h1>

        <div class="p-5">
            <MultiUpload name="Photos" type="photos" :filable_id="listing.id" filable_type="App\Models\Listing" v-model="listing.photos"></MultiUpload>
        </div>
    </div>
</template>

<script>
	import { ref, inject } from 'vue'
    import MultiUpload from './MultiUpload.vue';

    export default {
	    props: [],
        components: {
            MultiUpload
        },
	    setup(props) {
            const listing = inject('listing')

            const validate = () => {
            }

            return {
                listing,
                validate
            }
        }
    }
</script>
