<template>
    <div ref="markerContent">
        <slot></slot>
    </div>
</template>

<script>
import { ref, getCurrentInstance } from "vue";

export default {
    props: ['name', 'identifier', 'location', 'coordinates', 'lat', 'lng', 'size', 'icon'],
    watch: {
        '$props.identifier'(value) {
            const marker = this.getMarker()

            if(marker) {
                console.log(`change id to ${ value }`)
                marker.id = value
            }
        },
        '$props.location'(value) {
            const marker = this.getMarker()

            if(marker) {
                marker.position = new google.maps.LatLng(value.lat, value.lng)

                return
            }

            this.setMarker()
        },
        async '$props.coordinates'(value) {
            const marker = this.getMarker()

            if(marker) {
                marker.position = new google.maps.LatLng(value.lat, value.lng)

                return
            }

            this.setMarker()
        }
    },
    setup(props, { emit }) {
        const instance = getCurrentInstance();

        const markerContent = ref(null)
        const googleMap = instance.parent.setupState.googleMap

        let marker = null

        const setMarker = async() => {
            if(!marker) {
                marker = await googleMap.createAdvanceMarker(props.identifier, getLatLng(), (markerContent.value.innerHTML !== '')? markerContent.value : null)

                emit('markerCreated', marker)

                marker.addListener("click", () => {
                    emit('markerClicked')
                })

                marker.addListener("mouseover", () => {
                    emit('markerMouseOver')
                })

                marker.addListener("mouseout", () => {
                    emit('markerMouseOut')
                })
            }
        }

        const getLatLng = () => {
            if(props.location) {
                return {
                    latitude: parseFloat(props.location.lat),
                    longitude: parseFloat(props.location.lng)
                }
            }

            if(props.coordinates){
                return {
                    latitude: parseFloat(props.coordinates.lat),
                    longitude: parseFloat(props.coordinates.lng)
                }
            }
        }

        const removeMarker = () => {
            if(marker) {
                emit('markerRemoved', marker)

                googleMap.removeMarker(marker)
                marker = null
            }
        }

        const getMarker = () => {
            return marker
        }

        return {
            googleMap,
            markerContent,
            setMarker,
            removeMarker,
            getMarker
        }
    },
    mounted() {
        this.setMarker()
    },
    beforeUnmount() {
        this.removeMarker()
    }
}
</script>
