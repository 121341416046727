<template>
    <div>
        <div class="inline-block py-2 px-4 rounded-lg text-white whitespace-pre-wrap" v-bind:class="bubbleColor" v-html="message.message"></div>
    </div>
</template>


<script>
	import { ref, inject } from 'vue'
    import dayjs from 'dayjs'

    export default {
	    props: ['message', 'bubbleColor'],
        components: {

        },
	    setup(props) {

            return {
                dayjs
            }
		}
	}
</script>
