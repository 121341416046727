<template>
    <div class="space-y-8 py-5">
        <h1 class="text-5xl font-bold text-center">Exterior Features</h1>

        <div class="space-y-2 p-5">
            <h2 class="font-bold">Location Feature</h2>

            <ul class="border rounded-lg divide-y">
                <FacilityBooleanRow v-for="locationFeature in locationFeatures" :facility="locationFeature" v-model="listing.features.exterior.location_features" />
            </ul>
        </div>

        <div class="space-y-2 p-5">
            <h2 class="font-bold">Garden</h2>

            <ul class="border rounded-lg divide-y">
                <FacilityBooleanRow v-for="gardenFeature in gardenFeatures" :facility="gardenFeature" v-model="listing.features.exterior.garden_features" />
            </ul>
        </div>

        <div class="space-y-8" v-if="listing.features.exterior.garden_features.find(f => f.name === 'back_garden' && f.has)">
            <div class="space-y-2 p-5">
                <h2 class="font-bold">Garden direction</h2>

                <div class="flex justify-between border rounded-lg divide-x overflow-hidden">
                    <button class="w-1/4 p-5 font-semibold" @click="addGardenDirection('north')" v-bind:class="{'text-gray-100 cursor-not-allowed': listing.features.exterior.garden_direction.includes('south'), 'bg-primary text-white': listing.features.exterior.garden_direction.includes('north')}">North</button>
                    <button class="w-1/4 p-5 font-semibold" @click="addGardenDirection('east')" v-bind:class="{'text-gray-100 cursor-not-allowed': listing.features.exterior.garden_direction.includes('west'), 'bg-primary text-white': listing.features.exterior.garden_direction.includes('east')}">East</button>
                    <button class="w-1/4 p-5 font-semibold" @click="addGardenDirection('south')" v-bind:class="{'text-gray-100 cursor-not-allowed': listing.features.exterior.garden_direction.includes('north'), 'bg-primary text-white': listing.features.exterior.garden_direction.includes('south')}">South</button>
                    <button class="w-1/4 p-5 font-semibold" @click="addGardenDirection('west')" v-bind:class="{'text-gray-100 cursor-not-allowed': listing.features.exterior.garden_direction.includes('east'), 'bg-primary text-white': listing.features.exterior.garden_direction.includes('west')}">West</button>
                </div>
            </div>

            <div class="space-y-2 p-5">
                <h2 class="font-bold">Garden accessable from the rear?</h2>

                <div class="flex justify-between border rounded-lg divide-x overflow-hidden">
                    <button class="w-1/2 p-5 font-semibold" @click="listing.features.exterior.garden_accessable_from_rear = true" v-bind:class="{'bg-primary text-white': listing.features.exterior.garden_accessable_from_rear === true}">Yes</button>
                    <button class="w-1/2 p-5 font-semibold" @click="listing.features.exterior.garden_accessable_from_rear = false" v-bind:class="{'bg-primary text-white': listing.features.exterior.garden_accessable_from_rear === false}">No</button>
                </div>
            </div>

            <div class="space-y-2 p-5">
                <h2 class="font-bold">Back garden dimensions</h2>

                <div class="flex space-x-3">
                    <div class="border w-1/2 rounded-lg flex items-center overflow-hidden">
                        <input type="number" class="p-5 w-full rounded-lg" v-model="listing.features.exterior.back_garden_dimensions.deep" />
                        <div class="px-3 whitespace-nowrap">Meters(s) Deep</div>
                    </div>

                    <div class="border w-1/2 rounded-lg flex items-center overflow-hidden">
                        <input type="number" class="p-5 w-full rounded-lg" v-model="listing.features.exterior.back_garden_dimensions.width" />
                        <div class="px-3 whitespace-nowrap">Meters(s) Width</div>
                    </div>
                </div>
            </div>
        </div>


        <div class="space-y-2 p-5">
            <h2 class="font-bold">Balcony / Roof garden</h2>

            <ul class="border rounded-lg divide-y">
                <FacilityBooleanRow v-for="balconyRoofFeature in balconyRoofFeatures" :facility="balconyRoofFeature" v-model="listing.features.exterior.balcony_roof_features" />
            </ul>
        </div>
    </div>
</template>

<script>
	import { ref, inject } from 'vue'
    import FacilityBooleanRow from './FacilityBooleanRow.vue'

    export default {
	    props: [],
        components: {
            FacilityBooleanRow
        },
	    setup(props) {
            const listing = inject('listing')

            if(listing.value.features.exterior.location_features === undefined) {
                listing.value.features.exterior.location_features = []
            }

            if(listing.value.features.exterior.garden_features === undefined) {
                listing.value.features.exterior.garden_features = []
            }

            if(listing.value.features.exterior.garden_direction === undefined) {
                listing.value.features.exterior.garden_direction = []
            }

            if(listing.value.features.exterior.back_garden_dimensions === undefined) {
                listing.value.features.exterior.back_garden_dimensions = {
                    deep: null,
                    width: null
                }
            }

            if(listing.value.features.exterior.balcony_roof_features === undefined) {
                listing.value.features.exterior.balcony_roof_features = []
            }

            const locationFeatures = ref([
                { name: "sheltered_location"},
                { name: "on_the_edge_of_a_forest"},
                { name: "alongside_park"},
                { name: "alongside_a_quiet_road"},
                { name: "in_wooded_surroundings"},
                { name: "rural"},
                { name: "unobstructed_surrounding_view"},
                { name: "in_center"},
                { name: "on_navigable_waterway"},
                { name: "in_residential_district"}
            ])

            const gardenFeatures = ref([
                { name: "back_garden"},
                { name: "front_garden"},
                { name: "side_garden"},
                { name: "surrounded_by_garden"}
            ])

            const balconyRoofFeatures = ref([
                { name: "roof_terrace"},
                { name: "balcony"}
            ])

            const addGardenDirection = (direction) => {
                if(!listing.value.features.exterior.garden_direction.includes(direction)) {
                    if(direction === 'north' && !listing.value.features.exterior.garden_direction.includes('south')) {
                        listing.value.features.exterior.garden_direction.push(direction)
                    }

                    if(direction === 'south' && !listing.value.features.exterior.garden_direction.includes('north')) {
                        listing.value.features.exterior.garden_direction.push(direction)
                    }

                    if(direction === 'east' && !listing.value.features.exterior.garden_direction.includes('west')) {
                        listing.value.features.exterior.garden_direction.push(direction)
                    }

                    if(direction === 'west' && !listing.value.features.exterior.garden_direction.includes('east')) {
                        listing.value.features.exterior.garden_direction.push(direction)
                    }

                    return
                }

                listing.value.features.exterior.garden_direction = listing.value.features.exterior.garden_direction.filter(f => f !== direction)
            }

            const validate = () => {

            }

            return {
                listing,
                validate,
                locationFeatures,
                gardenFeatures,
                balconyRoofFeatures,
                addGardenDirection
            }
        }
    }
</script>
