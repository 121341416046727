<template>
    <div class="space-y-8 py-5">
        <h1 v-if="listing.type.name === 'selling'" class="text-5xl font-bold text-center">Asking Price</h1>
        <h1 v-if="listing.type.name === 'leasing'" class="text-5xl font-bold text-center">Rent Price per month</h1>

        <div class="p-5" v-bind:class="{'rounded-lg border-red-500 border-2': validationErrors.includes('askingPrice')}">
            <div class="border rounded-xl overflow-hidden">
                <div class="flex items-center space-x-3">
                    <button class="font-semibold flex flex-nowrap space-x-2 hover:bg-gray-100 h-full items-center p-5" @click="$refs.currencySelectModal.showModal = true">
                        <img :src="`/images/flags/${ listing.currency.icon }`" class="block w-5" />
                        <span class="">{{ listing.currency.symbol }}</span>
                    </button>
                    <input type="text" class="flex-1 text-xl focus:outline-none" v-model="listing.price" placeholder="How much you want to sell it for?" />
                </div>
            </div>
        </div>

    </div>

    <CurrencySelectModal ref="currencySelectModal" v-model="listing.currency" />
</template>

<script>
	import { ref, inject } from 'vue'
    import CurrencySelectModal from '../CurrencySelectModal.vue'

    export default {
	    props: [],
        components: {
            CurrencySelectModal
        },
	    setup(props) {
            const listing = inject('listing')
            const validationErrors = inject('validationErrors')

            const validate = () => {
                if(listing.value.price === null || listing.value.price === '') {
                    validationErrors.value.push('askingPrice')
                }
            }

            return {
                listing,
                validationErrors,
                validate
            }
        }
    }
</script>
