<template>
    <div>
        <div class="space-y-2">
            <div class="inline-block py-2 px-4 rounded-lg text-white whitespace-pre-wrap" v-bind:class="bubbleColor">
                <div class="flex space-x-3">
                    <div class="bg-white h-14 w-14 rounded-lg flex items-center justify-center">
                        <div>
                            <div class="text-gray-800 font-bold text-2xl">
                                {{ dayjs(message.ticket_messagable.date_time).format('DD') }}
                            </div>
                            <div class="text-gray-800 font-bold text-sm text-center">
                                {{ dayjs(message.ticket_messagable.date_time).format('MMM') }}
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>Appointment Requested</div>
                        <div class="text-sm text-gray-100">
                            <span v-if="message.ticket_messagable.confirmed === null">Not confirmed yet.</span>
                            <span v-if="message.ticket_messagable.confirmed === true" class="text-green-300 font-semibold">Confirmed</span>
                            <span v-if="message.ticket_messagable.confirmed === false" class="text-red-300 font-semibold">Declined</span>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="thisIsRealEstateAgent && !loading && message.ticket_messagable.confirmed === null" class="flex space-x-2">
                <button class="bg-green-500 w-1/2 rounded-lg py-2 font-semibold text-white hover:shadow-lg" @click="confirmAppointment(true)">Accept</button>
                <button class="bg-red-500 w-1/2 rounded-lg py-2 font-semibold text-white hover:shadow-lg" @click="confirmAppointment(false)">Decline</button>
            </div>
            <loading v-if="loading" />
        </div>
    </div>
</template>


<script>
	import { ref, inject } from 'vue'
    import dayjs from 'dayjs'
    import { useApi } from '../lib/api'

    export default {
	    props: ['message', 'bubbleColor', 'thisIsRealEstateAgent'],
        components: {

        },
	    setup(props) {
            const message = ref(props.message)
            const { post, loading, data } = useApi(`ticket/${ message.value.ticket_id }/message/${ message.value.id }/confirming-appointment`)

            const confirmAppointment = (status) => {
                post({
                    confirm: status
                }).then(() => {
                    if(data.value.status) {
                        message.value = data.value.ticket_message
                    }
                })
            }
            return {
                message,
                dayjs,
                confirmAppointment,
                loading
            }
		}
	}
</script>
