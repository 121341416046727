<template>
    <div class="space-y-8">
        <div class="flex items-start justify-between">
            <div class="space-y-3">
                <div>
                    <ul class="flex space-x-4">
                        <li>
                            <a href="/user/account" class="hover:underline">
                                Account
                            </a>
                        </li>
                        <li>
                            <div class="flex items-center space-x-4">
                                <i class="fi fi-rr-angle-small-right flex items-center"></i>
                                <div>Listings</div>
                            </div>
                        </li>
                    </ul>
                </div>
                <h1 class="text-3xl font-bold" v-if="listings">{{ listings.total }} listings</h1>

                <div class="flex space-x-5">
                    <div class="w-72 bg-gray-100 border rounded overflow-hidden flex items-center py-1 px-2 space-x-2">
                        <i class="fi fi-rr-search flex items-center text-gray-500"></i>
                        <input type="text" class="bg-gray-100 focus:outline-none w-full" placeholder="Search listings" />
                    </div>

                    <button class="border rounded py-1 px-2 text-sm flex items-center space-x-2">
                        <div>Rooms and beds</div>
                        <i class="fi fi-rr-angle-small-down flex items-center"></i>
                    </button>

                    <button class="border rounded py-1 px-2 text-sm flex items-center space-x-2">
                        <div>Amenities</div>
                        <i class="fi fi-rr-angle-small-down flex items-center"></i>
                    </button>

                    <button class="border rounded py-1 px-2 text-sm flex items-center space-x-2">
                        <div>Listing status</div>
                        <i class="fi fi-rr-angle-small-down flex items-center"></i>
                    </button>

                    <button class="border rounded py-1 px-2 text-sm flex items-center space-x-2">
                        <div>More filters</div>
                        <i class="fi fi-rr-angle-small-down flex items-center"></i>
                    </button>
                </div>
            </div>

            <a href="/listing/new" class="py-2 px-5 rounded-lg border border-gray-800 flex items-center space-x-1 hover:bg-gray-100">
                <i class="fi fi-rr-plus-small items-center flex text-xl"></i>
                <span>Create listing</span>
            </a>
        </div>

        <div class="border rounded-lg">
            <table class="w-full">
                <thead>
                    <tr>
                        <th class="border-b text-left py-3 px-2">
                            <input type="checkbox" class="w-4 h-4 accent-primary" />
                        </th>
                        <th class="border-b border-gray-300 text-left py-3">
                            Listing
                        </th>
                        <th class="border-b border-gray-300 text-left py-3">
                            Status
                        </th>
                        <th class="border-b border-gray-300 text-left py-3">
                            Property Type
                        </th>
                        <th class="border-b border-gray-300 text-left py-3">
                            Rooms
                        </th>
                        <th class="border-b border-gray-300 text-left py-3">
                            Bedrooms
                        </th>
                        <th class="border-b border-gray-300 text-left py-3">
                            Baths
                        </th>
                        <th class="border-b border-gray-300 text-left py-3">
                            Location
                        </th>
                        <th class="border-b border-gray-300 text-left py-3">
                            Last modified
                        </th>
                    </tr>
                </thead>
                <tbody class="divide-y divide-gray-200" v-if="listings">
                    <tr class="hover:bg-gray-100 hover:cursor-pointer" v-for="listing in listings.data" @click="selectListing(listing)">
                        <td class="py-3 px-2">
                            <input type="checkbox" class="w-4 h-4 accent-primary" />
                        </td>
                        <td class="py-3">
                            <div class="flex items-center space-x-2 ">
                                <img v-if="listing.photos.length" :src="listing.photos[0].image_url" class="rounded-lg w-12 h-10 object-cover" />
                                <img v-else src="/images/blank_image.png" class="rounded-lg w-12 h-10 object-cover">
                                <h3 v-if="listing.infos.length">{{ listing.infos[0].title }}</h3>
                            </div>
                        </td>
                        <td class="py-3">
                            <div class="flex items-center space-x-2">
                                <div class="h-3 w-3 rounded-full" v-bind:style="`background-color:${ listing.status.color }`"></div>
                                <div>{{ $t(`statuses.${ listing.status.name }`) }}</div>
                            </div>
                        </td>
                        <td class="py-3 ">
                            <span v-if="listing.features">
                                {{ listing.features.construction.property_type }}
                            </span>
                        </td>
                        <td class="py-3 ">
                            <span v-if="listing.features">
                                {{ listing.features.layout.numbers_of_rooms }}
                            </span>
                        </td>
                        <td class="py-3 ">
                            <span v-if="listing.features">
                                {{ listing.features.layout.numbers_of_bedrooms }}
                            </span>
                        </td>
                        <td class="py-3 ">
                            <span v-if="listing.features">
                                {{ listing.features.layout.numbers_of_bath_rooms }}
                            </span>
                        </td>
                        <td class="py-3 ">
                            <span v-if="listing.address && listing.address.country">{{ $t(`countries.${ listing.address.country.name }`) }}</span>
                        </td>
                        <td class="py-3 ">
                            {{ dayjs(listing.updated_at).format('MMM DD, YYYY') }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
	import { ref } from 'vue'
    import { useApi } from '../../lib/api'
    import dayjs from 'dayjs'

	export default {
	    props: [],
	    setup(props) {
            const { get, data, loading } = useApi('account/listings')
            const listings = ref(null)

            get().then(() => {
                if(data.value.status) {
                    listings.value = data.value.listings
                }
            })

            const selectListing = (listing) => {
                if(listing.status.name === 'is_new') {
                    window.location.href = `/listing/new/${ listing.id }`
                }
            }

            return {
                listings,
                dayjs,
                selectListing
            }
		}
	}
</script>
