<template>
    <div class="h-full w-full" @mousedown="dragStart" @touchstart="dragStart" @touchend="dragEnd()" @touchmove="dragAction" v-bind:style="{
        transform: `translateX(${ translateX })`
    }" v-bind:class="{
        'hidden': hideSlide,
        'z-10': !isUpperLayer,
        'z-20': isUpperLayer,
        'duration-500 ease-in-out transition-transform transform': !startDragging,
    }">
        <slot></slot>
    </div>
</template>

<script>
	import { ref, inject } from 'vue'

    export default {
	    props: ['itemKey'],
        components: {
        },
        watch: {
            activeSlide(value) {
                this.layerShouldBeUpper()
                this.shouldDisplaySlide()
                this.shouldSlideToLeft()
                this.shouldSlideToRight()
                this.shouldHideSlide()
            },
            draggedX: {
                handler(value) {
                    if(this.$props.itemKey === 0 && this.activeSlide + 1 === this.totalImages) {
                        this.startDragging = true
                        this.translateX = `${ this.$el.getBoundingClientRect().width + value }px`
                    }

                    if(this.$props.itemKey - this.activeSlide === 1) {
                        this.startDragging = true
                        this.translateX = `${ this.$el.getBoundingClientRect().width + value }px`
                    }

                    if(this.$props.itemKey - this.activeSlide === -1 && value > 0) {
                        this.startDragging = true
                        this.translateX = `${ (this.$el.getBoundingClientRect().width - value) * -1 }px`
                    }

                    if(this.activeSlide === 0 && this.slideIsLastInLine && value > 0) {
                        this.startDragging = true
                        this.translateX = `${ (this.$el.getBoundingClientRect().width - value) * -1 }px`
                    }
                },
                deep: true
            }
        },
	    setup(props, { emit }) {
            const posX1 = ref(0)
            const posX2 = ref(0)
            const translateX = ref('100%')
            const hideSlide = ref(false)
            const startDragging = ref(false)
            const activeSlide = inject('activeSlide')
            const totalImages = inject('totalImages')
            const draggedX = inject('draggedX')
            const isUpperLayer = ref(false)

            const slideIsLastInLine = ref(props.itemKey + 1 === totalImages)

            const dragStart = (e) => {
                startDragging.value = true

                if(e.type == 'touchstart') {
                    posX1.value = e.touches[0].clientX
                }
            }

            const dragEnd = () => {
                startDragging.value = false

                if(parseFloat(translateX.value) == 0) {
                    emit('click')

                    return
                }

                if(parseFloat(translateX.value) < -100) {
                    emit('nextSlide')

                    return
                }

                if(parseFloat(translateX.value) > -100) {
                    emit('previousSlide')

                    return
                }
            }

            const dragAction = (e) => {
                e.preventDefault()

                if(e.type == 'touchmove') {
                    posX2.value = e.touches[0].clientX - posX1.value
                }

                translateX.value = `${ posX2.value }px`
                draggedX.value = posX2.value
            }

            const shouldHideSlide = () => {
                if(activeSlide.value == props.itemKey) {
                    hideSlide.value = false

                    return
                }

                if(props.itemKey - activeSlide.value === -1) {
                    hideSlide.value = false

                    return
                }

                if(props.itemKey - activeSlide.value === 1) {
                    hideSlide.value = false

                    return
                }

                if(slideIsLastInLine.value && activeSlide.value === 0) {
                    hideSlide.value = false

                    return
                }

                if(props.itemKey === 0 && activeSlide.value + 1 === totalImages) {
                    hideSlide.value = false

                    return
                }

                hideSlide.value = true
            }

            const shouldSlideToLeft = () => {
                if(props.itemKey - activeSlide.value === -1 || slideIsLastInLine.value && activeSlide.value === 0) {
                    translateX.value = '-100%'
                }
            }

            const shouldSlideToRight = () => {
                if(activeSlide.value - props.itemKey > 1 || props.itemKey - activeSlide.value === 1) {
                    translateX.value = '100%'
                }
            }

            const shouldDisplaySlide = () => {
                if(activeSlide.value === props.itemKey) {
                    translateX.value = '0px'
                }
            }

            const layerShouldBeUpper = () => {
                if(activeSlide.value === props.itemKey || (props.itemKey - activeSlide.value === -1) || (props.itemKey - activeSlide.value === 1)) {
                    isUpperLayer.value = true

                    return
                }

                isUpperLayer.value = false
            }

            shouldHideSlide()
            shouldSlideToLeft()
            shouldSlideToRight()
            shouldDisplaySlide()

            return {
                dragStart,
                dragAction,
                dragEnd,
                translateX,
                startDragging,
                activeSlide,
                hideSlide,
                totalImages,
                shouldHideSlide,
                shouldSlideToLeft,
                shouldSlideToRight,
                shouldDisplaySlide,
                layerShouldBeUpper,
                slideIsLastInLine,
                draggedX,
                isUpperLayer
            }
		}
	}
</script>
