<template>
    <div class="py-5 space-y-3">
        <h2 class="font-semibold text-gray-600 text-sm">{{ name }}</h2>

        <div class="flex space-x-2 items-center">
            <specifiable-select class="h-12 w-1/2 relative rounded border overflow-hidden" :options="options" v-model="selected.from"></specifiable-select>
            <div class="text-center text-sm">To</div>
            <specifiable-select class="h-12 w-1/2 relative rounded border overflow-hidden" :addNoMax="true" :options="options" v-model="selected.to"></specifiable-select>
        </div>
    </div>
</template>


<script>
	import { ref, inject } from 'vue'
    import SpecifiableSelect from './SpecifiableSelect.vue'

    export default {
	    props: ['name', 'options', 'modelValue'],
        components: {
            SpecifiableSelect
        },
        watch: {
            selected: {
                handler(value) {
                    this.$emit('update:modelValue', value)
                },
                deep: true
            }
        },
	    setup(props) {
            const selected = ref(props.modelValue ?? {
                from: '',
                to: ''
            })

            return {
                selected
            }
		}
	}
</script>

