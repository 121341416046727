import { ref } from 'vue'
import { useApi } from './api'

const { get, data } = useApi('countries')

const countries = ref([])

get().then(() => {
    if(data.value.status) {
        countries.value = data.value.countries
    }
})

export const useCountries = () => {
    return {
        countries
    }
}
