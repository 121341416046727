<template>
    <div>
        <dialog class="block relative w-full z-50 p-0 z-10" v-if="modalShown">
            <div class="md:p-7 flex justify-center items-start overflow-x-hidden overflow-y-auto fixed left-0 top-0 w-full h-full bg-gray-900 bg-opacity-50 z-50">
                <div class="flex 2xl:w-1/3 xl:w-1/2 md:w-2/3 w-full relative items-center">
                    <div class="bg-white w-full md:rounded-xl shadow-xl relative z-10">
                        <div class="flex flex-col items-start w-full">
                            <div class="md:px-5 md:py-5 p-3 flex items-center w-full border-b">
                                <button @click="modalShown = false" class="w-8 h-8 hover:bg-gray-100 rounded-full flex justify-center items-center">
                                    <i class="fi fi-rr-cross-small text-xl flex items-center"></i>
                                </button>

                                <div class="text-center text-lg font-bold w-full">
                                    Create Realtor Profile
                                </div>
                            </div>

                            <div class="w-full p-5 space-y-8">

                                <div class="flex justify-center">
                                    <img src="/images/blank_image.png" class="w-32 h-32 rounded-full" />
                                </div>

                                <div class="space-y-1">
                                    <div class="relative">
                                        <input id="realtor_name" type="text" class="block rounded-lg px-2.5 pb-2.5 pt-5 w-full text-gray-900 bg-white border border-gray-300 appearance-none focus:border-2 focus:border-gray-800 focus:outline-none focus:ring-0 peer" placeholder=" "  />
                                        <label for="realtor_name" class="absolute text-gray-500 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] start-2.5 peer-focus:text-primary  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto" >Realtor Name</label>
                                    </div>
                                    <!-- <div class="text-sm flex items-center space-x-1 text-red-500">
                                        <i class="fi fi-rr-exclamation flex items-center"></i>
                                        <span>Enter a valid email.</span>
                                    </div> -->
                                </div>

                                <div class="space-y-3">
                                    <h2 class="font-bold text-lg">Contact info</h2>

                                    <div class="space-y-1">
                                        <div class="border border-gray-300 rounded-lg overflow-hidden divide-y">
                                            <div class="relative">
                                                <input id="email" ref="firstName" type="text" class="block px-2.5 pb-2.5 pt-5 w-full text-gray-900 bg-white appearance-none focus:border-2 focus:border-gray-800 focus:outline-none focus:ring-0 peer rounded-lg" placeholder=" " />
                                                <label for="email" class="absolute text-gray-500 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] start-2.5 peer-focus:text-primary  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto" >Email</label>
                                            </div>

                                            <div class="relative">
                                                <input id="phone_number" type="text" class="block px-2.5 pb-2.5 pt-5 w-full text-gray-900 bg-white appearance-none focus:border-2 focus:border-gray-800 focus:outline-none focus:ring-0 peer rounded-lg" placeholder=" " />
                                                <label for="phone_number" class="absolute text-gray-500 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] start-2.5 peer-focus:text-primary  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto" >Phone number</label>
                                            </div>

                                            <div class="relative">
                                                <input id="website" type="text" class="block px-2.5 pb-2.5 pt-5 w-full text-gray-900 bg-white appearance-none focus:border-2 focus:border-gray-800 focus:outline-none focus:ring-0 peer rounded-lg" placeholder=" " />
                                                <label for="website" class="absolute text-gray-500 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] start-2.5 peer-focus:text-primary  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto" >Website</label>
                                            </div>
                                        </div>

                                        <!-- <div v-if="hasValidationErrors('firstName') || hasValidationErrors('lastName')" class="text-sm flex items-center space-x-1 text-red-500">
                                            <i class="fi fi-rr-exclamation flex items-center"></i>
                                            <span>Your name is not valid.</span>
                                        </div> -->
                                    </div>
                                </div>

                                <div class="space-y-3">
                                    <h2 class="font-bold text-lg">Social media</h2>

                                    <div class="space-y-1">
                                        <div class="border border-gray-300 rounded-lg overflow-hidden divide-y">
                                            <div class="relative">
                                                <input id="instagram" ref="firstName" type="text" class="block px-2.5 pb-2.5 pt-5 w-full text-gray-900 bg-white appearance-none focus:border-2 focus:border-gray-800 focus:outline-none focus:ring-0 peer rounded-lg" placeholder=" " />
                                                <label for="instagram" class="absolute text-gray-500 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] start-2.5 peer-focus:text-primary  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto" >Instagram</label>
                                            </div>

                                            <div class="relative">
                                                <input id="facebook" type="text" class="block px-2.5 pb-2.5 pt-5 w-full text-gray-900 bg-white appearance-none focus:border-2 focus:border-gray-800 focus:outline-none focus:ring-0 peer rounded-lg" placeholder=" " />
                                                <label for="facebook" class="absolute text-gray-500 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] start-2.5 peer-focus:text-primary  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto" >Facebook</label>
                                            </div>

                                            <div class="relative">
                                                <input id="linkedin" type="text" class="block px-2.5 pb-2.5 pt-5 w-full text-gray-900 bg-white appearance-none focus:border-2 focus:border-gray-800 focus:outline-none focus:ring-0 peer rounded-lg" placeholder=" " />
                                                <label for="linkedin" class="absolute text-gray-500 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] start-2.5 peer-focus:text-primary  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto" >LinkedIn</label>
                                            </div>
                                        </div>

                                        <!-- <div v-if="hasValidationErrors('firstName') || hasValidationErrors('lastName')" class="text-sm flex items-center space-x-1 text-red-500">
                                            <i class="fi fi-rr-exclamation flex items-center"></i>
                                            <span>Your name is not valid.</span>
                                        </div> -->
                                    </div>
                                </div>

                                <div class="space-y-3">
                                    <h2 class="font-bold text-lg">Address</h2>

                                    <div class="border border-gray-300 rounded-lg divide-y divide-gray-300">
                                        <div class="flex divide-x divide-gray-300">
                                            <div class="relative w-3/4">
                                                <input id="street" type="text" class="block rounded-lg px-2.5 pb-2.5 pt-5 w-full text-gray-900 bg-white appearance-none focus:border-2 focus:border-gray-800 focus:outline-none focus:ring-0 peer" placeholder=" "  />
                                                <label for="street" class="absolute text-gray-500 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] start-2.5 peer-focus:text-primary  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">Street</label>
                                            </div>
                                            <div class="relative w-1/4">
                                                <input id="house_number" type="text" class="block rounded-lg px-2.5 pb-2.5 pt-5 w-full text-gray-900 bg-whiteappearance-none focus:border-2 focus:border-gray-800 focus:outline-none focus:ring-0 peer" placeholder=" "  />
                                                <label for="house_number" class="absolute text-gray-500 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] start-2.5 peer-focus:text-primary  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">House number</label>
                                            </div>
                                        </div>

                                        <div class="relative w-full">
                                            <input id="apartment" type="text" class="block rounded-lg px-2.5 pb-2.5 pt-5 w-full text-gray-900 bg-whiteappearance-none focus:border-2 focus:border-gray-800 focus:outline-none focus:ring-0 peer" placeholder=" "  />
                                            <label for="apartment" class="absolute text-gray-500 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] start-2.5 peer-focus:text-primary  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">Apartment</label>
                                        </div>

                                        <div class="relative w-full">
                                            <input id="district" type="text" class="block rounded-lg px-2.5 pb-2.5 pt-5 w-full text-gray-900 bg-whiteappearance-none focus:border-2 focus:border-gray-800 focus:outline-none focus:ring-0 peer" placeholder=" "  />
                                            <label for="district" class="absolute text-gray-500 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] start-2.5 peer-focus:text-primary  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">District</label>
                                        </div>

                                        <div class="flex divide-x divide-gray-300">
                                            <div class="relative w-1/2">
                                                <input id="zipcode" type="text" class="block rounded-lg px-2.5 pb-2.5 pt-5 w-full text-gray-900 bg-white appearance-none focus:border-2 focus:border-gray-800 focus:outline-none focus:ring-0 peer" placeholder=" "  />
                                                <label for="zipcode" class="absolute text-gray-500 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] start-2.5 peer-focus:text-primary  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">Zipcode</label>
                                            </div>
                                            <div class="relative w-1/2">
                                                <input id="city" type="text" class="block rounded-lg px-2.5 pb-2.5 pt-5 w-full text-gray-900 bg-whiteappearance-none focus:border-2 focus:border-gray-800 focus:outline-none focus:ring-0 peer" placeholder=" "  />
                                                <label for="city" class="absolute text-gray-500 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] start-2.5 peer-focus:text-primary  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">City</label>
                                            </div>
                                        </div>

                                        <div class="relative w-full">
                                            <select class="block rounded-lg px-2.5 pb-2.5 pt-5 w-full text-gray-900 bg-whiteappearance-none focus:border-2 focus:border-gray-800 focus:outline-none focus:ring-0 peer">
                                                <option v-for="country in countries" :value="country.id">{{ $t(`countries.${ country.name }`) }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div class="space-y-3">
                                    <h2 class="font-bold text-lg">Services</h2>

                                    <ul class="grid grid-cols-3 gap-5">
                                        <li class="group border flex flex-col items-center justify-center rounded-lg text-gray-500 p-5 hover:border-primary hover:cursor-pointer hover:text-primary space-y-1">
                                            <i class="fi fi-rr-label text-2xl"></i>
                                            <h3 class="font-bold">Selling</h3>
                                        </li>
                                        <li class="group border flex flex-col items-center justify-center rounded-lg text-gray-500 p-5 hover:border-primary hover:cursor-pointer hover:text-primary space-y-1">
                                            <i class="fi fi-rr-handshake text-2xl"></i>
                                            <h3 class="font-bold">Purchase</h3>
                                        </li>
                                        <li class="group border flex flex-col items-center justify-center rounded-lg text-gray-500 p-5 hover:border-primary hover:cursor-pointer hover:text-primary space-y-1">
                                            <i class="fi fi-rr-rent text-2xl"></i>
                                            <h3 class="font-bold">Rental</h3>
                                        </li>
                                        <li class="group border flex flex-col items-center justify-center rounded-lg text-gray-500 p-5 hover:border-primary hover:cursor-pointer hover:text-primary space-y-1">
                                            <i class="fi fi-rr-tags text-2xl"></i>
                                            <h3 class="font-bold">Appraisals</h3>
                                        </li>
                                        <li class="group border flex flex-col items-center justify-center rounded-lg text-gray-500 p-5 hover:border-primary hover:cursor-pointer hover:text-primary space-y-1">
                                            <i class="fi fi-rr-key text-2xl"></i>
                                            <h3 class="font-bold">Hire</h3>
                                        </li>
                                        <li class="group border flex flex-col items-center justify-center rounded-lg text-gray-500 p-5 hover:border-primary hover:cursor-pointer hover:text-primary space-y-1">
                                            <i class="fi fi-rr-piggy-bank text-2xl"></i>
                                            <h3 class="font-bold">Financial advice</h3>
                                        </li>
                                    </ul>
                                </div>

                                <div class="space-y-3">
                                    <h2 class="font-bold text-lg">Description</h2>
                                    <ckeditor :editor="ClassicEditor"  :config="editorConfig"></ckeditor>
                                </div>

                                <button class="bg-primary w-full rounded-lg text-white font-semibold py-3 px-5 hover:shadow-xl">Create Profile</button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </dialog>
    </div>
</template>

<script>
	import { ref, provide } from 'vue'
    import { useCountries } from '../lib/countries'
    import { ClassicEditor } from '@ckeditor/ckeditor5-editor-classic';
    import { Essentials } from '@ckeditor/ckeditor5-essentials';
    import { Bold, Italic } from '@ckeditor/ckeditor5-basic-styles';

    export default {
	    props: [],
        components: {
        },
	    setup(props) {
            const { countries } = useCountries()
            const modalShown = ref(false)

            const openModal = () => {
                modalShown.value = true
            }

            const editorConfig = ref({
                plugins: [
                    Essentials,
                    Bold,
                    Italic
                ],
                toolbar: {
                    items: [
                        'undo',
                        'redo',
                        '|',
                        'bold',
                        'italic'
                    ]
                }
            })

            return {
                modalShown,
                openModal,
                countries,
                ClassicEditor,
                editorConfig
            }
		}
	}
</script>
