<template>
    <div>
        <dialog class="block relative w-full z-50 p-0 z-10" v-if="showModal">
            <div class="md:p-7 flex justify-center items-start overflow-x-hidden overflow-y-auto fixed left-0 top-0 w-full h-full bg-gray-900 bg-opacity-50 z-50">
                <div class="flex 2xl:w-1/3 xl:w-1/2 md:w-2/3 w-full relative items-center">
                    <div class="bg-white w-full md:rounded-xl shadow-xl relative z-10">
                        <div class="flex flex-col items-start w-full">
                            <div class="md:px-5 md:py-5 p-3 flex items-center w-full border-b">
                                <button @click="showModal = false" class="w-8 h-8 hover:bg-gray-100 rounded-full flex justify-center items-center">
                                    <i class="fi fi-rr-cross-small text-xl flex items-center"></i>
                                </button>

                                <div class="text-center text-lg font-bold w-full">
                                    <span>Select Currency</span>
                                </div>
                            </div>

                            <div class="w-full space-y-3 p-5">
                                <ul v-if="islink" class="grid grid-cols-3 gap-4">
                                    <li v-for="currency in currencies">
                                        <a :href="`?currency=${ currency.symbol }`" class="p-3 block hover:bg-gray-100 rounded-xl" v-bind:class="{'border border-gray-600': currency.id === modelValue.id}">
                                            <div>{{ $t(`currencies.${ currency.name }`) }}</div>
                                            <div class="text-gray-400 text-sm">{{ currency.symbol }} - {{ currency.symbol_icon }}</div>
                                        </a>
                                    </li>
                                </ul>
                                <ul v-else class="border rounded-lg divide-y h-72 overflow-y-auto">
                                    <li v-for="currency in currencies" class="p-3 hover:bg-gray-100 hover:cursor-pointer flex space-x-3" @click="selectCurrency(currency)">
                                        <img :src="`/images/flags/${ currency.icon }`" class="w-4" />
                                        <span>{{ $t(`currencies.${ currency.name }`) }}</span>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </dialog>
    </div>
</template>

<script>
	import { ref, inject } from 'vue'
    import { useCurrencies } from '../lib/currencies';

    export default {
	    props: ['islink', 'modelValue'],
        components: {

        },
	    setup(props, { emit }) {
            const showModal = ref(false)
            const { currencies } = useCurrencies()

            const selectCurrency = (currency) => {
                emit('update:modelValue', currency)
                showModal.value = false
            }

            const openModal = () => {
                showModal.value = true
            }

            return {
                showModal,
                currencies,
                selectCurrency,
                openModal
            }
		}
	}
</script>
