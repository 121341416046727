<template>
    <ListingNotFinishedModal v-if="isNew" />

    <div class="w-full overflow-y-auto" ref="wizardContent">
        <div class="2xl:w-1/3 xl:w-1/2 w-full md:px-0 px-5 mx-auto space-y-8">
            <TypeOfListing v-if="currentStep === 'type_of_listing'" ref="stepView" />
            <Address v-if="currentStep === 'address'" ref="stepView" />
            <AskingPrice v-if="currentStep === 'asking_price'" ref="stepView" />
            <Description v-if="currentStep === 'description'" ref="stepView" />
            <Photos v-if="currentStep === 'photos'" ref="stepView" class="space-y-8" />
            <ConstructionFeatures v-if="currentStep === 'construction_features'" @resetSteps="resetSteps" ref="stepView" />
            <AreasFeatures v-if="currentStep === 'areas_features'" ref="stepView" />
            <LayoutFeatures v-if="currentStep === 'layout_features'" ref="stepView" />
            <EnergyFeatures v-if="currentStep === 'energy_features'" ref="stepView" />
            <ExteriorFeatures v-if="currentStep === 'exterior_features'" ref="stepView" />
            <StorageFeatures v-if="currentStep === 'storage_features'" ref="stepView" />
            <GarageFeatures v-if="currentStep === 'garage_features'" ref="stepView" />
            <ParkingFeatures v-if="currentStep === 'parking_features'" ref="stepView" />
        </div>
    </div>

    <div class="w-full md:p-8 py-5 px-3 flex justify-between relative items-center">
        <div class="h-2 bg-gray-200 w-full absolute left-0 top-0">
            <div class="h-2 bg-gray-800 transition-all duration-200" v-bind:style="{'width': `${ stepPercentage }%`}"></div>
        </div>

        <button class="font-semibold underline" @click="(step > 1)? step -= 1 : ''">Back</button>

        <div v-if="!loading">
            <button v-if="step < steps.length" class="font-semibold bg-gray-800 text-white px-8 py-3 rounded-xl" @click="nextStep()">Next</button>
            <button v-if="step === steps.length" class="font-semibold bg-gray-800 text-white px-8 py-3 rounded-xl" @click="nextStep(true)">Finish</button>
        </div>
        <loading v-else color="text-gray-800" />
    </div>
</template>


<script>
	import { ref, provide, computed } from 'vue'
    import { useListingEditor } from '../../lib/listingEditor'

    import TypeOfListing from './TypeOfListing.vue'
    import Address from './Address.vue'
    import AskingPrice from './AskingPrice.vue'
    import Description from './Description.vue'
    import Photos from './Photos.vue'
    import ConstructionFeatures from './ConstructionFeatures.vue'
    import AreasFeatures from './AreasFeatures.vue'
    import LayoutFeatures from './LayoutFeatures.vue'
    import EnergyFeatures from './EnergyFeatures.vue'
    import ExteriorFeatures from './ExteriorFeatures.vue'
    import StorageFeatures from './StorageFeatures.vue'
    import GarageFeatures from './GarageFeatures.vue'
    import ParkingFeatures from './ParkingFeatures.vue'
    import ListingNotFinishedModal from './ListingNotFinishedModal.vue'

	export default {
        props: ['listing'],
        components: {
            TypeOfListing,
            Address,
            AskingPrice,
            Description,
            Photos,
            ConstructionFeatures,
            AreasFeatures,
            LayoutFeatures,
            EnergyFeatures,
            ExteriorFeatures,
            StorageFeatures,
            GarageFeatures,
            ParkingFeatures,
            ListingNotFinishedModal
        },
        watch: {
            stepView(value) {
                if(value) {
                    this.wizardContent.scrollTo({ top: 0, behavior: 'smooth' })
                }
            }
        },
        setup(props) {
            const { step, steps, stepView, stepPercentage, currentStep, validationErrors, resetSteps, nextStep, loading, listing, editListing, isNew } = useListingEditor()

            const wizardContent = ref(null)

            if(props.listing) {
                editListing(props.listing)
            }

            provide('listing', listing)
            provide('validationErrors', validationErrors)
            provide('steps', steps)

            return {
                stepView,
                stepPercentage,
                step,
                steps,
                currentStep,
                resetSteps,
                nextStep,
                loading,
                isNew,
                wizardContent
            };
        }
    }
</script>
