<template>
    <div class="space-y-8 py-5">
        <h1 class="text-5xl font-bold text-center">Garage Features</h1>

        <div class="space-y-2">
            <h2 class="font-bold">Garage Location</h2>

            <ul class="border rounded-lg divide-y">
                <FacilityBooleanRow v-for="garageLocation in garageLocations" :facility="garageLocation" v-model="listing.features.garage.locations" />
            </ul>
        </div>

        <div v-if="listing.features.garage.locations && listing.features.garage.locations.filter(f => f.has).length" class="space-y-8">
            <div class="space-y-2">
                <h2 class="font-bold">Capacity</h2>

                <div class="border w-full rounded-lg flex items-center overflow-hidden">
                    <input type="number" class="p-5 w-full"  v-model="listing.features.construction.parking_capacity" />
                    <div class="px-3">Car(s)</div>
                </div>
            </div>

            <div class="space-y-2">
                <h2 class="font-bold">Garage Insulation</h2>

                <ul class="border rounded-lg divide-y">
                    <FacilityBooleanRow v-for="garageInsulation in garageInsulations" :facility="garageInsulation" v-model="listing.features.garage.insulations" />
                </ul>
            </div>

            <div class="space-y-2">
                <h2 class="font-bold">Facilities</h2>

                <ul class="border rounded-lg divide-y">
                    <FacilityBooleanRow v-for="garageFacility in garageFacilities" :facility="garageFacility" v-model="listing.features.garage.facilities" />
                </ul>

            </div>
        </div>
    </div>
</template>

<script>
	import { ref, inject } from 'vue'
    import FacilityBooleanRow from './FacilityBooleanRow.vue'

    export default {
	    props: [],
        components: {
            FacilityBooleanRow
        },
	    setup(props) {
            const listing = inject('listing')

            if(listing.value.features.garage.locations === undefined) {
                listing.value.features.garage.locations = []
            }

            if(listing.value.features.garage.insulations === undefined) {
                listing.value.features.garage.insulations = []
            }

            if(listing.value.features.garage.facilities === undefined) {
                listing.value.features.garage.facilities = []
            }

            const validate = () => {

            }

            const garageLocations = ref([
                {"name": "built_in"},
                {"name": "attached_brick_garage"},
                {"name": "detached_brick_garage"},
                {"name": "attached_wooden_garage"},
                {"name": "carport"}
            ])

            const garageInsulations = ref([
                {"name": "roof_insulation"},
                {"name": "insulated_walls"},
                {"name": "floor_insulation"}
            ])

            const garageFacilities = ref([
                {"name": "electrical_door"},
                {"name": "electricity"},
                {"name": "heating"},
                {"name": "running_water"},
                {"name": "loft"}
            ])

            return {
                listing,
                validate,
                garageLocations,
                garageInsulations,
                garageFacilities
            }
        }
    }
</script>
