<template>
    <div class="space-y-3">
        <ListingBlock :listing="$props.listing" />

        <button class="underline">Add Note</button>
    </div>
</template>

<script>
    import ListingBlock from './ListingBlock.vue'

	export default {
	    props: ['listing'],
        components: {
            ListingBlock
        },
	    setup(props) {

            return {

            }
		}
	}
</script>

