<template>
    <div>
        <dialog class="block relative w-full z-50 p-0 z-10" v-if="showModal">
            <div class="md:p-7 flex justify-center items-start overflow-x-hidden overflow-y-auto fixed left-0 top-0 w-full h-full bg-gray-900 bg-opacity-50 z-50">
                <div class="flex 2xl:w-1/3 xl:w-1/2 md:w-2/3 w-full relative items-center">
                    <div class="bg-white w-full md:rounded-xl shadow-xl relative z-10">
                        <div class="flex flex-col items-start w-full">
                            <div class="md:px-5 md:py-5 p-3 flex items-center w-full border-b">
                                <button @click="showModal = false" class="w-8 h-8 hover:bg-gray-100 rounded-full flex justify-center items-center">
                                    <i class="fi fi-rr-cross-small text-xl flex items-center"></i>
                                </button>

                                <div class="text-center text-lg font-bold w-full">
                                    <span>Select Language</span>
                                </div>
                            </div>

                            <div class="w-full space-y-3 p-5">
                                <ul v-if="islink" class="border rounded-lg divide-y overflow-hidden">
                                    <li v-for="language in languages">
                                        <a :href="`?locale=${ language.locale }`" class="block p-2 hover:bg-gray-100 hover:cursor-pointer flex space-x-2">
                                            <img :src="`/images/flags/${ language.icon }`" class="w-5" />
                                            <span>{{ language.name }}</span>
                                        </a>
                                    </li>
                                </ul>
                                <ul v-else class="border rounded-lg divide-y overflow-hidden">
                                    <li v-for="language in languages" class="p-3 hover:bg-gray-100 hover:cursor-pointer flex space-x-3" @click="selectLanguage(language)">
                                        <img :src="`/images/flags/${ language.icon }`" class="w-5" />
                                        <span>{{ language.name }}</span>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </dialog>
    </div>
</template>

<script>
	import { ref } from 'vue'
    import { useLanguages } from '../lib/languages'

	export default {
	    props: ['islink', 'modelValue'],
	    setup(props, { emit }) {
            const showModal = ref(false)
            const { languages } = useLanguages()

            const openModal = () => {
                showModal.value = true
            }

            const selectLanguage = (language) => {
                emit('update:modelValue', language)
                showModal.value = false
            }

            return {
                showModal,
                languages,
                openModal,
                selectLanguage
            }
		}
	}
</script>
