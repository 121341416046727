import { ref } from 'vue'

const validationErrors = ref([])

export const useFormValidator = () => {

    const hasValidationErrors = (fieldName: string) => {
        return validationErrors.value.includes(fieldName)
    }

    const resetErrors = () => {
        validationErrors.value = []
    }

    return {
        hasValidationErrors,
        resetErrors,
        validationErrors
    }
}
