<template>
    <div class="relative" ref="menuEl">
        <button class="border rounded-lg flex space-x-3 items-center p-2 hover:shadow-md duration-150" @click="showMenu = !showMenu">
            <div class="px-1">
                <i class="fi fi-rr-menu-burger flex items-center"></i>
            </div>

            <div class="w-8 h-8 bg-gray-600 rounded-full flex items-center justify-center text-white">
                <i class="fi fi-rr-user"></i>
            </div>

        </button>

        <Transition enter-from-class="opacity-0 translate-y-5"
                enter-to-class="opacity-100 translate-y-0"
                enter-active-class="transform transition ease-out duration-200"
                leave-active-class="transform transition ease-in duration-150"
                leave-from-class="opacity-100 translate-y-0"
                leave-to-class="opacity-0 translate-y-5">
            <div v-if="showMenu" class="absolute right-0 mt-3 shadow-xl py-3 rounded-lg border bg-white w-64 z-10">
                <div v-if="user">
                    <ul>
                        <li>
                            <a href="/user/account/messages" class="font-semibold py-2 px-5 w-full hover:bg-gray-100 block text-left flex space-x-1">
                                <div>{{ $t('menu.user_menu.messages') }}</div>
                                <!-- <span class="bg-red-500 rounded-lg w-6 h-6 text-white text-xs flex-none flex justify-center items-center">99</span> -->
                            </a>
                        </li>
                        <li>
                            <a href="/user/account/listings" class="font-semibold py-2 px-5 w-full hover:bg-gray-100 block text-left">{{ $t('menu.user_menu.listings') }}</a>
                        </li>
                        <li>
                            <a href="/user/account/saved/searches" class="font-semibold py-2 px-5 w-full hover:bg-gray-100 block text-left">{{ $t('menu.user_menu.saved_searches') }}</a>
                        </li>
                        <li>
                            <a href="/user/account/saved/houses" class="font-semibold py-2 px-5 w-full hover:bg-gray-100 block text-left">{{ $t('menu.user_menu.saved_houses') }}</a>
                        </li>
                    </ul>

                    <hr class="my-2" />

                    <ul>
                        <li>
                            <a href="/user/account" class="py-2 px-5 w-full hover:bg-gray-100 block">{{ $t('menu.user_menu.my_account') }}</a>
                        </li>
                        <li>
                            <a href="/user/logout" class="py-2 px-5 w-full hover:bg-gray-100 block">{{ $t('menu.user_menu.logout') }}</a>
                        </li>
                        <li>
                            <a href="/help-center" class="py-2 px-5 w-full hover:bg-gray-100 block">{{ $t('menu.user_menu.help_center') }}</a>
                        </li>
                    </ul>
                </div>

                <div v-else>
                    <ul>
                        <li>
                            <button class="font-semibold py-2 px-5 w-full hover:bg-gray-100 block text-left" @click="showAuthModal()">{{ $t('menu.user_menu.sign_up') }}</button>
                        </li>
                        <li>
                            <button class="py-2 px-5 w-full hover:bg-gray-100 block text-left" @click="showAuthModal()">{{ $t('menu.user_menu.login') }}</button>
                        </li>
                    </ul>

                    <hr class="my-2" />

                    <ul>
                        <li>
                            <a href="/help-center" class="py-2 px-5 w-full hover:bg-gray-100 block">{{ $t('menu.user_menu.help_center') }}</a>
                        </li>
                    </ul>
                </div>
            </div>
        </Transition>

    </div>
</template>

<script>
	import { ref } from 'vue'
    import { onClickOutside } from '@vueuse/core'
    import { useAuth } from '../lib/auth'
    import { useUser } from '../lib/user'

    export default {
	    props: [],
        components: {
        },
	    setup(props) {
            const showMenu = ref(false)
            const menuEl = ref(null)
            const { showAuthModal } = useAuth()
            const { user } = useUser()

            onClickOutside(menuEl, (event) => showMenu.value = false)

            return {
                showMenu,
                menuEl,
                showAuthModal,
                user
            }
		}
	}
</script>
