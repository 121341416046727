<template>
    <div>
        <h1 class="text-5xl font-bold text-center">Type of listing</h1>

        <div class="space-y-3">
            <div class="p-5" v-bind:class="{'rounded-lg border-red-500 border-2': validationErrors.includes('typeOfListing')}">
                <ul class="space-y-5">
                    <li class="border rounded-xl p-5 hover:border-gray-800 hover:cursor-pointer flex items-center space-x-5" @click="listing.type.name = 'selling'" v-bind:class="{'bg-gray-50 border-gray-700': listing.type.name == 'selling'}">
                        <div class="flex-1 space-y-3">
                            <h2 class="text-xl font-semibold">Sell your place</h2>
                            <p class="text-sm">Quickly list your property for sale with our easy and secure process.</p>
                        </div>
                        <div class="w-8">
                            <i class="fi fi-rr-house-building text-xl"></i>
                        </div>
                    </li>
                    <li class="border rounded-xl p-5 hover:border-gray-800 hover:cursor-pointer flex items-center space-x-5" @click="listing.type.name = 'leasing'" v-bind:class="{'bg-gray-50 border-gray-700': listing.type.name == 'leasing'}">
                        <div class="flex-1 space-y-3">
                            <h2 class="text-xl font-semibold">Rent out your place</h2>
                            <p class="text-sm">List your rental easily and reach potential tenants fast.</p>
                        </div>
                        <div class="w-8">
                            <i class="fi fi-rr-rent text-xl"></i>
                        </div>
                    </li>
                </ul>
            </div>

            <div v-if="validationErrors.includes('typeOfListing')" class="bg-red-600 text-white p-1 rounded text-sm">
                Please select what type of listing you want to post.
            </div>
        </div>
    </div>
</template>

<script>
	import { ref, inject } from 'vue'

	export default {
    props: [],
    components: {
    },
    setup(props) {
        const listing = inject('listing')
        const validationErrors = inject('validationErrors')

        const validate = () => {
            if(listing.value.type.name === null) {
                validationErrors.value.push('typeOfListing')
            }
        }

        return {
            listing,
            validate,
            validationErrors
        };
    }
}
</script>
