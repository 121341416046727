import { Loader } from "@googlemaps/js-api-loader"
import { MarkerClusterer, Renderer } from "@googlemaps/markerclusterer";
import { v4 as uuidv4 } from 'uuid';

export interface Coordinate {
    latitude: number,
    longitude: number
}

export class GoogleMap {
    map: Map
    loader: Loader
    markers: Array<any>
    markerCluster: MarkerClusterer

    constructor(element: HTMLElement, options: any) {
        this.markers = []

        this.loader = new Loader({
            apiKey: import.meta.env.VITE_GOOGLE_API_KEY,
            version: "weekly"
        })
    }

    async createMap(element: HTMLElement, options: any) {
        const { Map } = await this.loader.importLibrary('maps')

        this.map = new Map(element, { ...options })
    }

    async fitBounds(southwest, northeast) {
        const bounds = new google.maps.LatLngBounds()

        bounds.union(new google.maps.LatLngBounds(southwest, northeast));

        this.map.fitBounds(bounds)
    }

    async createAdvanceMarker (identifier: number | string | null, coordinates: Coordinate, htmlElement: HTMLElement) {
        const { AdvancedMarkerElement } = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary

        const marker = new AdvancedMarkerElement({
            map: this.map,
            position: new google.maps.LatLng(coordinates.latitude, coordinates.longitude),
            content: htmlElement,
        })

        marker.id = identifier ?? uuidv4()

        this.markers.push(marker)

        if(this.markerCluster) {
            this.markerCluster.addMarker(marker)
        }

        return marker
    }

    removeMarker (marker) {
        this.markers = this.markers.filter(m => marker.id != m.id)

        if(this.markerCluster) {
            this.markerCluster.removeMarker(marker)
        }

        marker.setMap(null)
    }

    activateMarkerClusterer (renderer: Renderer | null) {
        if(this.map) {
            this.markerCluster = new MarkerClusterer({
                map: this.map,
                renderer: renderer ?? undefined
            })

            this.markerCluster.addMarkers(this.markers)
        }
    }

    async panTo (coordinates) {
        this.map.panTo(new google.maps.LatLng(coordinates))
    }

    async setBounds (locationData) {
        if(locationData.geometry) {
            const bounds = new google.maps.LatLngBounds

            if (locationData.geometry.viewport) {
                bounds.union(new google.maps.LatLngBounds(locationData.geometry.viewport.southwest, locationData.geometry.viewport.northeast));
            }

            if(locationData.geometry.location) {
                bounds.extend(locationData.geometry.location)
            }

            this.map.fitBounds(bounds)
        }
    }

    async setCenter (coordinates) {
        this.map.setCenter(new google.maps.LatLng(coordinates))
    }
}


// export function useGoogleMaps() {
//     const createMap = async(element: HTMLElement, options: any) => {
//         const loader = new Loader({
//             apiKey: import.meta.env.VITE_GOOGLE_API_KEY,
//             version: "weekly"
//         })

//         const { Map } = await loader.importLibrary('maps')

//         return new Map(element, {
//             ...options
//         })
//     }

//     const setBounds = async(map, locationData) => {
//         if(locationData.geometry) {
//             const bounds = new new google.maps.LatLngBounds

//             if (locationData.geometry.viewport) {
//                 bounds.union(new google.maps.LatLngBounds(locationData.geometry.viewport.southwest, locationData.geometry.viewport.northeast));
//             }

//             if(locationData.geometry.location) {
//                 bounds.extend(locationData.geometry.location)
//             }

//             map.fitBounds(bounds)
//         }
//     }

//     // const fitBounds = async(southwest, northeast) => {
//     //     const { LatLngBounds } = await loader.importLibrary('core')

//     //     const bounds = new LatLngBounds

//     //     bounds.union(new LatLngBounds(southwest, northeast));

//     //     map.fitBounds(bounds)
//     // }



//     // const drawShape = async(shape, color, editable = false) => {
//     //     const { Polygon, Circle, Rectangle} = await loader.importLibrary('maps')

//     //     if(shape.points.type === "polygon") {
//     //         return new google.maps.Polygon({
//     //             id: shape.id,
//     //             paths: shape.points.path,
//     //             strokeColor: color,
//     //             strokeOpacity: 0.8,
//     //             strokeWeight: 2,
//     //             fillColor: color,
//     //             fillOpacity: 0.35,
//     //             editable: editable,
//     //             map: map
//     //         })
//     //     }

//     //     if(shape.points.type === "circle") {
//     //         return new Circle({
//     //             id: shape.id,
//     //             center: shape.points.center,
//     //             radius: shape.points.radius,
//     //             strokeColor: color,
//     //             strokeOpacity: 0.8,
//     //             strokeWeight: 2,
//     //             fillColor: color,
//     //             fillOpacity: 0.35,
//     //             editable: editable,
//     //             map: map
//     //         })
//     //     }

//     //     if(shape.points.type === "rectangle") {
//     //         return new Rectangle({
//     //             id: shape.id,
//     //             bounds: shape.points.bounds,
//     //             strokeColor: color,
//     //             strokeOpacity: 0.8,
//     //             strokeWeight: 2,
//     //             fillColor: color,
//     //             fillOpacity: 0.35,
//     //             editable: editable,
//     //             map: map
//     //         })
//     //     }
//     // }



//     // const createMarker = async(title: string, coordinates: Coordinate, icon: string|null) => {
//     //     const { Marker } = await loader.importLibrary("marker") as google.maps.MarkerLibrary;

//     //     if(icon) {
//     //         return new Marker({
//     //             title: title,
//     //             position: new google.maps.LatLng(coordinates.latitude, coordinates.longitude),
//     //             animation: google.maps.Animation.DROP,
//     //             map: map,
//     //             icon: {
//     //                 url: icon,
//     //                 scaledSize: new google.maps.Size(32, 32)
//     //             }
//     //         })
//     //     }

//     //     return new Marker({
//     //         title: title,
//     //         position: new google.maps.LatLng(coordinates.latitude, coordinates.longitude),
//     //         animation: google.maps.Animation.DROP,
//     //         map: map
//     //     })
//     // }

//     // const getCenter = () => {
//     //     return map.getCenter()
//     // }

//     // const createLatLng = async (coordinates) => {
//     //     const { LatLng } = await loader.importLibrary('core')

//     //     return new LatLng(coordinates)
//     // }

//     // const getMap = () => {
//     //     return map
//     // }

//     return {
//         createMap,
//         setBounds,

//         // map,
//         // loadMap,
//         // setBounds,
//         // fitBounds,
//         // drawShape,
//         // createAdvanceMarker,
//         // createMarker,
//         // getCenter,
//         // getMap,
//         // setCenter,
//         // panTo,
//         // createLatLng
//     }
// }
