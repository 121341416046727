<template>
    <div class="divide-y" v-if="user">
        <div class="space-y-1 flex justify-between items-start py-5">
            <div>
                <h3>Name</h3>
                <p class="text-gray-500 text-sm">{{ user.first_name }} {{ user.last_name}}</p>
            </div>

            <button class="underline font-bold">Edit</button>
        </div>

        <div class="space-y-1 flex justify-between items-start py-5">
            <div>
                <h3>Email address</h3>
                <p class="text-gray-500 text-sm">{{ user.email }}</p>
            </div>

            <button class="underline font-bold">Edit</button>
        </div>

        <div class="space-y-1 flex justify-between items-start py-5">
            <div>
                <h3>Date of birth</h3>
                <p class="text-gray-500 text-sm">{{ user.dob }}</p>
            </div>

            <button class="underline font-bold">Edit</button>
        </div>

        <div class="space-y-1 flex justify-between items-start py-5">
            <div>
                <h3>Phone number</h3>
                <p class="text-gray-500 text-sm">-</p>
            </div>

            <button class="underline font-bold">Edit</button>
        </div>
    </div>
</template>


<script>
	import { ref } from 'vue'
    import { useUser } from '../../lib/user'

	export default {
	    props: [],
	    setup(props) {
            const { user } = useUser()

            return {
                user
            }
		}
	}
</script>
