<template>
    <li class="p-3 flex items-center space-x-5">
        <div class="text-lg font-semibold grow">
            {{ facility.name }}
        </div>

        <div>
            <div class="border rounded-lg divide-x flex items-center hover:shadow-lg w-32 h-12 justify-between">
                <button class="w-1/3 h-full flex justify-center items-center" @click="minus()">
                    <i class="fi fi-rr-minus-circle text-2xl flex items-center"></i>
                </button>
                <div class="w-1/3 text-lg w-10 text-center h-full flex items-center justify-center">
                    {{ qty }}
                </div>
                <button class="w-1/3 h-full flex justify-center items-center" @click="add()">
                    <i class="fi fi-rr-add text-2xl flex items-center"></i>
                </button>
            </div>
        </div>
    </li>
</template>

<script>
    import { ref } from 'vue'

    export default {
        props: ['facility', 'modelValue'],
        watch: {
            qty(value) {
                if(this.$props.modelValue === null || this.$props.modelValue.length === 0) {
                    this.$emit('update:modelValue', [{
                        name: this.$props.facility.name,
                        qty: value
                    }])

                    return
                }

                const facility = this.$props.modelValue.find(f => f.name === this.$props.facility.name)

                if(facility) {
                    this.$emit('update:modelValue', this.$props.modelValue.map((f) => {
                        if(f.name === this.$props.facility.name) {
                            f.qty = value
                        }

                        return f
                    }))

                    return
                }

                this.$props.modelValue.push({
                    name: this.$props.facility.name,
                    qty: value
                })

                this.$emit('update:modelValue', this.$props.modelValue)
            }
        },
	    setup(props, { emit }) {
            const qty = ref((props.modelValue && props.modelValue.find(f => f.name === props.facility.name))? props.modelValue.find(f => f.name === props.facility.name).qty : 0)

            const minus = () => {
                if(qty.value > 0) {
                    qty.value -= 1
                }
            }

            const add = () => {
                qty.value += 1
            }

            return {
                qty,
                minus,
                add
            }
        }
    }
</script>
