import { ref } from 'vue'
import { useApi } from './api'

const { get, data } = useApi('languages')

const languages = ref([])

get().then(() => {
    if(data.value.status) {
        languages.value = data.value.languages
    }
})

export const useLanguages = () => {
    return {
        languages
    }
}
