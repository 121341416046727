<template>
    <div>
        <div class="relative">
            <Transition enter-from-class="opacity-0 translate-y-full"
                                    enter-to-class="opacity-100 translate-y-0"
                                    enter-active-class="transform transition ease-out duration-150 delay-200"
                                    leave-active-class="transform transition ease-in duration-150"
                                    leave-from-class="opacity-100 translate-y-0"
                                    leave-to-class="opacity-0 translate-y-full" appear>

                <div v-if="showingGallery" class="fixed top-0 w-full bg-white h-screen overflow-y-auto z-10" ref="gallery">
                    <div class="fixed top-0 w-full flex justify-between items-center py-3 px-5 bg-white">
                        <button class="hover:bg-gray-200 w-10 h-10 rounded-full flex justify-center items-center" @click="showingGallery = false">
                            <i class="fi fi-rr-angle-small-left flex items-center"></i>
                        </button>

                        <div>
                            <ul class="flex justify-between space-x-5">
                                <li>
                                    <button class="flex items-center space-x-2">
                                        <i class="fi fi-rr-share items-center flex"></i>
                                        <span class="underline font-semibold lg:block hidden">Share</span>
                                    </button>
                                </li>

                                <li>
                                    <toggle-listing-favourite v-if="user" ref="toggleListingFavourite" :listing_id="$props.listing_id">
                                        <template v-slot:is-not-saved>
                                            <button class="flex items-center space-x-2">
                                                <i class="fi fi-rr-heart items-center flex"></i>
                                                <span class="underline font-semibold lg:block hidden">Save</span>
                                            </button>
                                        </template>

                                        <template v-slot:is-saved>
                                            <button class="flex items-center space-x-2 text-primary">
                                                <i class="fi fi-rr-heart items-center flex"></i>
                                                <span class="underline font-semibold lg:block hidden">Saved</span>
                                            </button>
                                        </template>
                                    </toggle-listing-favourite>
                                    <button v-else @click="showAuthModal($props.url)" class="flex items-center space-x-2">
                                        <i class="fi fi-rr-heart items-center flex"></i>
                                        <span class="underline font-semibold lg:block hidden">Save</span>
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="lg:w-2/5 w-full mx-auto grid grid-cols-2 gap-4 lg:py-5 py-8">
                        <div v-for="(photo, key) in $props.photos" v-bind:class="{'col-span-2': (key % 3 == 0)}" :id="`photo_${ key }`" @click="$refs.slideShow.showPhoto(key)">
                            <img :src="photo.image_url" class="w-full object-cover" />
                        </div>
                    </div>
                </div>
            </Transition>
        </div>

        <SlideShow ref="slideShow" :photos="$props.photos" :url="$props.url" @slideShowClosed="slideShowWasOpen = true"  />
    </div>
</template>

<script>
	import { ref } from 'vue'
    import ToggleListingFavourite from './ToggleListingFavourite.vue'
    import SlideShow from './SlideShow.vue'
    import { useUser } from '../lib/user'
    import { useAuth } from '../lib/auth'

    export default {
	    props: ['photos', 'listing_id', 'url'],
        components: {
            ToggleListingFavourite,
            SlideShow
        },
        watch: {
            gallery(value) {
                if(value && this.selectedImageKey) {
                    value.querySelector(`#photo_${ this.selectedImageKey }`).scrollIntoView({behavior: 'smooth'})
                }

                this.selectedImageKey = null
            }
        },
	    setup(props) {
            const showingGallery = ref(false)
            const { user } = useUser()
            const { showAuthModal } = useAuth()
            const gallery = ref(null)
            const selectedImageKey = ref(null)
            const slideShowWasOpen = ref(false)

            const showGallery = (imageKey = null) => {
                showingGallery.value = true

                if(imageKey) {
                    selectedImageKey.value = imageKey
                }
            }

            return {
                showingGallery,
                showGallery,
                user,
                showAuthModal,
                gallery,
                selectedImageKey,
                slideShowWasOpen
            }
		},
        mounted() {
            addEventListener("keydown", (event) => {
                if(event.code === 'Escape') {
                    if(this.slideShowWasOpen === false && this.showingGallery === true) {
                        this.showingGallery = false
                    }

                    if(this.slideShowWasOpen === true) {
                        this.slideShowWasOpen = false
                    }
                }
            })
        }
	}
</script>
