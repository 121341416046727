<template>
    <label class="inline-flex items-center cursor-pointer">
        <input type="checkbox" value="" class="sr-only peer" v-model="state">
        <div class="relative w-11 h-6 bg-gray-300 peer-focus:outline-none rounded-full peer  peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-primary"></div>
    </label>
</template>

<script>
    import { watch, ref } from 'vue'

    export default {
        props: ['modelValue'],
        setup(props, { emit }){
            const state = ref(props.modelValue)

            watch(() => state.value, (value, prevValue) => {
                emit('update:modelValue', value)
            })

            return {
                state
            }
        }
    }
</script>
