<template>
    <div class="space-y-8 py-5">
        <h1 class="text-5xl font-bold text-center">{{ $t(`listing_editor.construction_features`) }}</h1>

        <div class="space-y-2 p-5" v-bind:class="{'rounded-lg border-red-500 border-2': validationErrors.includes('property_type')}">
            <h2 class="font-bold">{{ $t(`features.property_type`) }}</h2>

            <select class="border w-full rounded-lg p-5" v-model="listing.features.construction.property_type">
                <option :value="null" disabled="disabled">{{ $t(`listing_editor.select_property_type`) }}</option>
                <option v-for="property_type in property_types" :value="property_type.name">{{ $t(`features.property_type_options.${ property_type.name }`) }}</option>
            </select>
        </div>

        <div v-if="getOrientations" class="space-y-2 p-5" v-bind:class="{'rounded-lg border-red-500 border-2': validationErrors.includes('property_orientation')}">
            <h2 class="font-bold">{{ $t(`features.orientation`) }}</h2>

            <select class="border w-full rounded-lg p-5" v-model="listing.features.construction.property_orientation">
                <option :value="null" disabled="disabled">{{ $t(`listing_editor.select_orientation`) }}</option>
                <option v-for="orientation in getOrientations" :value="orientation">{{ $t(`features.orientation_options.${ orientation }`) }}</option>
            </select>
        </div>

        <div v-if="getTypes" class="space-y-2 p-5" v-bind:class="{'rounded-lg border-red-500 border-2': validationErrors.includes('type')}">
            <h2 class="font-bold">{{ $t(`features.type`) }}</h2>

            <select class="border w-full rounded-lg p-5" v-model="listing.features.construction.type">
                <option :value="null" disabled="disabled">{{ $t(`listing_editor.select_type`) }}</option>
                <option v-for="propertyType in getTypes" :value="propertyType">{{ $t(`features.type_options.${ propertyType }`) }}</option>
            </select>
        </div>

        <div v-if="listing.features.construction.property_type === 'parking'" class="space-y-2 p-5" v-bind:class="{'rounded-lg border-red-500 border-2': validationErrors.includes('parking_capacity')}">
            <h2 class="font-bold">Capacity</h2>

            <div class="border w-full rounded-lg flex items-center overflow-hidden">
                <input type="number" class="p-5 w-full"  v-model="listing.features.construction.parking_capacity" />
                <div class="px-3">Car(s)</div>
            </div>
        </div>

        <div v-if="['house', 'apartment', 'parking', 'storage_space', 'storage', 'berth', 'substructure'].includes(listing.features.construction.property_type)" class="space-y-8">
            <div class="space-y-2 p-5" v-bind:class="{'rounded-lg border-red-500 border-2': validationErrors.includes('building_type')}">
                <h2 class="font-bold">{{ $t(`features.building_type`) }}</h2>

                <div class="flex space-x-5">
                    <div class="border rounded-lg p-3 flex flex-col items-center space-y-2 hover:cursor-pointer w-1/2" @click="listing.features.construction.building_type = 'newly_built'" v-bind:class="{'border-primary text-primary': listing.features.construction.building_type === 'newly_built', 'hover:border-gray-700': listing.features.construction.building_type !== 'newly_built'}">
                        <i class="fi fi-rr-build text-2xl"></i>
                        <span>{{ $t(`features.building_type_options.newly_built`) }}</span>
                        <i v-if="listing.features.construction.building_type === 'newly_built'" class="fi fi-rr-dot-circle flex items-center"></i>
                        <i v-else class="fi fi-rr-circle flex items-center"></i>
                    </div>

                    <div class="border rounded-lg p-3 flex flex-col items-center space-y-2 hover:cursor-pointer w-1/2" @click="listing.features.construction.building_type = 'resale'" v-bind:class="{'border-primary text-primary': listing.features.construction.building_type === 'resale', 'hover:border-gray-700': listing.features.construction.building_type !== 'resale'}">
                        <i class="fi fi-rr-home text-2xl"></i>
                        <span>{{ $t(`features.building_type_options.resale`) }}</span>
                        <i v-if="listing.features.construction.building_type === 'resale'" class="fi fi-rr-dot-circle flex items-center"></i>
                        <i v-else class="fi fi-rr-circle flex items-center"></i>
                    </div>
                </div>
            </div>

            <div class="space-y-2 p-5" v-bind:class="{'rounded-lg border-red-500 border-2': validationErrors.includes('year_of_construction')}">
                <h2 class="font-bold">Year of Construction</h2>

                <input class="border w-full rounded-lg p-5" v-model="listing.features.construction.year_of_construction" />
            </div>
        </div>

        <div v-if="listing.features.construction.property_type === 'house' || listing.features.construction.property_type === 'apartment'" class="space-y-8">
            <div class="space-y-2 p-5" v-bind:class="{'rounded-lg border-red-500 border-2': validationErrors.includes('type_of_roof')}">
                <h2 class="font-bold">Type of Roof</h2>

                <div class="grid grid-cols-3 gap-3">
                    <div v-for="roof_type in roof_types" class="border rounded-lg p-3 flex flex-col items-center space-y-2 hover:cursor-pointer" @click="listing.features.construction.type_of_roof = roof_type" v-bind:class="{'border-primary text-primary': listing.features.construction.type_of_roof === roof_type, 'hover:border-gray-700': listing.features.construction.type_of_roof !== roof_type}">
                        <img :src="`/images/roof_types/${ roof_type }.svg`" class="w-16"/>
                        <span>{{ $t(`features.type_of_roof_options.${ roof_type }`) }}</span>
                        <i v-if="listing.features.construction.type_of_roof === roof_type" class="fi fi-rr-dot-circle flex items-center"></i>
                        <i v-else class="fi fi-rr-circle flex items-center"></i>
                    </div>
                </div>
            </div>

            <div class="space-y-2 p-5" v-bind:class="{'rounded-lg border-red-500 border-2': validationErrors.includes('roof_cover')}">
                <h2 class="font-bold">Roof Cover</h2>

                <select class="border w-full rounded-lg p-5" v-model="listing.features.construction.roof_cover">
                    <option :value="null" disabled="disabled">Select Roof Cover</option>
                    <option v-for="roof_cover in roof_covers" :value="roof_cover">{{ $t(`features.roof_cover_options.${ roof_cover }`) }}</option>
                </select>
            </div>
        </div>
    </div>
</template>

<script>
	import { ref, inject, computed } from 'vue'

    export default {
	    props: [],
        components: {

        },
        watch: {
            'listing.features.construction.property_type'(value) {
                this.listing.features.construction.property_orientation = null
                this.listing.features.construction.type = null

                this.$emit('resetSteps')

                if(['land', 'substructure'].includes(value)) {
                    this.steps = this.steps.filter(s => !['layout_features', 'energy_features', 'exterior_features', 'storage_features', 'garage_features', 'parking_features'].includes(s))

                    return
                }

                if(['parking', 'storage_space', 'berth', 'pitch', 'storage'].includes(value)) {
                    this.steps = this.steps.filter(s => !['layout_features', 'exterior_features', 'storage_features', 'garage_features'].includes(s))
                }

                if(['storage_space', 'storage'].includes(value)) {
                    this.steps = this.steps.filter(s => !['parking_features'].includes(s))
                }
            }
        },
	    setup(props) {
            const listing = inject('listing')
            const steps = inject('steps')
            const resetSteps = inject('resetSteps')
            const validationErrors = inject('validationErrors')

            const property_types = ref([
                {
                    name: 'house',
                    orientations: [
                        'terraced_house',
                        'detached_house',
                        'double_house',
                        'corner_house',
                        'link_detached_house',
                        'semi_detached_house',
                        'end_of_terrace',
                        'link_double_house',
                        'staggered_house'
                    ],
                    types: [
                        'single_family_property',
                        'villa',
                        'townhouse',
                        'bungalow',
                        'former_farm',
                        'country_house',
                        'canalside_property',
                        'houseboat',
                        'country_estate',
                        'mobile_home',
                        'caravan'
                    ]
                },
                {
                    name: 'apartment',
                    orientations: [
                        'duplex_apartment',
                        'corridor_flat',
                        'service_flat',
                        'piano_nobile',
                        'basement',
                        'sheltered_apartment'
                    ],
                    types: [
                        'flat_with_porch',
                        'upper_floor_apartment',
                        'apartment_with_external_access',
                        'ground_floor_apartment',
                        'maisonnette',
                        'penthouse',
                        'house_with_porch',
                        'mezzanine',
                        'duplex_apartment',
                        'double_ground_floor_apartment',
                        'student_room'
                    ]
                },
                {
                    name: 'parking',
                    types: [
                        'garage',
                        'parking_space',
                        'underground_car_park',
                        'integrated_garage'
                    ]
                },
                {
                    name: 'land'
                },
                {
                    name: 'storage_space'
                },
                {
                    name: 'storage'
                },
                {
                    name: 'berth'
                },
                {
                    name: 'substructure'
                },
                {
                    name: 'pitch'
                }
            ])

            const roof_types = ref([
                'gable',
                'hip',
                'dutch',
                'jerkinhead',
                'pyramid',
                'mansard',
                'bonnet',
                'gambrel',
                'skillion',
                'curved',
                'flat',
                'saltbox',
                'butterfly',
                'sawtooth',
                'dormer',
                'combination'
            ])

            const roof_covers = ref([
                'shingles',
                'slate',
                'clay_tiles',
                'green_roofing',
                'rolled_roofing',
                'solar_tiles',
                'wood_roofing',
                'epdm',
                'wood_shingles',
                'concrete_roofing',
                'membrane_roofing',
                'composite_material',
                'pvc',
                'tpo',
                'butterfly_roof',
                'asphalt',
                'corrugated_roofing',
                'curved_roof',
                'metal',
                'aluminum_shingles',
                'bitumen_roofing',
                'rubber_roofing',
                'others'
            ])

            const getOrientations = computed(() => {
                if(listing.value.features.construction.property_type) {
                    const property_type = property_types.value.find(pt => pt.name === listing.value.features.construction.property_type)

                    if(property_type) {
                        return property_type.orientations ?? null
                    }
                }

                return null
            })

            const getTypes = computed(() => {
                if(listing.value.features.construction.property_type) {
                    const property_type = property_types.value.find(pt => pt.name === listing.value.features.construction.property_type)

                    if(property_type) {
                        return property_type.types ?? null
                    }
                }

                return null
            })

            const validate = () => {
                if(!listing.value.features.construction.property_type) {
                    validationErrors.value.push('property_type')
                }

                if(getOrientations.value && !listing.value.features.construction.property_orientation) {
                    validationErrors.value.push('property_orientation')
                }

                if(getTypes.value && !listing.value.features.construction.type) {
                    validationErrors.value.push('type')
                }

                if(listing.value.features.construction.property_type === 'parking') {
                    if(!listing.value.features.construction.parking_capacity) {
                        validationErrors.value.push('parking_capacity')
                    }
                }

                if(['house', 'apartment', 'parking', 'storage_space', 'storage', 'berth', 'substructure'].includes(listing.value.features.construction.property_type)) {
                    if(!listing.value.features.construction.building_type) {
                        validationErrors.value.push('building_type')
                    }

                    if(!listing.value.features.construction.year_of_construction) {
                        validationErrors.value.push('year_of_construction')
                    }

                    if(['house', 'apartment'].includes(listing.value.features.construction.property_type)) {

                        if(!listing.value.features.construction.type_of_roof) {
                            validationErrors.value.push('type_of_roof')
                        }

                        if(!listing.value.features.construction.roof_cover) {
                            validationErrors.value.push('roof_cover')
                        }
                    }
                }
            }

            return {
                listing,
                property_types,
                getOrientations,
                getTypes,
                roof_types,
                roof_covers,
                steps,
                resetSteps,
                validationErrors,
                validate
            }
        }
    }
</script>
