<template>
    <div>
        <input type="text" class="border w-full rounded-full p-2 px-5 focus:outline-none" v-model="message" @keyup.enter="sendMessage()" :disabled="loading" />

        <loading v-if="loading" />

        <button v-else class="flex-none w-10 h-10 rounded-full bg-primary text-white flex items-center justify-center hover:shadow-lg" @click="sendMessage()">
            <i class="fi fi-rr-paper-plane flex items-center"></i>
        </button>

    </div>
</template>


<script>
    import { ref } from 'vue'
    import { useApi } from '../lib/api'

    export default {
	    props: ['ticket'],
        watch: {
            '$props.ticket'(value) {
                this.setEndpoint(`ticket/${ value.id }/send-message`)
            }
        },
	    setup(props, { emit }) {
            const message = ref(null)

            const { post, data, loading, setEndpoint } = useApi(`ticket/${ (props.ticket)? props.ticket.id : 'null' }/send-message`)

            const sendMessage = () => {
                if(message.value) {
                    post({ message: message.value }).then(() => {
                        if(data.value.status) {
                            emit('messageSend', data.value.message)

                            message.value = null
                        }
                    })
                }
            }

            return {
                message,
                setEndpoint,
                sendMessage,
                loading
            }
		}
	}
</script>
