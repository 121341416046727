<template>
    <div class="space-y-5 lg:text-black text-white">
        <div>
            <label class="text-sm font-semibold">Email:</label>
            <div class="">{{ user.email }}</div>
        </div>

        <CreateAccountPassword ref="passwordInput" :firstName="user.first_name" :lastName="user.last_name" :email="user.email" @keyup.enter="claimAccount()" v-model="password" />

        <button v-if="!loading" class="bg-primary w-full py-2 rounded-lg font-bold text-white" @click="claimAccount()">Claim Account</button>

        <loading v-else />
    </div>
</template>


<script>
import CreateAccountPassword from './CreateAccountPassword.vue'
import { useFormValidator } from '../lib/formValidator';
import { ref } from 'vue'
import { useApi } from '../lib/api';
import { useAuth } from '../lib/auth';

export default {
    props: ['user'],
    components: {
        CreateAccountPassword
    },
    setup(props) {
        const { validationErrors, resetErrors } = useFormValidator()
        const { post, data, loading } = useApi('user/claim-account')
        const { redirectTo } = useAuth()

        const password = ref(null)
        const passwordInput = ref(null)

        const claimAccount = () => {
            resetErrors()

            if(!passwordInput.value.passwordIsOkay) {
                validationErrors.value.push('password')
            }

            if(validationErrors.value.length === 0) {
                post({
                    email: props.user.email,
                    password: password.value
                }).then(() => {
                    if(data.value.status) {
                        window.location.replace(redirectTo.value)
                    }
                })
            }
        }

        return {
            password,
            passwordInput,
            claimAccount,
            loading
        }
    }
}

</script>
