<template>
    <div class="sticky top-0 bg-white z-10">
        <div class="2xl:w-5/6 xl:w-full mx-auto relative md:h-auto lg:z-20 z-auto">
            <div class="flex items-center md:px-8 px-3 justify-between w-full md:py-3 py-3 lg:h-auto" v-bind:class="{'h-20': scrollPosition !== 0 && !expandSearchBar}">
                <div class="flex md:space-x-12 items-center w-full">
                    <a href="/" class="md:block hidden">
                        <img src="/images/logo.svg" class="lg:w-40 w-32" />
                    </a>

                    <Transition enter-from-class="opacity-0 -translate-y-full"
                        enter-to-class="opacity-100 translate-y-0"
                        enter-active-class="transform transition ease-out duration-200"
                        leave-active-class="transform transition ease-in duration-150"
                        leave-from-class="opacity-100 translate-y-0"
                        leave-to-class="opacity-0 -translate-y-full">

                        <ul v-if="scrollPosition === 0 || expandSearchBar" class="flex md:space-x-3 space-x-2 transition duration-150 w-full md:justify-normal justify-between lg:text-base text-sm">
                            <li>
                                <a href="#" class="text-gray-500 md:px-5 px-2 py-3 hover:rounded-full hover:bg-gray-100 hover:text-gray-900">
                                    {{ $t('menu.buying') }}
                                </a>
                            </li>
                            <li>
                                <a href="#" class="text-gray-500 md:px-5 px-2 py-3 hover:rounded-full hover:bg-gray-100 hover:text-gray-900">
                                    {{ $t('menu.renting') }}
                                </a>
                            </li>
                            <li>
                                <a href="#" class="text-gray-500 md:px-5 px-2 py-3 hover:rounded-full hover:bg-gray-100 hover:text-gray-900">
                                    {{ $t('menu.property_management') }}
                                </a>
                            </li>
                        </ul>
                    </Transition>
                </div>

                <ul class="md:flex hidden space-x-3 items-center h-12">
                    <li>
                        <a v-if="user" href="/listing/new" class="lg:px-8 px-5 py-3 hover:text-gray-900 font-semibold border border-dark rounded-lg text-black hover:shadow-md duration-150 whitespace-nowrap">
                            {{ $t('menu.post_property') }}
                        </a>
                        <button v-else @click="showAuthModal('/listing/new')" class="lg:px-8 px-5 py-3 hover:text-gray-900 font-semibold border border-dark rounded-lg text-black hover:shadow-md duration-150 whitespace-nowrap">
                            {{ $t('menu.post_property') }}
                        </button>
                    </li>
                    <li>
                        <UserMenu></UserMenu>
                    </li>
                </ul>
            </div>
        </div>

        <div class="border-t">
            <div class="2xl:w-5/6 xl:w-full mx-auto relative" v-bind:class="{'z-10': scrollPosition === 0 || expandSearchBar, 'lg:z-30 z-auto': (scrollPosition !== 0 && !expandSearchBar)}">
                <SearchBar :scroll_position="scrollPosition" :expand_search_bar="expandSearchBar" @expand="changeExpandState" class="w-full xl:px-8 px-5 mx-auto"></SearchBar>
            </div>

            <CategoriesBar @selectCategory="selectCategory" v-model="selectedCategory"></CategoriesBar>
        </div>
    </div>
</template>

<script>
	import { ref } from 'vue'
    import SearchBar from './SearchBar.vue'
    import UserMenu from './UserMenu.vue'
    import CategoriesBar from './CategoriesBar.vue'

    import { useUser } from '../lib/user'
    import { useAuth } from '../lib/auth'
    import { useScrollPosition } from '../lib/scrollPosition'
    import { useHomeListings } from '../lib/homeListings'

    export default {
	    props: [],
        components: {
            SearchBar,
            UserMenu,
            CategoriesBar
        },
        watch: {
            scrollPosition(value, oldValue) {
                if(window.innerWidth > 640 && ((value - oldValue) < -15 || (value - oldValue) > 15)) {
                    this.expandSearchBar = false
                }
            }
        },
	    setup(props) {
            const { user } = useUser()
            const { showAuthModal } = useAuth()
            const { scrollPosition } = useScrollPosition()
            const { selectCategory, selectedCategory } = useHomeListings()

            const expandSearchBar = ref(false)

            const changeExpandState = (value) => {
                expandSearchBar.value = value
            }

			return {
                scrollPosition,
                user,
                showAuthModal,
                changeExpandState,
                expandSearchBar,
                selectCategory,
                selectedCategory
			}
		}
	}
</script>
