<template>
    <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 853.56 154.64">
    <g id="Layer_1-2" data-name="Layer 1">
        <g>
        <path class="cls-2" d="M176.17,112.04v-60.9c0-5.85,3.15-9,9-9h12.9c5.85,0,9,3.15,9,9v.75c4.35-7.05,11.25-11.4,21.9-11.4,24.45,0,27.45,21.45,27.45,34.35v37.2c0,5.85-3.15,9-9,9h-12.9c-5.85,0-9-3.15-9-9v-34.2c0-6.15-1.35-13.35-9.3-13.35s-9.15,7.2-9.15,13.35v34.2c0,5.85-3.15,9-9,9h-12.9c-5.85,0-9-3.15-9-9Z"/>
        <path class="cls-2" d="M263.92,81.9c0-21.6,15.6-41.4,44.1-41.4,24.9,0,43.5,15,43.5,40.2,0,4.95-3.75,7.05-9.3,7.05h-49.5c0,4.35,6.9,11.85,20.25,11.85,3.6,0,8.55-.6,13.95-3.15,5.1-2.4,9.15-2.1,12.45,2.4l2.4,3.15c3.45,4.5,3.15,9.45-1.8,12.75-8.85,5.7-19.5,7.95-29.7,7.95-29.1,0-46.35-18.15-46.35-40.8ZM323.61,73.5c-.45-7.65-7.8-11.4-15.45-11.4s-15,3.75-15.45,11.4h30.9Z"/>
        <path class="cls-2" d="M355.57,81.6c0-23.85,16.05-41.1,37.05-41.1,10.2,0,18.3,4.35,22.5,11.55v-.9c0-5.85,3.15-9,9-9h12.9c5.85,0,9,3.15,9,9v60.9c0,5.85-3.15,9-9,9h-12.9c-5.85,0-9-3.15-9-9v-.75c-4.2,7.05-12.3,11.4-22.5,11.4-21,0-37.05-17.25-37.05-41.1ZM415.56,81.6c0-9.9-5.55-17.55-14.55-17.55s-14.55,7.65-14.55,17.55,5.55,17.55,14.55,17.55,14.55-7.65,14.55-17.55Z"/>
        <path class="cls-2" d="M514.86,46.8v16.5c0,4.8-2.7,6.9-6.45,6.6-1.35-.15-2.4-.15-3.45-.15-12.6,0-15.6,8.7-15.6,16.95v25.35c0,5.85-3.15,9-9,9h-12.9c-5.85,0-9-3.15-9-9v-60.9c0-5.85,3.15-9,9-9h11.7c5.85,0,9,3.15,9,9v7.65c2.55-16.05,15.6-18.3,19.8-18.3h1.8c4.2,0,5.1,3.45,5.1,6.3Z"/>
        <path class="cls-2" d="M559.26,102.14l-6.9-15.75v25.65c0,5.85-3.15,9-9,9h-12.9c-5.85,0-9-3.15-9-9V21.6c0-5.85,3.15-9,9-9h12.9c5.85,0,9,3.15,9,9v52.05l15.45-25.5c2.55-4.2,5.7-6,10.65-6h14.25c7.5,0,9.9,4.8,5.55,10.95l-18.45,25.65,7.5,13.35c3.15,5.25,6.45,5.85,9.45,5.85.6,0,1.5-.15,2.4-.3,3.75-.9,6.3-.3,7.95,3l4.05,8.55c1.5,3.3.75,6.45-3,8.55-6.3,3.45-12.6,4.95-18.9,4.95-12.75,0-22.95-4.95-30-20.55Z"/>
        <path class="cls-2" d="M608.91,81.9c0-21.6,15.6-41.4,44.1-41.4,24.9,0,43.5,15,43.5,40.2,0,4.95-3.75,7.05-9.3,7.05h-49.5c0,4.35,6.9,11.85,20.25,11.85,3.6,0,8.55-.6,13.95-3.15,5.1-2.4,9.15-2.1,12.45,2.4l2.4,3.15c3.45,4.5,3.15,9.45-1.8,12.75-8.85,5.7-19.5,7.95-29.7,7.95-29.1,0-46.35-18.15-46.35-40.8ZM668.61,73.5c-.45-7.65-7.8-11.4-15.45-11.4s-15,3.75-15.45,11.4h30.9Z"/>
        <path class="cls-2" d="M712.11,144.14l13.65-30.75-28.05-60.75c-2.85-6.15,0-10.5,6.9-10.5h14.25c5.25,0,8.7,2.25,10.35,7.35l11.1,33,12.6-33.15c1.8-4.95,5.25-7.2,10.35-7.2h13.05c6.75,0,9.6,4.35,6.9,10.5l-42.45,95.25c-2.1,4.8-5.4,6.75-10.5,6.75h-11.25c-6.75,0-9.6-4.35-6.9-10.5Z"/>
        <path class="cls-2" d="M786.51,106.19c-2.55-5.55.6-9.6,6.3-10.95l6-1.35c5.1-1.2,7.05,1.35,11.1,5.1,2.4,2.1,5.55,2.85,9.3,2.85,4.5,0,7.35-1.35,7.35-3.9,0-1.5-.6-2.55-5.25-4.2l-9-3.3c-4.35-1.65-24.6-6.75-24.6-24,0-15.75,13.95-25.95,32.1-25.95,10.8,0,19.8,3.15,26.1,11.1,4.05,5.25,1.35,10.2-4.8,11.85l-5.4,1.5c-4.8,1.35-7.2-.15-10.65-2.7-1.65-1.2-3.6-1.65-5.55-1.65-3.3,0-4.95,1.8-4.95,3.75s1.65,2.85,5.25,4.05l8.7,3.6c17.1,6,24.75,14.4,25.05,25.2,0,17.7-16.05,25.5-34.35,25.5-15.9,0-27.45-5.25-32.7-16.5Z"/>
        </g>
        <path class="cls-1" d="M138.44,56.26l-6.76-6.18c-9.02-8.26-18.04-16.52-27.06-24.78-2.78-2.55-5.56-5.11-8.34-7.67-4.92-4.52-10-9.2-15.03-13.78C78.26,1.14,74.52-.15,70.12.01c-4.97.19-8.54,2.94-11.1,5.31-8.16,7.56-16.49,15.21-24.55,22.6-2.3,2.11-4.59,4.22-6.89,6.33-3.63,3.33-7.26,6.66-10.89,9.99-4.52,4.15-9.05,8.3-13.57,12.45-3.02,2.78-3.89,6.23-2.46,9.73,1.51,3.67,4.36,5.62,8.25,5.64,2.29.02,4.61,0,6.86,0,1.01,0,2.01,0,3.02,0h.54v15.44c.02,12.02.03,24.05.03,36.07,0,5.38,2.43,10.52,6.66,14.1,4.36,3.69,9.99,5.17,15.87,4.18,9.1-1.53,16.31-10.31,16.07-19.57-.25-9.47-.17-19.13-.09-28.47.02-1.94.03-3.88.04-5.82.03-4.11,1.95-7.93,5.28-10.46,3.29-2.51,7.42-3.35,11.31-2.3,5.78,1.55,9.67,6.57,9.7,12.49.02,5.97.01,12.04,0,17.91,0,5.66-.02,11.51,0,17.27.02,5.79,2.49,11.19,6.78,14.82,3.43,2.9,7.78,4.46,12.28,4.46,1.05,0,2.11-.08,3.17-.26,10.31-1.68,16.37-10.87,16.27-18.79-.11-8.87-.09-23.76-.08-33.61.01-9.39,0-9.73-.02-9.97-.32-2.63-2.77-4.82-5.36-4.82h-.04c-2.9.03-5.18,2.12-5.42,4.97-.04.53-.05,6.14-.05,19.79,0,8.15,0,17.39-.02,23.41-.01,2.92-1.5,5.59-3.98,7.13-2.39,1.49-5.18,1.66-7.66.46-3.26-1.58-4.84-4.14-4.83-7.82.05-11.13.03-22.44.01-33.38v-.65c0-1.31-.09-2.72-.26-4.29-.65-5.87-3.93-11.47-8.99-15.36-5.28-4.05-11.84-5.72-18.47-4.71-3.39.52-6.53,1.75-9.66,3.83.01-2.12.02-4.15-.03-6.17-.07-2.72-1.99-4.85-4.77-5.3-2.42-.39-5.09,1.2-5.82,3.47-.32.99-.36,2.01-.36,2.98,0,10.15,0,20.3-.01,30.45,0,8.92,0,17.84,0,26.75,0,1.53-.03,2.81-.18,4.06-.39,3.38-3.88,6.64-7.31,6.86-2.59.16-4.82-.57-6.46-2.11-1.69-1.59-2.61-3.97-2.61-6.72,0-8.37.02-16.73.02-25.1,0-8.07.02-16.14.02-24.21,0-1.14-.01-2.36-.11-3.59-.34-4.39-3.66-7.77-7.88-8.02-2.29-.14-4.61-.13-6.84-.13-.46,0-.93,0-1.39,0,11.1-10.27,47.84-44.06,52.67-48.45,3.01-2.73,5.13-2.82,7.85-.33,3.77,3.45,8.49,7.79,13.61,12.48,24.12,22.14,42.68,39.15,43.98,40.16,1.5,1.16,3.34,1.5,5.07.92,1.71-.57,3.03-1.99,3.52-3.8.83-3.06-1.28-5-2.41-6.03Z"/>
    </g>
    </svg>
</template>

<script>
	import { ref, inject } from 'vue'

    export default {
	    props: [],
        components: {

        },
	    setup(props) {

		}
	}
</script>
