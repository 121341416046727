<template>
    <div class="space-y-5">
        <div class="relative cursor-pointer">
            <div @drop="dragDropped($event, 1)" @dragover="dragOverHandler" @dragleave="dragLeaveHandler" @click="$refs.selectFileRef.click()">
                <input type="file" ref="selectFileRef" class="hidden" accept="image/*" @change="inputSelectFiles($event)" multiple>

                <div class="border rounded-lg border-dashed py-16 flex items-center justify-center" v-bind:class="{'border-gray-600': highlighted, 'border-gray-300': !highlighted }">
                    <div class="text-center space-y-2">
                        <i class="fas fa-photo-video text-3xl" v-bind:class="{'text-primary': highlighted, 'text-gray-300': !highlighted }"></i>
                        <h4 class="text-sm font-bold" v-bind:class="{'text-gray-600': highlighted, 'text-gray-300': !highlighted }">Drag and drop your photos</h4>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!loading" class="space-y-2">
            <draggable :list="modelValue"
                       item-key="order"
                       ghost-class="opacity-0.50"
                       class="grid grid-cols-4 gap-2"
                       handle=".handle"
                       @end="updateOrdering"
            >
                <template #item="{ element }">
                    <div class="relative">
                        <!-- <button type="button" class="w-6 h-6 rounded-full shadow border absolute bg-white -top-2 -left-2 handle">
                            <i class="fas fa-arrows-alt text-xs text-gray-500"></i>
                        </button> -->
                        <button type="button" @click="remove(element)" class="w-6 h-6 rounded-full shadow border absolute bg-red-500 -top-2 -left-1">
                            <i class="fi fi-rr-trash text-xs text-white"></i>
                        </button>
                        <img :src="element.image_url" class="object-cover w-full h-20 rounded">
                    </div>
                </template>
            </draggable>

            <ul class="grid grid-cols-4 gap-2">
                <li v-for="file in uploadQueue.filter((uq) => uq.uploaded === false)">
                    <div class="rounded overflow-hidden relative w-full h-20 ">
                        <img :src="createObjectURL(file.file)" class="object-cover rounded">
                        <div class="absolute bottom-0 w-full bg-gray-200 h-3" v-if="file.uploadInProgress">
                            <div class="bg-primary h-full text-[9px] text-white text-center" :style="`width: ${ file.uploadProgress }%`">Uploading...</div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>

        <loading v-else />

    </div>
</template>

<script>
import { ref } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import { useUpload } from '../../lib/upload'
import { useApi } from "../../lib/api";

import draggable from 'vuedraggable'

export default {
    name: "MultiUpload",
    props: ['name', 'modelValue', 'filable_id', 'filable_type', 'type'],
    components: {
        draggable
    },
    setup(props, { emit }) {
        const highlighted = ref(false)
        const uploadQueue = ref([])
        const { upload, setFiles } = useUpload(props.filable_id, props.filable_type, props.type)
        const { post } = useApi('update-rank')
        const { del, setEndpoint, data, loading } = useApi('remove/File')

        setFiles(props.modelValue)

        const inputSelectFiles = (e) => {
            e.target.files.forEach((file) => {
                uploadQueue.value.push({
                    uuid: uuidv4(),
                    file: file,
                    fileModel: null,
                    uploadProgress: 0,
                    uploaded: false,
                    uploadInProgress: false
                })
            })

            uploadFiles()
        }

        const dragDropped = (e) => {
            e.preventDefault()

            highlighted.value = false

            e.dataTransfer.items.forEach((file) => {
                uploadQueue.value.push({
                    uuid: uuidv4(),
                    file: file.getAsFile(),
                    fileModel: null,
                    uploadProgress: 0,
                    uploaded: false,
                    uploadInProgress: false
                })
            })

            uploadFiles()
        }

        const dragOverHandler = (e) => {
            e.preventDefault()

            if(!highlighted.value) {
                highlighted.value = true
            }
        }

        const dragLeaveHandler = () => {
            if(highlighted.value) {
                highlighted.value = false
            }
        }

        const uploadFiles = () => {
            const files = uploadQueue.value.map((file) => {
                if(!file.uploadInProgress && !file.fileModel) {
                    file.uploadInProgress = true

                    return file
                }

                return null
            }).filter(umf => umf)

            upload(files)
        }

        const createObjectURL = (file) => {
            return URL.createObjectURL(file)
        }

        const updateOrdering = () => {
            props.modelValue.map((file, x) => {
                file.ranking = (x * 10) + 10
            })

            post({
                model: 'File',
                rankings: props.modelValue
            })
        }

        const remove = (file) => {
            setEndpoint(`remove/File/${ file.id }`)

            del().then(function(){
                if(data.value.status) {
                    let files = props.modelValue

                    emit('update:modelValue', files.filter((f => f.id !== file.id)))
                }
            })
        }

        return {
            highlighted,
            uploadQueue,
            inputSelectFiles,
            dragDropped,
            dragOverHandler,
            dragLeaveHandler,
            createObjectURL,
            updateOrdering,
            remove,
            loading
        }
    }
}
</script>
