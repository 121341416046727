<template>
    <div class="space-y-8 py-5">
        <h1 class="text-5xl font-bold text-center">Parking Features</h1>

        <div class="space-y-2">
            <h2 class="font-bold">Type of parking facilities</h2>

            <ul class="border rounded-lg divide-y">
                <FacilityBooleanRow v-for="parkingFacility in parkingFacilities" :facility="parkingFacility" v-model="listing.features.parking.facilities" />
            </ul>
        </div>
    </div>
</template>

<script>
	import { ref, inject } from 'vue'
    import FacilityBooleanRow from './FacilityBooleanRow.vue'

    export default {
	    props: [],
        components: {
            FacilityBooleanRow
        },
	    setup(props) {
            const listing = inject('listing')

            if(listing.value.features.parking.facilities === undefined) {
                listing.value.features.parking.facilities = []
            }

            const parkingFacilities = ref([
                { name: "public_parking"},
                { name: "parking_on_private_property"},
                { name: "underground_parking"},
                { name: "parking_garage"},
                { name: "parking_on_gated_property"},
                { name: "paid_parking"},
                { name: "residents_parking_permits"}
            ])

            const validate = () => {
            }

            return {
                listing,
                parkingFacilities,
                validate
            }
        }
    }
</script>
