<template>
    <div class="py-5 space-y-3">
        <h2 class="font-semibold text-gray-600 text-sm">{{ name }}</h2>

        <div class="space-y-3">
            <ul class="space-y-3 text-sm overflow-hidden" v-bind:class="{'h-[150px]': !showingMore && options.length > 5, 'h-auto': showingMore && options.length > 5}">
                <li v-for="option in options">
                    <CheckboxInput :value="option.value" :displayValue="option.displayValue" :count="option.count" v-model="selected"  />
                </li>
            </ul>

            <button v-if="options.length > 5" class="bg-primary text-white rounded p-1 text-xs hover:shadow-lg" @click="showingMore = !showingMore">
                <div v-if="!showingMore" class="flex items-center space-x-1">
                    <span>{{ $t('search_filters.filter_options.show_more') }}</span>
                    <i class="fi fi-rr-angle-small-right flex items-center"></i>
                </div>
                <div v-else class="flex items-center space-x-1">
                    <span>{{ $t('search_filters.filter_options.show_less') }}</span>
                    <i class="fi fi-rr-angle-small-up flex items-center"></i>
                </div>
            </button>
        </div>
    </div>
</template>


<script>
	import { ref, inject } from 'vue'
    import CheckboxInput from './CheckboxInput.vue'

    export default {
	    props: ['name', 'options', 'modelValue'],
        components: {
            CheckboxInput
        },
        watch: {
            '$props.modelValue'(value) {
                this.selected = value
            },
            selected(value) {
                this.$emit('update:modelValue', value)
            }
        },
	    setup(props) {
            const showingMore = ref(false)
            const selected = ref(props.modelValue)

            return {
                showingMore,
                selected
            }
		}
	}
</script>
