<template>
    <div>
        <dialog class="block relative w-full z-50 p-0 z-10" v-if="showModal">
            <div class="md:p-7 flex justify-center items-center overflow-x-hidden overflow-y-auto fixed left-0 top-0 w-full h-full bg-gray-900 bg-opacity-50 z-50">
                <div class="flex 2xl:w-1/3 xl:w-1/2 md:w-2/3 w-full relative items-center md:px-0 px-5">
                    <div class="bg-white w-full rounded-xl shadow-xl relative z-10">
                        <div class="flex flex-col items-start w-full">
                            <div class="md:px-5 md:py-5 p-3 flex items-center w-full border-b">
                                <div class="text-center text-lg font-bold w-full">
                                    <span>Continue working on listing</span>
                                </div>
                            </div>

                            <div class="relative w-full">
                                <div class="w-full space-y-5 p-5">
                                    <h1 class="font-bold text-xl text-gray-600">Amerikaweg, Santa Maria, Willemstad</h1>

                                    <img src="https://livinggoed.com/wp-content/uploads/objects/55383/55383-mexicoweg-willemstad-4183584071.jpg" class="w-full h-80 rounded-xl object-cover" />
                                    <ul class="border rounded-xl grid grid-cols-3">
                                        <li class="p-3">
                                            <h3 class="text-sm text-gray-500">Type</h3>
                                            <div class="font-bold">Selling</div>
                                        </li>
                                        <li class="p-3">
                                            <h3 class="text-sm text-gray-500">Price</h3>
                                            <div class="font-bold">225.000 ANG</div>
                                        </li>
                                        <li class="p-3">
                                            <h3 class="text-sm text-gray-500">Living Space</h3>
                                            <div class="font-bold">168 m²</div>
                                        </li>
                                        <li class="p-3">
                                            <h3 class="text-sm text-gray-500">Plot Area</h3>
                                            <div class="font-bold">168 m²</div>
                                        </li>
                                        <li class="p-3">
                                            <h3 class="text-sm text-gray-500">Bathroom</h3>
                                            <div class="font-bold">2</div>
                                        </li>
                                    </ul>

                                    <div class="flex space-x-3">
                                        <button class="w-1/2 bg-red-400 p-3 rounded-lg text-white font-bold" @click="removeListing()">
                                            Delete this listing
                                        </button>

                                        <a :href="`/listing/new/${ listing.id }`" class="w-1/2 bg-green-500 p-3 rounded-lg text-white font-bold text-center">
                                            <div v-if="countdownSeconds > 0">
                                                <div>Resume</div>
                                                <div class="text-[10px]">Continue in {{ countdownSeconds }} seconds.</div>
                                            </div>
                                            <loading color="text-white" v-else />
                                        </a>
                                    </div>
                                </div>

                                <div v-if="showConfirmationDialog" class="w-full h-full bg-black/75 absolute top-0 rounded-b-xl flex flex-col items-center justify-center space-y-5">
                                    <div class="text-center space-y-2">
                                        <h3 class="text-white font-bold text-2xl">Are you sure?</h3>
                                        <div class="text-white">You won't get it back when the listing is removed.</div>
                                    </div>

                                    <div v-if="!loading" class="space-x-3 w-11/12 flex justify-center">
                                        <button class="bg-red-500 p-3 rounded-lg text-white font-bold md:w-52 w-1/2" @click="dismissRemoveListing()">No</button>
                                        <button class="bg-green-500 p-3 rounded-lg text-white font-bold md:w-52 w-1/2" @click="confirmRemoveListing()">Yes</button>
                                    </div>

                                    <loading v-else color="text-white" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </dialog>
    </div>
</template>

<script>
	import { ref } from 'vue'
    import { useApi } from '../../lib/api'

	export default {
	    props: [],
	    setup(props) {
            const showModal = ref(false)
            const { get, data } = useApi('account/listings?status=is_new')
            const { del, data: removeDataListing, loading, setEndpoint } = useApi('')
            const listing = ref(null)
            const countdownSeconds = ref(10)
            const runningInterval = ref(null)
            const showConfirmationDialog = ref(false)

            get().then(() => {
                if(data.value.status) {
                    if(data.value.listings.total > 0) {
                        listing.value = data.value.listings.data[0]

                        setEndpoint(`listing/${ listing.value.id }`)

                        showModal.value = true
                        startCountdown()
                    }
                }
            })

            const removeListing = () => {
                clearInterval(runningInterval.value)

                showConfirmationDialog.value = true
            }

            const startCountdown = () => {
                runningInterval.value = setInterval(function() {
                    countdownSeconds.value -= 1

                    if(countdownSeconds.value < 0) {
                        window.location.replace(`/listing/new/${ listing.value.id }`);
                    }
                }, 1000)
            }

            const dismissRemoveListing = () => {
                countdownSeconds.value = 10

                startCountdown()

                showConfirmationDialog.value = false
            }

            const confirmRemoveListing = () => {
                del().then(() => {
                    if(removeDataListing.value.status) {
                        showModal.value = false
                    }
                })
            }

            return {
                showModal,
                countdownSeconds,
                listing,
                removeListing,
                showConfirmationDialog,
                dismissRemoveListing,
                loading,
                confirmRemoveListing
            }
		}
	}
</script>
