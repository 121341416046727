<template>
    <div class="space-y-8 py-5">
        <h1 class="text-5xl font-bold text-center">Description</h1>

        <div v-if="info"  class="space-y-8">
            <div class="space-y-2 p-5">
                <h3 class="font-bold">Listing Title</h3>

                <div class="border rounded-xl overflow-hidden flex items-center">
                    <input type="text" v-model="info.title" class="text-xl focus:outline-none p-5  w-full" placeholder="Give it a catchy title" />

                    <div class="px-3">
                        <button class="w-10 p-2 rounded-lg hover:bg-gray-100" @click="$refs.languageSelectModal.openModal()">
                            <img :src="`/images/flags/${ selectedLangauge.icon }`" class="w-full h-full" />
                        </button>
                    </div>
                </div>
            </div>

            <div class="p-5" v-bind:class="{'rounded-lg border-red-500 border-2': validationErrors.includes('notEnoughWords')}">
                <div class="space-y-2">
                    <h3 class="font-bold">Description</h3>
                    <ckeditor :editor="ClassicEditor" v-model="info.body" :config="editorConfig" @ready="onCkeditorReady"></ckeditor>
                </div>
            </div>
        </div>
    </div>

    <LanguageSelectModal ref="languageSelectModal" v-model="selectedLangauge" />
</template>

<script>
	import { ref, inject } from 'vue'
    import { ClassicEditor } from '@ckeditor/ckeditor5-editor-classic';
    import { Heading } from '@ckeditor/ckeditor5-heading';
    import { Essentials } from '@ckeditor/ckeditor5-essentials';
    import { Bold, Italic } from '@ckeditor/ckeditor5-basic-styles';
    import { Paragraph } from '@ckeditor/ckeditor5-paragraph';
    import { SourceEditing } from '@ckeditor/ckeditor5-source-editing';
    import { WordCount } from '@ckeditor/ckeditor5-word-count';
    import LanguageSelectModal from '../LanguageSelectModal.vue';

    export default {
	    props: [],
        components: {
            LanguageSelectModal
        },
        watch: {
            selectedLangauge(value) {
                let listingInfo = this.listing.infos.find(i => i.language_id === value.id)

                if(!listingInfo) {
                    listingInfo = {
                        language: value,
                        language_id: value.id,
                        title: null,
                        body: ''
                    }

                    this.infos.push(listingInfo)
                }

                this.info = listingInfo
            }
        },
	    setup(props) {
            const editor = ref(null)
            const listing = inject('listing')
            const validationErrors = inject('validationErrors')
            const selectedLangauge = ref(null)

            const infos = ref(listing.value.infos ?? [{
                language: {
                    id: 1,
                    name: 'English',
                    iso: 'en',
                    locale: 'en',
                    icon: 'United-Kingdom'
                },
                language_id: 1,
                title: null,
                body: ''
            }])

            const info = ref(null)

            const editorConfig = ref({
                plugins: [
                    Essentials,
                    Heading,
                    Bold,
                    Italic,
                    Paragraph,
                    SourceEditing,
                    WordCount
                ],
                toolbar: {
                    items: [
                        'undo',
                        'redo',
                        '|',
                        'heading',
                        'bold',
                        'italic',

                        'sourceEditing'
                    ]
                }
            })

            const onCkeditorReady = (e) => {
                editor.value = e
            }

            const validate = () => {
                listing.value.infos = infos

                if(editor.value.plugins.get( 'WordCount' ).words < 100) {
                    validationErrors.value.push('notEnoughWords')
                }
            }

            return {
                listing,
                infos,
                info,
                ClassicEditor,
                editorConfig,
                validate,
                onCkeditorReady,
                validationErrors,
                selectedLangauge
            }
        },
        mounted() {
            if(this.infos.length > 0) {
                this.selectedLangauge = this.infos[0].language
            }
        }
    }
</script>

<style>
.ck-editor__editable_inline {
    min-height: 400px;
}
</style>
