import { ref, watch } from 'vue'
import { useApi } from './api'

import { Promise } from 'bluebird'
import { TaskQueue } from 'cwait'
// import { UploadFile } from '@/models/UploadFile'

const uploadPhotoApi = useApi('/upload/image')
const uploadVideoApi = useApi('/upload/video')

const simultaneousUpload = 5
const isUploading = ref(false)
const files = ref([] as any)

const queue = new TaskQueue(Promise, simultaneousUpload)

var maxRunning = 0
var running = 0

export function useUpload(filable_id: number, filable_type: string, type: string|null) {

    const uploadPhoto = (uploadFile: any, blob: Blob) => {
        isUploading.value = true

        return new Promise((resolve) => {
            const formData = new FormData()
            formData.append('file', blob)
            formData.append('filable_id', String(filable_id))
            formData.append('filable_type', filable_type)
            formData.append('type', type)

            uploadPhotoApi
                .postData(formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    onUploadProgress: (progressEvent: any) => {
                        uploadFile.uploadProgress = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total
                        )
                    },
                })
                .then(() => {
                    if (uploadPhotoApi.data.value.status) {
                        uploadFile.fileModel = uploadPhotoApi.data.value.file
                        uploadFile.uploaded = true

                        files.value.push(uploadPhotoApi.data.value.file)

                        isUploading.value = running - 1 === 0 ? false : true

                        resolve()
                    }
                })
                .catch((error) => {
                    resolve()
                })
        })
    }

    const uploadVideo = (uploadFile: any, blob: Blob) => {
        isUploading.value = true

        return new Promise((resolve) => {
            uploadVideoApi
                .postData(blob, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    onUploadProgress: (progressEvent: any) => {
                        uploadFile.uploadProgress = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total
                        )
                    },
                })
                .then(() => {
                    if (uploadVideoApi.data.value.status) {
                        uploadFile.fileModel = uploadVideoApi.data.value.file

                        files.value.push(uploadVideoApi.data.value.file)

                        isUploading.value = running - 1 === 0 ? false : true

                        resolve()
                    }
                })
                .catch((error) => {
                    resolve()
                })
        })
    }

    const setFiles = (modelFiles: Array<any>) => {
        files.value = modelFiles
    }

    const getBlob = (file: File) => {
        return new Promise((resolve) => {
            const fileReader = new FileReader()
            fileReader.readAsArrayBuffer(file)

            fileReader.onload = () => {
                resolve(new Blob([fileReader.result as BlobPart]))
            }
        })
    }

    const upload = (uploadFiles: Array<any>) => {
        return new Promise((resolve) => {
            uploadFiles.forEach(
                queue.wrap(async (uploadFile: any) => {
                    if (++running > maxRunning) maxRunning = running

                    const blob = await getBlob(uploadFile.file)

                    if (uploadFile.file.type.includes('image')) {
                        await uploadPhoto(uploadFile, blob as Blob)
                    }

                    if (uploadFile.file.type.includes('video')) {
                        await uploadVideo(uploadFile, blob as Blob)
                    }

                    uploadFile.uploadInProgress = false

                    --running
                })
            )

            resolve()
        })
    }

    return {
        queue,
        upload,
        setFiles,
        isUploading,
        getBlob
    }
}
