<template>
	<div ref="mapsEl" :style="'height: ' + height + '; width: ' + width + ';'"></div>

    <div v-if="isMounted">
        <slot></slot>
    </div>

</template>

<script>
	import { ref, computed } from 'vue'
    import {GoogleMap} from '../../lib/googleMaps'

    export default {
    	props: ['center', 'coordinates', 'zoom', 'minzoom', 'location_data', 'height', 'width', 'zoomControl', 'scaleControl', 'gestureHandling', 'disableUI'],
        watch: {
            '$props.location_data'(value) {
                if(value) {
                    this.setBounds(this.map, value)

                }
            },
            async mapsEl(value) {
                if(value) {
                    await this.googleMap.createMap(value, {
                        disableDefaultUI: this.$props.disableUI ?? false,
                        gestureHandling: this.$props.gestureHandling ?? 'cooperative',
                        scaleControl: this.$props.scaleControl ?? true,
                        zoomControl: this.$props.zoomControl ?? true,
                        zoom: this.$props.zoom ?? 5,
                        center: {
                            lat: this.$props.center.lat ?? 0,
                            lng: this.$props.center.lng ?? 0
                        },
                        mapId: '2307533155cd22ad'
                    })

                    this.googleMap.map.addListener('click', (event) => {
                        this.$emit('mapClicked', event)
                    })

                    this.$emit('mapMounted')
                }
            }
        },
        setup() {
            const mapsEl = ref(null)
            const googleMap = new GoogleMap()
            const isMounted = ref(false)

            return {
                mapsEl,
                googleMap,
                isMounted
            }
        },
        mounted() {
            this.isMounted = true
        }
        // data() {
        //     return {
        //         loader: new Loader({
        //             apiKey: import.meta.env.VITE_GOOGLE_API_KEY,
        //             version: "weekly"
        //         }),
        //         maps: null,
        //         markers: []
        //     }
        // },
        // methods: {
        //     fitBounds(southwest, northeast) {
        //         const bounds = new google.maps.LatLngBounds()

        //         bounds.union(new google.maps.LatLngBounds(southwest, northeast));

        //         this.maps.fitBounds(bounds)
        //     },
        //     async createAdvanceMarker(coordinates, htmlElement) {
        //         const { AdvancedMarkerElement } = await google.maps.importLibrary("marker")
        //         console.log(coordinates)
        //         return new AdvancedMarkerElement({
        //             map: this.maps,
        //             position: new google.maps.LatLng(coordinates.latitude, coordinates.longitude),
        //             content: htmlElement,
        //         })
        //     }
        // },
        // async created() {
        //     const { Map } = await this.loader.importLibrary('maps')

        //     const maps = new Map(this.$refs.mapsEl, {
        //         disableDefaultUI: this.$props.disableUI ?? false,
        //         gestureHandling: this.$props.gestureHandling ?? 'cooperative',
        //         scaleControl: this.$props.scaleControl ?? true,
        //         zoomControl: this.$props.zoomControl ?? true,
        //         zoom: this.$props.zoom ?? 5,
        //         center: {
        //             lat: this.$props.center.lat ?? 0,
        //             lng: this.$props.center.lng ?? 0
        //         },
        //         mapId: '2307533155cd22ad'
        //     })

        //     const { AdvancedMarkerElement } = await google.maps.importLibrary("marker")

        //     return new AdvancedMarkerElement({
        //             map: maps,
        //             position: new google.maps.LatLng(12, 50),
        //             // content: htmlElement,
        //         })
        // }
        // async mounted() {
        //     const loader = await Loader({
        //         apiKey: import.meta.env.VITE_GOOGLE_API_KEY,
        //         version: "weekly"
        //     })

        //     const { Map } = await loader.importLibrary('maps')

        //     this.maps = new Map(this.$refs.mapsEl, {
        //         disableDefaultUI: this.$props.disableUI ?? false,
        //         gestureHandling: this.$props.gestureHandling ?? 'cooperative',
        //         scaleControl: this.$props.scaleControl ?? true,
        //         zoomControl: this.$props.zoomControl ?? true,
        //         zoom: this.$props.zoom ?? 5,
        //         center: {
        //             lat: this.$props.center.lat ?? 0,
        //             lng: this.$props.center.lng ?? 0
        //         },
        //         mapId: '2307533155cd22ad'
        //     })

        //     if(this.$props.location_data) {
        //         this.googleMap.setBounds(this.$props.location_data)
        //     }

        //     // if(this.$props.center) {
        //     //     this.googleMap.setCenter(new google.maps.LatLng(this.$props.center))
        //     // }
        // }
    }
</script>
