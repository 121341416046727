<template>
	<div ref="simpleBarEl" class="overflow-auto">
		<slot></slot>
	</div>
</template>

<script>
	import { ref, watch } from 'vue'
	import SimpleBar from 'simplebar'

	export default {
	    props: ['color'],
	    setup(props) {
			const simpleBarEl = ref(null)

			watch(() => simpleBarEl.value, (state, prevState) => {
	       		new SimpleBar(simpleBarEl.value);
            })

			return {
				simpleBarEl
			}
		}
	}
</script>
