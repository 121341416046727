<template>
    <div class="w-full space-y-3">
        <h2 class="text-xl font-semibold">Welcome to Nearkeys</h2>

        <div class="space-y-1">
            <div class="relative">
                <input id="email" type="text" class="block rounded-lg px-2.5 pb-2.5 pt-5 w-full text-gray-900 bg-white border border-gray-300 appearance-none focus:border-2 focus:border-gray-800 focus:outline-none focus:ring-0 peer" placeholder=" " v-model="auth.email" v-bind:class="{'border-red-500 focus:border-red-500 bg-red-100': hasValidationErrors('email') || !validator.isEmpty(auth.email ?? '') && !validator.isEmail(auth.email ?? '') }" @keyup.enter="checkKnownUser()" />
                <label for="email" class="absolute text-gray-500 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] start-2.5 peer-focus:text-primary  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto" v-bind:class="{'text-red-500 peer-focus:text-red-500': hasValidationErrors('email') || !validator.isEmpty(auth.email ?? '') && !validator.isEmail(auth.email ?? '') }">Email</label>
            </div>
            <div class="text-sm flex items-center space-x-1 text-red-500" v-if="hasValidationErrors('email')">
                <i class="fi fi-rr-exclamation flex items-center"></i>
                <span>Enter a valid email.</span>
            </div>
        </div>

        <button v-if="!loading" class="bg-primary w-full rounded-lg text-white font-semibold py-3 px-5 hover:shadow-xl" @click="checkKnownUser()">Continue</button>
        <button v-else class="bg-gray-300 w-full rounded-lg text-white font-semibold py-3 px-5">
            <loading color="text-white" />
        </button>

        <div class="py-5 justify-center flex relative justify-center">
            <hr class="w-full" />
            <div class="absolute top-2 bg-white px-5 inline-block text-sm text-gray-500">or</div>
        </div>

        <button class="border border-gray-800 w-full rounded-lg text-gray-800 font-semibold py-3 px-5 hover:bg-gray-100 flex items-center justify-center">
            <img src="/images/icons/facebook.svg" class="w-5 inline-block" />
            <span class="flex-1">Continue with Facebook</span>
        </button>
        <button class="border border-gray-800 w-full rounded-lg text-gray-800 font-semibold py-3 px-5 hover:bg-gray-100 flex items-center justify-center">
            <img src="/images/icons/google.svg" class="w-5 inline-block" />
            <span class="flex-1">Continue with Google</span>
        </button>
        <button class="border border-gray-800 w-full rounded-lg text-gray-800 font-semibold py-3 px-5 hover:bg-gray-100 flex items-center justify-center">
            <img src="/images/icons/apple.svg" class="w-5 inline-block" />
            <span class="flex-1">Continue with Apple</span>
        </button>
    </div>
</template>

<script>
	import { ref, inject, computed } from 'vue'
    import { useApi } from '../../lib/api';
    import validator from 'validator';
    import { useFormValidator } from '../../lib/formValidator';

    export default {
	    props: [],
        components: {
        },
        watch: {
            'auth.email'(value) {
                this.validationErrors = this.validationErrors.filter(e => e !== 'email')
            }
        },
	    setup(props) {
            const { hasValidationErrors } = useFormValidator()
            const auth = inject('auth')
            const step = inject('step')
            const validationErrors = inject('validationErrors')

            const loading = ref(false)

            const checkKnownUser = () => {
                validationErrors.value = []

                if(!validator.isEmail(auth.value.email ?? '')) {
                    validationErrors.value.push('email')

                    return
                }

                loading.value = true

                const { post, data } = useApi('user/is-known')

                post({
                    email: auth.value.email
                }).then(() => {
                    loading.value = false

                    if(data.value.is_known) {
                        step.value = 'passwordLogin'
                    } else {
                        step.value = 'signUp'
                    }
                }).catch(() => {
                    loading.value = false
                })
            }

            return {
                auth,
                loading,
                checkKnownUser,
                validator,
                validationErrors,
                hasValidationErrors
            }
        }
    }
</script>
