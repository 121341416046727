<template>
    <div class="transition-all ease-in-out duration-300" v-bind:class="{'shadow-lg': scrollPosition > 0, 'h-20': !minimalizeCategory, 'h-15': minimalizeCategory}">
        <div class="2xl:w-11/12 2xl:px-0 xl:w-full xl:px-8 mx-auto h-full relative">
            <div ref="categoryBarRef" class="md:overflow-hidden overflow-x-auto h-full">
                <div v-if="!isAtStart" class="h-full absolute top-0 left-0 items-center pr-8 bg-gradient-to-r from-white to-transparant z-10 md:flex hidden">
                    <button class="w-8 h-8 rounded-full text-gray-800 border border-gray-600 hover:shadow-lg text-sm bg-white" @click="slideToLeft()">
                        <i class="fi fi-rr-angle-small-left flex items-center justify-center text-xl"></i>
                    </button>
                </div>
                <ul class="flex h-full select-none touch-pan-x text-gray-500 font-semibold">
                    <li v-for="category in categories" ref="categoryIconRef" class="flex-none text-center p-2 min-w-28 cursor-pointer flex flex-col justify-center items-center relative group whitespace-nowrap transition ease-in-out duration-300 hover:text-gray-700" v-bind:class="{'text-gray-700': modelValue && modelValue.id === category.id}" @click="$emit('selectCategory', category)">
                        <i :class="`${ category.icon } block`" v-bind:class="{'text-xl': !minimalizeCategory, 'text-lg': minimalizeCategory}"></i>
                        <span v-bind:class="{'text-sm': !minimalizeCategory, 'text-xs': minimalizeCategory}">{{ $t(`categories.${ category.name }`)}}</span>

                        <div class="group-hover:block absolute bottom-0 h-0.5 w-full bg-gray-300" v-bind:class="{'bg-primary block': modelValue && modelValue.id === category.id, 'hidden': !modelValue || modelValue.id !== category.id }"></div>
                    </li>
                </ul>
                <div v-if="!isAtEnd" class="h-full absolute top-0 right-0 items-center pl-8 bg-gradient-to-l from-white to-transparant md:flex hidden">
                    <button class="w-8 h-8 rounded-full text-gray-800 border border-gray-600 hover:shadow-lg text-sm bg-white" @click="slideToRight()">
                        <i class="fi fi-rr-angle-small-right flex items-center justify-center text-xl"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { ref } from 'vue'
    import { useCategories } from '../lib/categories'
    import { useScrollPosition } from '../lib/scrollPosition'

    export default {
        props: ['modelValue'],
        watch: {
            categoryIconRef: {
                handler(value) {
                    let totalWidth = 0

                    value.map((block) => {
                        totalWidth += block.offsetWidth
                    })

                    this.widthPerBlock = totalWidth / value.length
                },
                deep: true
            },
            position(value) {
                let totalWidth = (this.categories.length * this.widthPerBlock) - this.categoryBarRef.offsetWidth

                if(value <= 0) {
                    this.isAtStart = true
                }

                if(value >= totalWidth) {
                    this.isAtEnd = true
                }
            },
            scrollPosition(value, oldValue) {
                if(value - oldValue > 20 && this.previousScrollPositionDifference != (value - oldValue)) {
                    this.minimalizeCategory = true
                    this.previousScrollPositionDifference = value - oldValue

                    return
                }

                if(value - oldValue < -20 && this.previousScrollPositionDifference != (value - oldValue) * -1) {
                    this.minimalizeCategory = false
                    this.previousScrollPositionDifference = (value - oldValue) * -1
                }
            }
        },
        setup() {
            const { scrollPosition } = useScrollPosition()
            const { categories } = useCategories()
            const minimalizeCategory = ref(false)
            const previousScrollPositionDifference = ref(0)
            const isAtStart = ref(true)
            const isAtEnd = ref(false)
            const categoryBarRef = ref(null)
            const categoryIconRef = ref(null)
            const position = ref(0)
            const widthPerBlock = ref(0)
            const step = ref(3)

            const slideToLeft = () => {
                isAtEnd.value = false

                position.value -= (step.value * widthPerBlock.value)

                if(position.value < 0) {
                    position.value = 0
                }

                categoryIconRef.value.forEach((category) => {
                    category.style.transform = `translate(-${ position.value }px)`
                })
            }

            const slideToRight = () => {
                isAtStart.value = false

                let totalWidth = (categories.value.length * widthPerBlock.value) - categoryBarRef.value.offsetWidth

                position.value += (step.value * widthPerBlock.value)

                if(position.value > totalWidth) {
                    position.value = totalWidth
                }

                categoryIconRef.value.forEach((category) => {
                    category.style.transform = `translate(-${ position.value }px)`
                })
            }

            return {
                categories,
                minimalizeCategory,
                scrollPosition,
                previousScrollPositionDifference,
                isAtStart,
                isAtEnd,
                slideToLeft,
                slideToRight,
                categoryBarRef,
                categoryIconRef,
                widthPerBlock,
                position,
                step
            }
        }
    }
</script>
