<template>
    <footer class="w-full bg-white border-t fixed transition ease-in-out duration-300 md:h-11 bottom-0" v-bind:class="{'translate-y-full': !showing}">
        <div class="md:w-11/12 w-full mx-auto py-1 md:flex-row flex-col items-center justify-between md:space-y-0 space-y-1 flex">
            <div>
                <ul class="flex space-x-5 text-sm items-center">
                    <li>© {{ dayjs().format('YYYY') }} NearKeys B.V.</li>
                    <li>
                        <a href="/terms">
                            {{ $t('footer.terms') }}
                        </a>
                    </li>
                    <li>
                        <a href="/sitemap">
                            {{ $t('footer.sitemap') }}
                        </a>
                    </li>
                    <li>
                        <a href="/privacy">
                            {{ $t('footer.privacy') }}
                        </a>
                    </li>
                </ul>
            </div>

            <div class="md:order-last order-first">
                <ul class="flex space-x-5 items-center">
                    <li>
                        <button v-if="preferences" class="font-semibold flex space-x-1 hover:bg-gray-200 rounded-md py-1 px-2 items-center space-x-2" @click="$root.$refs.localeSelectModal.openModal('language')">
                            <i class="fi fi-rr-globe flex items-center text-sm"></i>
                            <span v-if="preferences.locale.language">{{ preferences.locale.language.name }}</span>
                            <span v-if="preferences.locale.language" class="uppercase">({{ preferences.locale.language.locale }})</span>
                        </button>
                    </li>
                    <li>
                        <button v-if="preferences" class="font-semibold flex space-x-1 hover:bg-gray-200 rounded-md py-1 px-2" @click="$root.$refs.localeSelectModal.openModal('currency')">
                            <span>{{ preferences.currency.symbol_icon }} {{ preferences.currency.symbol }}</span>
                        </button>
                    </li>
                    <li>
                        <a href="#" class="font-semibold flex space-x-1 hover:bg-gray-200 rounded-md py-1 px-2">
                            <span>{{ $t('footer.contact_us') }}</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </footer>
</template>

<script>
	import { ref } from 'vue'
    import dayjs from 'dayjs'
    import { useScrollPosition } from '../lib/scrollPosition'
    import { useUser } from '../lib/user'
    import { useAuth } from '../lib/auth'
    import { usePreferences } from '../lib/preferences'

	export default {
        watch: {
            scrollPosition(value, oldValue) {
                if(value < oldValue) {
                    this.showing = true
                }

                if(value > oldValue) {
                    this.showing = false
                }
            }
        },
	    setup(props) {
            const showing = ref(true)
            const { scrollPosition } = useScrollPosition()
            const { showAuthModal } = useAuth()
            const { user } = useUser()
            const { preferences } = usePreferences()

            return {
                showing,
                dayjs,
                scrollPosition,
                user,
                showAuthModal,
                preferences
			}
		}
	}
</script>
