<template></template>

<script>
import { ref} from 'vue'

export default {
    props: ['name', 'location', 'coordinates', 'lat', 'lng', 'size', 'icon'],
    watch: {
        '$props.location'() {
            this.setMarker()
        },
        '$props.coordinates'() {
            this.setMarker()
        }
    },
    setup(props, { emit }) {
        const googleMaps = ref(null)
        let marker = null

        const setMarker = async() => {
            if(marker) {
                marker.setMap(null)
            }

            marker = await googleMaps.value.createMarker(props.name, getLatLng(), props.icon)

            marker.addListener("click", () => {
                emit('markerClicked')
            })

            marker.addListener("mouseover", () => {
                emit('markerMouseOver')
            })

            marker.addListener("mouseout", () => {
                emit('markerMouseOut')
            })
        }

        const getLatLng = () => {
            if(props.location) {
                return {
                    latitude: parseFloat(props.location.lat),
                    longitude: parseFloat(props.location.lng)
                }
            }

            if(props.coordinates) {
                return {
                    latitude: parseFloat(props.coordinates.lat),
                    longitude: parseFloat(props.coordinates.lng)
                }
            }
        }

        const removeMarker = () => {
            if(marker) {
                marker.setMap(null)
                marker = null
            }
        }

        return {
            setMarker,
            googleMaps,
            removeMarker
        }
    },
    mounted() {
        this.googleMaps = this.$parent.googleMaps
        this.setMarker()
    },
    beforeUnmount() {
        this.removeMarker()
    }
}
</script>
