<template>
    <div class="space-y-8 py-5">
        <h1 class="text-5xl font-bold text-center">Areas Features</h1>

        <div v-if="['house', 'apartment'].includes(listing.features.construction.property_type)" class="space-y-8">
            <div class="space-y-2 p-5"  v-bind:class="{'rounded-lg border-red-500 border-2': validationErrors.includes('living_area')}">
                <h2 class="font-bold">Living area</h2>

                <div class="border w-full rounded-lg flex items-center overflow-hidden">
                    <input type="number" class="p-5 w-full rounded-lg"  v-model="listing.features.areas.living_area" />
                    <div class="px-3 text-lg">m²</div>
                </div>
            </div>

            <div class="space-y-2 p-5">
                <h2 class="font-bold">Exterior space attached to the building</h2>

                <div class="border w-full rounded-lg flex items-center overflow-hidden">
                    <input type="number" class="p-5 w-full rounded-lg"  v-model="listing.features.areas.exterior_space_attached_to_the_building" />
                    <div class="px-3 text-lg">m²</div>
                </div>
            </div>

            <div class="space-y-2 p-5">
                <h2 class="font-bold">External storage space</h2>

                <div class="border w-full rounded-lg flex items-center overflow-hidden">
                    <input type="number" class="p-5 w-full rounded-lg"  v-model="listing.features.areas.external_storage_space" />
                    <div class="px-3 text-lg">m²</div>
                </div>
            </div>
        </div>

        <div class="space-y-2 p-5">
            <h2 class="font-bold">Dimensions</h2>

            <div class="flex space-x-3">
                <div class="border w-1/2 rounded-lg flex items-center overflow-hidden">
                    <input type="number" class="p-5 w-full rounded-lg"  v-model="listing.features.areas.dimensions.deep" />
                    <div class="px-3 whitespace-nowrap">Meters(s) Deep</div>
                </div>

                <div class="border w-1/2 rounded-lg flex items-center overflow-hidden">
                    <input type="number" class="p-5 w-full rounded-lg"  v-model="listing.features.areas.dimensions.width" />
                    <div class="px-3 whitespace-nowrap">Meters(s) Width</div>
                </div>
            </div>
        </div>

        <div class="space-y-2 p-5">
            <h2 class="font-bold">Plot size</h2>

            <div class="border w-full rounded-lg flex items-center overflow-hidden">
                <input type="number" class="p-5 w-full rounded-lg"  v-model="listing.features.areas.plot_size" />
                <div class="px-3 text-lg">m²</div>
            </div>
        </div>

        <div v-if="['house', 'apartment', 'storage_space', 'storage', 'berth', 'pitch'].includes(listing.features.construction.property_type)" class="space-y-2 p-5">
            <h2 class="font-bold">Volume in cubic meters</h2>

            <div class="border w-full rounded-lg flex items-center overflow-hidden">
                <input type="number" class="p-5 w-full rounded-lg"  v-model="listing.features.areas.volume_in_cubic_meters" />
                <div class="px-3 text-lg">m³</div>
            </div>
        </div>
    </div>
</template>

<script>
	import { ref, inject } from 'vue'

    export default {
	    props: [],
        components: {

        },
        watch: {

        },
	    setup(props) {
            const listing = inject('listing')
            const validationErrors = inject('validationErrors')

            const validate = () => {
                if(['house', 'apartment'].includes(listing.value.features.construction.property_type)) {
                    if(!listing.value.features.areas.living_area) {
                        validationErrors.value.push('living_area')
                    }
                }
            }

            return {
                listing,
                validate,
                validationErrors
            }
        }
    }
</script>
