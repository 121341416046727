import { ref } from 'vue'
import { useApi } from './api';

const preferences = ref({
    currency: {
        id: 1,
        name: 'us_dollar',
        decimal_separator: '.',
        thousand_separator: ',',
        icon: 'united-states.svg',
        symbol_icon: '$',
        symbol: 'USD'
    },
    locale: {
        language: {
            id: 1,
            name: 'English',
            iso: 'en',
            locale: 'en',
            icon: 'united-kingdom.svg'
        },
        user_preferred: false
    }
})

const { get, data } = useApi('preferences')

const getPreferences = (() => {
    get().then(() => {
        if(data.value.status) {
            preferences.value = data.value.preferences
        }
    }).catch(() => {

    })
})

getPreferences()

export const usePreferences = () => {
    return {
        preferences,
        getPreferences
    }
}
