<template>
    <Transition enter-from-class="opacity-0 translate-y-full"
                                    enter-to-class="opacity-100 translate-y-0"
                                    enter-active-class="transform transition ease-out duration-150 delay-200"
                                    leave-active-class="transform transition ease-in duration-150"
                                    leave-from-class="opacity-100 translate-y-0"
                                    leave-to-class="opacity-0 translate-y-full" appear>
        <div v-if="displaySlideshow" class="flex flex-col overflow-hidden space-y-5 lg:py-8 py-3 fixed h-screen w-screen z-30 top-0 bg-black text-gray-50">
            <div class="flex-none lg:px-16 px-3 flex items-center w-full justify-between h-14">
                <button class="font-semibold flex items-center space-x-3 px-3 py-2 rounded-md hover:bg-white hover:bg-opacity-35" @click="displaySlideshow = false">
                    <i class="fi fi-rr-cross-small flex items-center"></i>
                    <span>Close</span>
                </button>

                <div>
                    {{ selectedKey + 1 }} / {{ photos.length }}
                </div>

                <div>
                    <ul class="flex justify-between space-x-3">
                        <li>
                            <button class="flex items-center space-x-2 w-10 h-10 rounded-full justify-center hover:bg-white hover:bg-opacity-35">
                                <i class="fi fi-rr-share items-center flex"></i>
                            </button>
                        </li>

                        <li>
                            <toggle-listing-favourite v-if="user" ref="toggleListingFavourite" :listing_id="$props.listing_id">
                                <template v-slot:is-not-saved>
                                    <button class="flex items-center space-x-2 w-10 h-10 rounded-full justify-center hover:bg-white hover:bg-opacity-35">
                                        <i class="fi fi-rr-heart items-center flex"></i>
                                    </button>
                                </template>

                                <template v-slot:is-saved>
                                    <button class="flex items-center space-x-2 text-primary w-10 h-10 rounded-full justify-center hover:bg-white hover:bg-opacity-35">
                                        <i class="fi fi-rr-heart items-center flex"></i>
                                    </button>
                                </template>
                            </toggle-listing-favourite>
                            <button v-else @click="showAuthModal($props.url)" class="flex items-center space-x-2 w-10 h-10 rounded-full justify-center hover:bg-white hover:bg-opacity-35">
                                <i class="fi fi-rr-heart items-center flex"></i>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="flex-1 flex justify-between items-center w-full lg:px-16 h-0">
                <div class="lg:block hidden">
                    <button @click="previousPhoto" class="lg:w-12 lg:h-12 w-10 h-10 rounded-full border border-white hover:bg-white hover:bg-opacity-35 transition-all active:scale-90">
                        <i class="fi fi-rr-angle-small-left flex items-center justify-center text-xl"></i>
                    </button>
                </div>
                <div v-if="innerWidth > 640" class="relative h-full">
                    <TransitionGroup enter-to-class="opacity-100"
                                    enter-active-class="z-10 transform transition ease-out duration-150"
                                    leave-active-class="absolute z-20 transform transition ease-in duration-150"
                                    leave-from-class="opacity-100"
                                    leave-to-class="opacity-0">
                        <template v-for="(photo, key) in $props.photos">
                            <img :src="photo.image_url" v-if="key == selectedKey" :key="key" class="h-full object-cover" />
                        </template>
                    </TransitionGroup>
                </div>
                <div v-else class="relative h-full w-full">
                    <ImageCarousel :images="$props.photos.map((p) => {
                        return p.image_url
                    })" class="w-full h-full" @slideKeyUpdated="slideChanged" />
                </div>

                <div class="lg:block hidden">
                    <button @click="nextPhoto" class="lg:w-12 lg:h-12 w-10 h-10 rounded-full border border-white hover:bg-white hover:bg-opacity-35 transition-all active:scale-90">
                        <i class="fi fi-rr-angle-small-right flex items-center justify-center text-xl"></i>
                    </button>
                </div>
            </div>
        </div>
    </Transition>
</template>

<script>
	import { ref, computed } from 'vue'
    import ToggleListingFavourite from './ToggleListingFavourite.vue'
    import ImageCarousel from './ImageCarousel.vue'
    import { useUser } from '../lib/user'
    import { useAuth } from '../lib/auth'

	export default {
	    props: ['photos', 'url'],
        components: {
            ToggleListingFavourite,
            ImageCarousel
        },
	    setup(props) {
            const displaySlideshow = ref(false)
            const { showAuthModal } = useAuth()
            const { user } = useUser()
            const selectedKey = ref(0)
            const innerWidth = ref(0)

            const previousPhoto = () => {
                if(selectedKey.value == 0) {
                    selectedKey.value = (props.photos.length - 1)

                    return
                }

                selectedKey.value -= 1
            }

            const nextPhoto = () => {
                if(selectedKey.value < (props.photos.length - 1)) {
                    selectedKey.value += 1

                    return
                }

                selectedKey.value = 0
            }

            const showPhoto = (key) => {
                displaySlideshow.value = true
                selectedKey.value = key
            }

            const slideChanged = (key) => {
                selectedKey.value = key
            }

            return {
                displaySlideshow,
                user,
                selectedKey,
                previousPhoto,
                nextPhoto,
                showAuthModal,
                showPhoto,
                innerWidth,
                slideChanged
            }
		},
        mounted() {
            var vm = this

            addEventListener("keydown", (event) => {
                if(event.code === 'Escape' && this.displaySlideshow === true) {
                    this.displaySlideshow = false

                    this.$emit('slideShowClosed')
                }
            })

            window.onresize = function(event) {
                vm.innerWidth = (event.srcElement || event.currentTarget).innerWidth
            }

            this.innerWidth = window.innerWidth
        }
	}
</script>
