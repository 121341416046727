<template>
    <div class="space-y-8 py-5">
        <h1 class="text-5xl font-bold text-center">Energy Features</h1>

        <div class="space-y-2">
            <h2 class="font-bold">Energy Label</h2>

            <select class="border w-full rounded-lg p-5" v-model="listing.features.energy.energy_label">
                <option :value="null" disabled="disabled">Select Energy Label</option>
                <option v-for="energyLabel in energyLabels" :value="energyLabel">{{ energyLabel }}</option>
            </select>
        </div>

        <div class="space-y-2">
            <h2 class="font-bold">Insulations</h2>

            <ul class="border rounded-lg divide-y">
                <FacilityBooleanRow v-for="insulationsType in insulationsTypes" :facility="insulationsType" v-model="listing.features.energy.insulations" />
            </ul>
        </div>

        <div class="space-y-2">
            <h2 class="font-bold">Heating</h2>

            <ul class="border rounded-lg divide-y">
                <FacilityBooleanRow v-for="heatingType in heatingTypes" :facility="heatingType" v-model="listing.features.energy.heatings" />
            </ul>
        </div>

        <div class="space-y-2">
            <h2 class="font-bold">Hot Water</h2>

            <select class="border w-full rounded-lg p-5" v-model="listing.features.energy.hot_water">
                <option :value="null" disabled="disabled">Select hot water source</option>
                <option v-for="hotWaterType in hotWaterTypes" :value="hotWaterType">{{ hotWaterType }}</option>
            </select>
        </div>
    </div>
</template>

<script>
	import { ref, inject } from 'vue'
    import FacilityBooleanRow from './FacilityBooleanRow.vue'

    export default {
	    props: [],
        components: {
            FacilityBooleanRow
        },
	    setup(props) {
            const listing = inject('listing')
            const energyLabels = ref([
                'A+++++',
                'A++++',
                'A+++',
                'A++',
                'A+',
                'A',
                'B',
                'C',
                'D',
                'E',
                'F',
                'G'
            ])

            const insulationsTypes = ref([
                {"name": "roof_insulation"},
                {"name": "double_glazing"},
                {"name": "insulated_walls"},
                {"name": "floor_insulation"},
                {"name": "completely_insulated"}
            ])

            const heatingTypes = ref([
                {"name": "communal_central_heating"},
                {"name": "ch_boiler"},
                {"name": "floor_heating"},
                {"name": "heat_pump"},
                {"name": "wood_heater"},
                {"name": "wall_heating"},
                {"name": "fireplace"},
                {"name": "district_heating"}
            ])

            const hotWaterTypes = ref([
                'communal_central_heating',
                'ch_boiler',
                'electrical_boiler'
            ])

            const validate = () => {

            }

            return {
                listing,
                energyLabels,
                insulationsTypes,
                heatingTypes,
                hotWaterTypes,
                validate
            }
        }
    }
</script>
