<template>
    <div class="w-full h-0 flex flex-grow">
        <Transition enter-from-class="opacity-0 -translate-x-full"
                                    enter-to-class="opacity-100 translate-x-0"
                                    enter-active-class="transform transition ease-out duration-150 delay-200"
                                    leave-active-class="transform transition ease-in duration-150"
                                    leave-from-class="opacity-100 translate-x-0"
                                    leave-to-class="opacity-0 -translate-x-full" appear>

            <div v-if="innerWidth > 768 || showingTickets" class="2xl:w-1/5 xl:w-1/4 w-full h-full border-r p-5 overflow-y-auto lg:space-y-0 space-y-5 lg:static fixed bg-white">
                <div class="lg:hidden">
                    <a href="/" class="flex justify-center">
                        <img src="/images/logo.svg" class="w-2/5" />
                    </a>
                </div>

                <loading v-if="loading" />

                <ul v-if="!loading && tickets" class="divide-y">
                    <li v-for="ticket in tickets.data" class="py-2">
                        <div class="flex space-x-3 items-center rounded-lg p-3 hover:bg-gray-100 hover:cursor-pointer" v-bind:class="{'bg-gray-100': selectedTicket && ticket.id === selectedTicket.id}" @click="selectTicket(ticket)">
                            <div>
                                <div class="w-12 h-12 rounded-full bg-gray-600 flex items-center justify-center text-white">
                                    <i class="fi fi-rr-user flex items-center text-xl"></i>
                                </div>
                            </div>
                            <div class="w-full">
                                <h3 class="text-gray-500 text-sm">
                                    <span v-if="ticket.user_id == user.id">{{ ticket.listing.user.first_name }}</span>
                                    <span v-else>{{ ticket.first_name }}</span>
                                </h3>
                                <div class="text-gray-800 text-sm">
                                    {{ ticket.last_message.message.substring(0, 50) }}
                                </div>
                                <div class="text-xs text-gray-400">{{ dayjs(ticket.last_message_created_at).fromNow() }}</div>
                            </div>
                            <div v-if="!ticket.last_message.is_read" class="flex-none w-2">
                                <div class="w-2 h-2 bg-blue-500 rounded-full"></div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </Transition>

        <MessengerChatWindow class="2xl:w-3/5 xl:w-3/4 w-full" v-model="selectedTicket" @showTickets="showingTickets = true" @showInfo="showingInfo = true" />

        <Transition enter-from-class="opacity-0 translate-x-full"
                                    enter-to-class="opacity-100 translate-x-0"
                                    enter-active-class="transform transition ease-out duration-150 delay-200"
                                    leave-active-class="transform transition ease-in duration-150"
                                    leave-from-class="opacity-100 translate-x-0"
                                    leave-to-class="opacity-0 translate-x-full" appear>
            <div v-if="(innerWidth > 768 || showingInfo) && selectedTicket && selectedTicket.listing" class="2xl:w-1/4 w-full lg:static fixed bg-white h-full lg:border-l">
                <button class="lg:hidden absolute top-5 right-5 text-2xl bg-white w-10 h-10 rounded-full items-center shadow-lg text-red-500" @click="showingInfo = false">
                    <i class="fi fi-rr-cross-circle flex items-center justify-center"></i>
                </button>
                <div class="w-full h-64">
                    <img src="/images/blank_image.png" class="w-full h-full object-cover" />
                </div>

                <div class="p-3 space-y-5">
                    <h2 class="font-semibold text-xl">{{ selectedTicket.listing.title }}</h2>

                    <ul class="flex justify-center space-x-8">
                        <li class="text-sm flex flex-col items-center space-y-2">
                            <a :href="selectedTicket.listing.url" target="_blank" class="block flex items-center justify-center w-10 h-10 rounded-full border-primary border text-primary hover:bg-primary hover:text-white">
                                <i class="fi fi-rr-home flex items-center justify-center"></i>
                            </a>
                            <div class="text-fourthly">View Listing</div>
                        </li>

                        <li class="text-sm flex flex-col items-center space-y-2">
                            <button class="w-10 h-10 rounded-full border-primary border text-primary hover:bg-primary hover:text-white">
                                <i class="fi fi-rr-heart items-center flex justify-center"></i>
                            </button>
                            <div class="text-fourthly">Save</div>
                        </li>
                        <li class="text-sm flex flex-col items-center space-y-2">
                            <button class="w-10 h-10 rounded-full border-primary border text-primary hover:bg-primary hover:text-white">
                                <i class="fi fi-rr-share flex items-center justify-center"></i>
                            </button>
                            <div class="text-fourthly">Share</div>
                        </li>
                    </ul>

                    <!-- <button v-if="selectedTicket.listing.user_id === user.id" class="w-full border rounded-lg p-3 text-primary hover:border-primary hover:font-semibold text-left flex justify-between items-center select-none">
                        <span>Update Status</span>

                        <i class="fi fi-rr-angle-small-down flex items-center"></i>
                    </button> -->
                </div>

            </div>
        </Transition>
    </div>
</template>


<script>
	import { ref, inject } from 'vue'
    import { useApi } from '../lib/api'
    import dayjs from 'dayjs'
    import relativeTime from 'dayjs/plugin/relativeTime';
    import MessengerChatWindow from './MessengerChatWindow.vue'
    import { useUser } from '../lib/user';

    export default {
	    props: ['reference'],
        components: {
            MessengerChatWindow
        },
	    setup(props) {
            const selectedTicket = ref(null)
            const tickets = ref(null)
            const { user } = useUser()
            const showingTickets = ref(true)
            const showingInfo = ref(false)
            const innerWidth = ref(0)

            dayjs.extend(relativeTime)

            const { get, data, loading } = useApi('tickets')

            get().then(() => {
                if(data.value.status) {
                    tickets.value = data.value.tickets

                    if(props.reference) {
                        selectTicket(tickets.value.data.find(t => t.reference === props.reference))
                    }
                }
            })

            const selectTicket = (ticket) => {
                selectedTicket.value = ticket
                showingTickets.value = false
            }

            return {
                tickets,
                loading,
                selectedTicket,
                dayjs,
                user,
                showingTickets,
                showingInfo,
                innerWidth,
                selectTicket
            }
		},
        mounted() {
            var vm = this

            window.onresize = function(event) {
                vm.innerWidth = (event.srcElement || event.currentTarget).innerWidth
            }

            this.innerWidth = window.innerWidth
        }
	}
</script>
