import { ref } from 'vue'

const selectedCategory = ref(null)

export const useHomeListings = () => {
    const selectCategory = (category) => {
        if(selectedCategory.value && selectedCategory.value.id === category.id) {
            selectedCategory.value = null
            return null
        }

        selectedCategory.value = category
    }

    return {
        selectCategory,
        selectedCategory
    }
}
