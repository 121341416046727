<template>
    <div>
        <dialog class="block relative w-full z-50 p-0 z-10" v-if="modalShown">
            <div class="md:p-7 flex justify-center items-start overflow-x-hidden overflow-y-auto fixed left-0 top-0 w-full h-full bg-gray-900 bg-opacity-50 z-50">
                <div class="flex 2xl:w-1/3 xl:w-1/2 md:w-2/3 w-full relative items-center">
                    <div class="bg-white w-full md:rounded-xl shadow-xl relative z-10">
                        <div class="flex flex-col items-start w-full">
                            <div class="md:px-5 md:py-5 p-3 flex items-center w-full border-b">
                                <button @click="modalShown = false" class="w-8 h-8 hover:bg-gray-100 rounded-full flex justify-center items-center">
                                    <i class="fi fi-rr-cross-small text-xl flex items-center"></i>
                                </button>

                                <div class="text-center text-lg font-bold w-full">
                                    Join Realtor
                                </div>
                            </div>

                            <div class="w-full p-5">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </dialog>
    </div>
</template>

<script>
	import { ref, provide } from 'vue'

    export default {
	    props: [],
        components: {
        },
	    setup(props) {
            const modalShown = ref(false)

            const openModal = () => {
                modalShown.value = true
            }

            return {
                modalShown,
                openModal
            }
		}
	}
</script>
