<template>
    <div>
        <div v-if="!loading && listings && listings.data.length === 0" class="w-full flex flex-col justify-center items-center space-y-5">
            <img src="/images/sakura/sad.png" class="h-64" />

            <div class="w-96 text-center space-y-5">
                <h3 class="text-3xl text-gray-500">ごめんなさい - Sorry</h3>

                <div class="text-lg text-gray-800">
                    Sorry, the selected filter combination has no results. Please try a different criteria.
                </div>
            </div>
        </div>

        <div class="grid 2xl:grid-cols-5 xl:grid-cols-4 md:grid-cols-3 gap-6">
            <div v-if="loading" v-for="x in 10" class="block space-y-2 animate-pulse ">
                <div class="rounded-lg overflow-hidden relative bg-gray-300 h-56">
                </div>

                <div class="bg-gray-300 h-6 rounded w-2/3"></div>

                <div class="space-x-5 flex">
                    <div class="w-9 h-9 bg-gray-300 rounded"></div>
                    <div class="w-9 h-9 bg-gray-300 rounded"></div>
                    <div class="w-9 h-9 bg-gray-300 rounded"></div>
                </div>
            </div>

            <listing-block v-if="!loading && listings" v-for="listing in listings.data" :listing="listing"></listing-block>

        </div>
    </div>
</template>

<script>
	import { ref } from 'vue'
    import ListingBlock from './ListingBlock.vue'
    import { useHomeListings } from '../lib/homeListings'
    import { useApi } from '../lib/api'

	export default {
	    props: [],
        components: {
            ListingBlock
        },
        watch: {
            selectedCategory(value){
                this.getListings(value)
            }
        },
	    setup(props) {
            const { selectedCategory } = useHomeListings()
            const { get, loading, data } = useApi('listings')

            const listings = ref(null)

            const getListings = (category = null) => {
                get({
                    featured: (category)? '' : 1,
                    category_id: (category)? category.id : '',
                    sort_by: 'latest'
                }).then(() => {
                    if(data.value.status) {
                        listings.value = data.value.listings
                    }
                })
            }

            return {
                selectedCategory,
                loading,
                getListings,
                listings
            }
		}
	}
</script>


