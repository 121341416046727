import { ref } from 'vue'
import { useApi } from './api'

const { get, data } = useApi('currencies')

const currencies = ref([])

get().then(() => {
    if(data.value.status) {
        currencies.value = data.value.countries
    }
})

export const useCurrencies = () => {
    return {
        currencies
    }
}
