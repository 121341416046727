<template>
    <div>
        <dialog class="block relative w-full z-50 p-0 z-10" v-if="showModal">
            <div class="md:p-7 flex justify-center items-start overflow-x-hidden overflow-y-auto fixed left-0 top-0 w-full h-full bg-gray-900 bg-opacity-50 z-50">
                <div class="flex 2xl:w-1/3 xl:w-1/2 md:w-2/3 w-full relative items-center">
                    <div class="bg-white w-full md:rounded-xl shadow-xl relative z-10">
                        <div class="flex flex-col items-start w-full">
                            <div class="md:px-5 md:py-5 p-3 flex items-center w-full border-b">
                                <button @click="showModal = false" class="w-8 h-8 hover:bg-gray-100 rounded-full flex justify-center items-center">
                                    <i class="fi fi-rr-cross-small text-xl flex items-center"></i>
                                </button>

                                <div class="text-center text-lg font-bold w-full">
                                    <span>Facilities</span>
                                </div>
                            </div>

                            <div class="w-full space-y-3 p-5">
                                <ul class="border rounded-lg divide-y">
                                    <FacilityQtyRow v-for="facility in facilities.filter(f => f.type === 'number')" :facility="facility" v-model="listing.features.layout.facilities" />
                                    <FacilityBooleanRow v-for="facility in facilities.filter(f => f.type === 'boolean')" :facility="facility" v-model="listing.features.layout.facilities" />
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </dialog>
    </div>
</template>

<script>
	import { ref, inject } from 'vue'
    import FacilityQtyRow from './FacilityQtyRow.vue'
    import FacilityBooleanRow from './FacilityBooleanRow.vue'

	export default {
	    props: [],
        components: {
            FacilityQtyRow,
            FacilityBooleanRow
        },
	    setup(props, { emit }) {
            const showModal = ref(false)
            const listing = inject('listing')

            const facilities = ref([
                {
                    type: 'number',
                    name: 'air_conditioning'
                },
                {
                    type: 'boolean',
                    name: 'alarm_installation'
                },
                {
                    type: 'number',
                    name: 'outdoor_awning'
                },
                {
                    type: 'boolean',
                    name: 'security_camera_system'
                },
                {
                    type: 'number',
                    name: 'elevator'
                },
                {
                    type: 'boolean',
                    name: 'mechanical_ventilation'
                },
                {
                    type: 'number',
                    name: 'rolldown_shutters'
                },
                {
                    type: 'number',
                    name: 'swimming_pool'
                },
                {
                    type: 'number',
                    name: 'fireplace'
                },
                {
                    type: 'boolean',
                    name: 'gated'
                },
                {
                    type: 'boolean',
                    name: 'beach_access'
                },
                {
                    type: 'boolean',
                    name: 'lake_access'
                },
                {
                    type: 'boolean',
                    name: 'resort_access'
                },
                {
                    type: 'boolean',
                    name: 'waterfront'
                },
                {
                    type: 'boolean',
                    name: 'backyard'
                },
                {
                    type: 'boolean',
                    name: 'outdoor_dining_area'
                },
                {
                    type: 'boolean',
                    name: 'outdoor_kitchen'
                },
                {
                    type: 'boolean',
                    name: 'patio_or_balcony'
                },
                {
                    type: 'number',
                    name: 'ev_charger'
                },
                {
                    type: 'boolean',
                    name: 'gym'
                },
            ])

            const openModal = () => {
                showModal.value = true
            }


            return {
                showModal,
                openModal,
                facilities,
                listing
            }
		}
	}
</script>
