import axios, { AxiosRequestConfig } from 'axios'
import { computed, ref, watch } from 'vue'

export const useApi = (endpoint: string) => {
    var endPoint = endpoint

    const api = axios.create({
        baseURL: '/api/web/v1'
    })

    const data = ref()
    const loading = ref(false)
    const error = ref()

    const setEndpoint = (endpoint: string) => {
        endPoint = endpoint
    }

    const post = (payload?: Record<string, any>) => {
        loading.value = true
        error.value = undefined

        return api
            .post(endPoint, payload)
            .then((res) => (data.value = res.data))
            .catch((e) => {
                error.value = e

                throw e
            })
            .finally(() => (loading.value = false))
    }

    const postData = (
        payload?: Record<string, any>,
        config?: Record<string, any>
    ) => {
        loading.value = true
        error.value = undefined

        if (!config) {
            config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }
        }

        return api
            .post(endPoint, payload, config)
            .then((res) => (data.value = res.data))
            .catch((e) => {
                error.value = e

                throw e
            })
            .finally(() => (loading.value = false))
    }

    const get = (query?: Record<string, any>, config?: AxiosRequestConfig) => {
        loading.value = true
        error.value = undefined

        let queryString = ''

        if (query) {
            queryString =
                '?' +
                Object.entries(query)
                    .map(
                        ([key, value]) =>
                            `${encodeURIComponent(key)}=${encodeURIComponent(
                                value
                            )}`
                    )
                    .join('&')
        }

        return api
            .get(endPoint + queryString, config)
            .then((res) => (data.value = res.data))
            .catch((e) => {
                error.value = e

                throw e
            })
            .finally(() => (loading.value = false))
    }

    // @ts-ignore
    const del = (query?: Record<string, any>) => {
        loading.value = true
        error.value = undefined

        let queryString = ''

        if (query) {
            queryString =
                '?' +
                Object.entries(query)
                    .map(
                        ([key, value]) =>
                            `${encodeURIComponent(key)}=${encodeURIComponent(
                                value
                            )}`
                    )
                    .join('&')
        }

        return api
            .delete(endPoint + queryString)
            .then((res) => (data.value = res.data))
            .catch((e) => {
                error.value = e

                throw e
            })
            .finally(() => (loading.value = false))
    }

    const errorMessage = computed(() => {
        console.log('?? compute', error.value)

        if (error.value) {
            return error.value.message
        }
    })

    const errorDetails = computed(() => {
        if (error.value && error.value.response) {
            return error.value.response.data.message
        }
    })

    const errorFields = computed(() => {
        if (error.value && Array.isArray(error.value.response.data.message)) {
            return (error.value.response.data.message as string[]).reduce(
                (acc: Record<string, any>, msg: string) => {
                    let [field] = msg.split(' ')

                    // TODO: Maximal...
                    if (field == 'maximal') field = 'dateOfBirth'

                    if (!acc[field]) {
                        acc[field] = []
                    }

                    acc[field].push(msg)

                    return acc
                },
                {}
            )
        }
    })

    const computedClasses = (key: string) => {
        if (errorFields.value?.hasOwnProperty(key)) {
            return ['border-red-600', 'bg-red-200', 'text-red-900']
        }
        return ['border-grey-600', 'bg-white', 'text-gray-900']
    }

    watch([error], () => {
        // If 401 Unauthorised, force user to buy a new subscription
        // if ( error.value.response.status === 401 && router ) {
        //     router.push('/login')
        // }
    })

    return {
        setEndpoint,
        loading,
        data,
        error,
        get,
        post,
        postData,
        del,
        errorMessage,
        errorDetails,
        errorFields,
        computedClasses,
    }
}

// export default api
