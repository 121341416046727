<template>
    <label class="flex space-x-3 items-center hover:cursor-pointer" @mouseover="isHovering = true" @mouseleave="isHovering = false">
        <div class="w-5 h-5 border-2 border-primary bg-primary bg-opacity-20 rounded flex justify-center items-center">
            <i v-if="isHovering && !checked.includes(value)" class="fi fi-br-check text-[11px] text-gray-400 h-full flex items-center"></i>
            <i v-if="checked.includes(value)" class="fi fi-br-check text-[11px] text-primary h-full flex items-center"></i>
        </div>
        <input type="checkbox" class="hidden" :value="value" v-model="checked" />
        <div class="space-x-2">
            <span>{{ displayValue }}</span>
            <span v-if="count" class="text-gray-500 text-xs">({{ count }})</span>
        </div>
    </label>
</template>

<script>
	import { ref } from 'vue'

    export default {
	    props: ['value', 'displayValue', 'count', 'modelValue'],
        watch: {
            '$props.modelValue'(value) {
                this.checked = value
            },
            checked(value) {
                this.$emit('update:modelValue', value)
            }
        },
	    setup(props) {
            const isHovering = ref(false)
            const checked = ref(props.modelValue)

            return {
                isHovering,
                checked
            }
		}
	}
</script>
