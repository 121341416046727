<template>
    <div @click.stop.prevent="toggleFavourite">
        <slot v-if="!loading && !saved" name="is-not-saved"></slot>
        <slot v-if="!loading && saved" name="is-saved"></slot>

        <loading v-if="loading" />
    </div>
</template>

<script>
    import { watch, ref } from 'vue'
    import { useApi } from '../lib/api'
    import { useUser } from '../lib/user'

    export default {
        props: ['listing_id'],
        watch: {
            'user.listing_favourite_ids'(value) {
                if(value.length) {
                    this.saved = value.includes(parseInt(this.$props.listing_id))
                }
            }
        },
        setup(props, { emit }){
            const { post, data, loading } = useApi(`/me/listing/${ props.listing_id }/toggle-favourite`)
            const { user, getUser } = useUser()

            const saved = ref(false)

            const toggleFavourite = () => {

                post().then(() => {
                    if(data.value.status) {
                        getUser()

                        saved.value = data.value.attached
                    }
                })
            }

            if(user.value && user.value.listing_favourite_ids) {
                saved.value = user.value.listing_favourite_ids.includes(parseInt(props.listing_id))
            }

            return {
                toggleFavourite,
                saved,
                loading,
                user
            }
        }
    }
</script>
