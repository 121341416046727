export function useHelpers(){
    const price = (price: any, currency: any = null) => {

        if(currency) {
            return `${currency.symbol_icon} ${ ('' + parseFloat(price).toFixed(0)).replace(
                /(\d)(?=(?:\d{3})+(?:\.|$))|(\.\d\d?)\d*$/g,
                function(m, s1, s2){
                  return s2 || (s1 + currency.thousand_separator);
                }
              ) }`
        }

        return parseFloat(price).toFixed(0)
    }

    const emailValidate = (email: string) => {
        const regexp= /^[\w.%+-]+@[\w.-]+\.[\w]{2,6}$/;
        return regexp.test(email);
    }

    const slug = (Text: string) => {
        return Text
            .toLowerCase()
            .replace(/[^\w ]+/g,'')
            .replace(/ +/g,'-');
    }

    const updateQueryStringParameter = (uri: string, key: string, value: string) => {
        var escaped_key = key.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')
        var re = new RegExp("([?&])" + escaped_key + "=.*?(&|$)", "i");
        var separator = uri.indexOf('?') !== -1 ? "&" : "?";

        if (uri.match(re)) {
            return uri.replace(re, '$1' + key + "=" + value + '$2');
        }

        return uri + separator + key + "=" + value;
    }

    const updateWindowLocation = (param: string, value: string) => {
        var fullUrl = window.location.pathname + window.location.search

        fullUrl = updateQueryStringParameter(fullUrl, param, value)

        window.history.pushState('page', 'page', fullUrl);
    }

    const removeParam = (key: string)  => {
        const sourceURL = window.location.pathname + window.location.search
        var rtn = sourceURL.split("?")[0], param, params_arr = [], queryString = (sourceURL.indexOf("?") !== -1) ? sourceURL.split("?")[1] : "";

        if (queryString !== "") {
            params_arr = queryString.split("&");

            for (var i = params_arr.length - 1; i >= 0; i -= 1) {
                param = params_arr[i].split("=")[0];

                if (param === key) {
                    params_arr.splice(i, 1);
                }
            }
            rtn = rtn + "?" + params_arr.join("&");
        }

        window.history.pushState('page', 'page', rtn);

        return rtn;
    }

    return {
        emailValidate,
        price,
        slug,
        updateQueryStringParameter,
        updateWindowLocation,
        removeParam
    }
}
