<template>
    <div>
        <div v-if="!userWantSpecify" class="w-full h-full">
            <select class="w-full h-full bg-white px-2 text-sm appearance-none focus:outline-none hover:cursor-pointer select-none" v-model="selectedValue">
                <option value="specify">Specify</option>
                <option v-for="option in options" :value="option.value">{{ option.displayValue }}</option>
                <option v-if="addNoMax" value="">No Max</option>
            </select>
            <i class="fi fi-rr-angle-small-down absolute right-2 translate-y-1/2 text-primary text-xl"></i>
        </div>

        <div v-else class="w-full h-full">
            <input ref="specifyInput" class="w-full h-full focus:outline-none px-2 text-sm" v-model="selectedValue" />
            <button class="absolute right-2 inset-y-0" @click="userWantSpecify = false">
                <i class="fi fi-rr-cross-circle text-gray-500 text-xl flex items-center"></i>
            </button>
        </div>
    </div>
</template>

<script>
	import { ref } from 'vue'

    export default {
	    props: ['addNoMax', 'options', 'modelValue'],
        watch: {
            selectedValue(value) {
                if(value === 'specify') {
                    this.userWantSpecify = true
                    this.selectedValue = ''
                }

                this.$emit('update:modelValue', value)
            },
            specifyInput(value) {
                if(this.userWantSpecify) {
                    this.$refs.specifyInput.focus()
                }
            }
        },
	    setup(props) {
            const selectedValue = ref(props.modelValue ?? '')
            const userWantSpecify = ref(false)
            const specifyInput = ref(null)

            if(!props.options.find(o => o.value == selectedValue.value)) {
                userWantSpecify.value = true
            }

            return {
                selectedValue,
                specifyInput,
                userWantSpecify
            }
		}
	}
</script>
