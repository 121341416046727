import { ref, computed } from 'vue'
import { useApi } from './api'
import { useFormValidator } from './formValidator'

const { post, data, setEndpoint, loading } = useApi('listing/create')
const { validationErrors, resetErrors } = useFormValidator()

const listing = ref({
    id: null,
    type: {
        name: null
    },
    address: {
        location: null,
        location_id: null,
        street: null,
        house_number: null,
        apartment: null,
        city: null,
        zipcode: null,
        district: null,
        state: null,
        country_id: null,
        country: {
            iso_code_2: null,
            iso_code_3: null
        },
        coordinates: {
            coordinates: []
        }
    },
    status: null,
    currency: {
        id: 1,
        name: 'us_dollar',
        decimal_separator: '.',
        thousand_separator: ',',
        icon: 'united-states.svg',
        symbol_icon: '$',
        symbol: 'USD'
    },
    price: null,
    features: {
        construction: {
            property_type: null,
            property_orientation: null,
            type: null,
            parking_capacity: null,
            building_type: null,
            year_of_construction: null,
            specific: null,
            type_of_roof: null,
            roof_cover: null
        },
        areas: {
            living_area: null,
            exterior_space_attached_to_the_building: null,
            external_storage_space: null,
            plot_size: null,
            volume_in_cubic_meters: null,
            dimensions: {
                deep: null,
                width: null
            }
        },
        layout: {
            numbers_of_rooms: null,
            numbers_of_bedrooms: null,
            numbers_of_bath_rooms: null,
            bathroom_facilities: [],
            numbers_of_stories: null,
            has_basement: false,
            facilities: [],
            dimension_deep: null,
            dimension_width: null
        },
        energy: {
            energy_label: null,
            insulations: [],
            heatings: [],
            hot_water: null
        },
        exterior: {
            location_features: [],
            garden_features: [],
            garden_direction: [],
            garden_accessable_from_rear: null,
            back_garden_dimensions: {
                deep: null,
                width: null
            },
            balcony_roof_features: []
        },
        storage: {
            types: []
        },
        garage: {
            locations: [],
            capacity: null,
            insulations: [],
            facilities: []
        },
        parking: {
            facilities: []
        }
    },
    settings: [],
    photos: [],
    infos: [{
        language: {
            id: 1,
            name: 'English',
            iso: 'en',
            locale: 'en',
            icon: 'united-kingdom'
        },
        language_id: 1,
        title: null,
        body: ''
    }]
})

const stepView = ref(null)
const step = ref(1)
const isNew = ref(true)
const steps = ref([
    'type_of_listing',
    'address',
    'asking_price',
    'description',
    'photos',
    'construction_features',
    'areas_features',
    'layout_features',
    'energy_features',
    'exterior_features',
    'storage_features',
    'garage_features',
    'parking_features'
])

const stepPercentage = computed(() => {
    return step.value / steps.value.length * 100 ;
})

const currentStep = computed(() => {
    return steps.value[step.value - 1]
})

const resetSteps = (() => {
    steps.value = [
        'type_of_listing',
        'address',
        'asking_price',
        'description',
        'photos',
        'construction_features',
        'areas_features',
        'layout_features',
        'energy_features',
        'exterior_features',
        'storage_features',
        'garage_features',
        'parking_features'
    ]
})

const submitListing = (isFinal: boolean) => {
    return post({
        listing: listing.value,
        is_final: isFinal
    })
}

const nextStep = (isFinal: boolean = false) => {
    resetErrors()

    stepView.value?.validate()

    if(validationErrors.value.length === 0) {
        submitListing(isFinal).then(() => {
            if(data.value.status) {
                if(isNew.value) {
                    listing.value.id = data.value.listing.id
                    setEndpoint(`listing/${ data.value.listing.id }/update-create-listing`)
                    window.history.pushState({}, `Edit Listing ${ data.value.listing.id }`, `/listing/new/${ data.value.listing.id }`);
                    isNew.value = false
                }

                if(data.value.listing.status.name === 'available') {
                    window.location.href = "/user/account/listings"

                    return
                }

                step.value += 1
            }
        })
    }
}

const editListing = (l) => {
    isNew.value = false
    listing.value = l

    if(listing.value.address === null) {
        listing.value.address = {
            location: null,
            location_id: null,
            street: null,
            house_number: null,
            apartment: null,
            city: null,
            zipcode: null,
            district: null,
            state: null,
            country_id: null,
            country: {
                iso_code_2: null,
                iso_code_3: null
            },
            coordinates: {
                coordinates: []
            }
        }
    }
    if(listing.value.infos.length === 0) {
        listing.value.infos.push({
            language: {
                id: 1,
                name: 'English',
                iso: 'en',
                locale: 'en',
                icon: 'united-kingdom.svg'
            },
            language_id: 1,
            title: null,
            body: ''
        })
    }

    setEndpoint(`listing/${ l.id }/update-create-listing`)
}

export const useListingEditor = () => {
    return {
        isNew,
        steps,
        step,
        setEndpoint,
        stepPercentage,
        currentStep,
        stepView,
        resetSteps,
        validationErrors,
        resetErrors,
        nextStep,
        loading,
        listing,
        editListing
    }
}
