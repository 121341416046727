import './bootstrap';

import { createApp } from 'vue'
import { i18nVue } from 'laravel-vue-i18n'

import Loading from './components/Loading.vue'
import WideTopBar from './components/WideTopBar.vue'
import DefaultTopBar from './components/DefaultTopBar.vue'
import ListingBlock from './components/ListingBlock.vue'
import AuthModal from './components/Auth/AuthModal.vue'
import ListingEditor from './components/ListingEditorWizard/ListingEditor.vue'
import SearchFilterModal from './components/SearchFilterModal.vue'
import CKEditor from '@ckeditor/ckeditor5-vue';
import Logo from './components/Logo.vue';
import AuthForm from './components/Auth/AuthForm.vue';
import PersonalInfo from './components/Account/PersonalInfo.vue'
import ToggleSwitch from './components/ToggleSwitch.vue';
import CreateRealtorProfileModal from './components/CreateRealtorProfileModal.vue'
import JoinRealtorModal from './components/JoinRealtorModal.vue'
import LanguageSelectModal from './components/LanguageSelectModal.vue'
import CurrencySelectModal from './components/CurrencySelectModal.vue'
import HomeFooter from './components/HomeFooter.vue'
import AccountListings from './components/Account/AccountListings.vue'
import HomeListingsGrid from './components/HomeListingsGrid.vue'
import MobileBottomBar from './components/MobileBottomBar.vue'
import ImageCarousel from './components/ImageCarousel.vue'
import AddressMap from './components/AddressMap.vue'
import GeneralListingsGrid from './components/GeneralListingsGrid.vue'
import ListingPhotoGalleryOverview from './components/ListingPhotoGalleryOverview.vue'
import ContactSellerModal from './components/ContactSellerModal.vue'
import ToggleListingFavourite from './components/ToggleListingFavourite.vue'
import ListingFavourites from './components/ListingFavourites.vue'
import SearchResults from './components/SearchResults.vue'
import Messenger from './components/Messenger.vue'
import ClaimAccountForm from './components/ClaimAccountForm.vue'
import LocaleSelectModal from './components/LocaleSelectModal.vue'
import PriceInput from './components/PriceInput.vue'
import BuyHouseInCountrySearchBox from './components/BuyHouseInCountrySearchBox.vue'

import { useUser } from './lib/user';
import { usePreferences } from './lib/preferences';
import { useHomeListings } from './lib/homeListings'
import { useAuth } from './lib/auth';

createApp({
    components: {
        WideTopBar,
        DefaultTopBar,
        ListingBlock,
        AuthModal,
        ListingEditor,
        SearchFilterModal,
        Logo,
        AuthForm,
        PersonalInfo,
        ToggleSwitch,
        CreateRealtorProfileModal,
        JoinRealtorModal,
        LanguageSelectModal,
        CurrencySelectModal,
        HomeFooter,
        AccountListings,
        HomeListingsGrid,
        MobileBottomBar,
        ImageCarousel,
        AddressMap,
        GeneralListingsGrid,
        ListingPhotoGalleryOverview,
        ContactSellerModal,
        ToggleListingFavourite,
        ListingFavourites,
        SearchResults,
        Messenger,
        ClaimAccountForm,
        LocaleSelectModal,
        BuyHouseInCountrySearchBox,
        PriceInput,
    },
    setup() {
        const { user } = useUser()
        const { selectedCategory } = useHomeListings()
        const { showAuthModal } = useAuth()
        const { preferences } = usePreferences()

        return {
            user,
            preferences,
            selectedCategory,
            showAuthModal
        }
    }
})
.component('Loading', Loading)
.use(CKEditor)
.use(i18nVue, {
    resolve: async lang => {
        const langs = import.meta.glob('../../lang/*.json');
        return await langs[`../../lang/${lang}.json`]();
    }
})
.mount('#app')
