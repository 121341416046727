<template>
    <div>
        <a :href="listing.url" class="block space-y-2">
            <div class="rounded-lg overflow-hidden relative h-56">
                <div class="absolute top-0 p-3 flex w-full items-center" v-bind:class="{'justify-between': listingIsNew || listing.status.name != 'available', 'justify-end': !listingIsNew || listing.status.name != 'available'}">

                    <div v-if="listingIsNew && listing.status.name == 'available'" class="bg-primary text-white p-1 rounded text-xs shadow-xl">{{ $t('listing.block.new_listing') }}</div>
                    <div v-if="listing.status.name != 'available'" class="p-1 rounded text-xs shadow-xl text-white" :style="`background-color:${ listing.status.color }`">{{ $t(`statuses.${ listing.status.name }`) }}</div>

                    <toggle-listing-favourite v-if="user" ref="toggleListingFavourite" :listing_id="listing.id">
                        <template v-slot:is-not-saved>
                            <button class="text-white text-2xl hover:text-red-500"><i class="fi fi-rr-heart"></i></button>
                        </template>

                        <template v-slot:is-saved>
                            <button class="text-2xl text-red-500"><i class="fi fi-rr-heart"></i></button>
                        </template>
                    </toggle-listing-favourite>
                </div>

                <div class="absolute w-full bottom-0 h-1/4">
                    <div class="w-full h-full opacity-80 bottom-0  bg-gradient-to-t from-black to-transparent p-3"></div>
                    <div class="font-bold text-white text-lg absolute bottom-3 left-3">{{ listing.display_price }}</div>
                </div>

                <img v-if="listing.photos.length > 0" :src="listing.photos[0].image_url" class="w-full h-full object-cover" />
                <img v-else src="/images/blank_image.png" class="w-full h-full object-cover" />
            </div>

            <h2 class="font-semibold h-12 overflow-hidden">{{ listing.title }}</h2>
            <ul class="text-sm flex space-x-8">
                <li class="text-center" v-if="listing.features.areas.living_area> 0">
                    <i class="fi fi-rr-draw-square block"></i>
                    <span>{{ listing.features.areas.living_area }} m²</span>
                </li>
                <li class="text-center" v-if="listing.features.areas.plot_size > 0">
                    <i class="fi fi-rr-border-all block"></i>
                    <span>{{ listing.features.areas.plot_size }} m²</span>
                </li>
                <li class="text-center" v-if="listing.features.layout.numbers_of_bedrooms > 0">
                    <i class="fi fi-rr-bed-alt block"></i>
                    <span>{{ listing.features.layout.numbers_of_bedrooms }}</span>
                </li>
            </ul>
        </a>
    </div>
</template>


<script>
	import { ref } from 'vue'
    import dayjs from 'dayjs'
    import { useHelpers } from '../lib/helpers'
    import ToggleListingFavourite from './ToggleListingFavourite.vue'
    import { useUser } from '../lib/user'

	export default {
	    props: ['listing'],
        components: {
            ToggleListingFavourite
        },
	    setup(props) {
            const listingIsNew = ref(dayjs().diff(props.listing.created_at, 'day') < 5)
            const { user } = useUser()

            const { price, slug } = useHelpers()

            return {
                user,
                dayjs,
                listingIsNew,
                price,
                slug
            }
		}
	}
</script>
