<template>
    <div>
        <Maps height="100%" width="100%" :disableUI="false" ref="maps" :zoom="14" :center="{
            lat: 0,
            lng: 0
        }" @mapMounted="mapMounted">
            <AdvanceMarker :coordinates="coordinates" />
        </Maps>
    </div>
</template>

<script>
	import { ref, computed } from 'vue'
    import Maps from './Maps/Maps.vue'
    import AdvanceMarker from './Maps/AdvanceMarker.vue'

    export default {
	    props: ['address'],
        components: {
            Maps,
            AdvanceMarker
        },
	    setup(props) {
            const maps = ref(null)

            const coordinates = computed(() => {
                if(props.address.location) {
                    return {
                        lat: props.address.location.coordinates.coordinates[1],
                        lng: props.address.location.coordinates.coordinates[0]
                    }
                }

                return {
                    lat: props.address.coordinates.coordinates[1],
                    lng: props.address.coordinates.coordinates[0]
                }
            })

            const mapMounted = () => {
                if(props.address.location) {
                    maps.value.googleMap.setBounds(props.address.location)

                    return
                }

                maps.value.googleMap.setCenter(coordinates.value)
            }

            return {
                maps,
                coordinates,
                mapMounted
            }
		}
	}
</script>
