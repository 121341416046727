<template>
    <div v-if="ticket" class="h-full flex flex-col">
        <div class="w-full px-3 py-5 border-b flex items-center justify-between">
            <div class="flex items-center space-x-3">
                <button class="lg:hidden" @click="$emit('showTickets')">
                    <i class="fi fi-rr-menu-burger flex items-center"></i>
                </button>
                <div class="w-8 h-8 rounded-full bg-gray-600 flex items-center justify-center text-white">
                    <i class="fi fi-rr-user flex items-center text-lg"></i>
                </div>
                <h2 class="font-bold text-xl">
                    <span v-if="ticket.user_id === user.id">{{ ticket.listing.user.first_name }}</span>
                    <span v-else>{{ ticket.first_name }}</span>
                </h2>
            </div>

            <button class="lg:hidden" @click="$emit('showInfo')">
                <i class="fi fi-rr-info flex items-center text-xl"></i>
            </button>
        </div>

        <div class="flex-1 flex flex-col">
            <div class="grow h-0 p-5 overflow-y-auto space-y-2 w-full" ref="chatWindow">
                <div ref="loadMore"></div>

                <div v-if="loading" class="h-full flex items-center justify-center">
                    <loading />
                </div>

                <div class="flex" v-bind:class="{'justify-end': user && ticket.user_id == user.id}">
                    <a :href="ticket.listing.url" target="_blank" class="lg:w-1/3 w-4/5 block rounded-lg overflow-hidden" v-bind:class="{'bg-blue-500': user && ticket.user_id != user.id, 'bg-thirdly': user && ticket.user_id == user.id}">
                        <div class="w-full h-44">
                            <img src="http://nearkeys.local/images/blank_image.png" class="w-full h-full object-cover" />
                        </div>

                        <div class="text-white p-3 space-y-2">
                            <h4 class="font-semibold">{{ ticket.listing.title }}</h4>
                            <div class="text-gray-100 text-sm">{{ ticket.listing.display_price }}</div>
                        </div>
                    </a>
                </div>


                <div v-if="messages" class="space-y-2">
                    <div v-for="(message, key) in sortMessages">
                        <TextMessage v-if="message.ticket_messagable_type === null"
                                :message="message"
                                v-bind:class="{'flex items-end justify-end': user && message.user_id == user.id, 'flex items-end space-x-1': !user || message.user_id != user.id}"
                                v-bind:bubbleColor="{'bg-thirdly': user && message.user_id == user.id, 'bg-blue-500': !user || message.user_id != user.id}" />

                        <AppointmentMessage v-if="message.ticket_messagable_type === 'App\\Models\\Appointment'" :message="message" v-bind:class="{'flex items-end justify-end': user && message.user_id == user.id, 'flex items-end space-x-1': !user || message.user_id != user.id}" v-bind:bubbleColor="{'bg-thirdly': user && message.user_id == user.id, 'bg-blue-500': !user || message.user_id != user.id}" :thisIsRealEstateAgent="user && ticket.listing.user_id == user.id" />
                        <!-- <div v-if="user && message.user_id == user.id" class="flex items-end justify-end">
                            <div class="bg-thirdly inline-block py-2 px-4 rounded-lg text-white whitespace-pre-wrap" v-html="message.message">
                            </div>
                        </div>

                        <div v-else class="flex items-end space-x-1">
                            <div v-if="user && sortMessages[key + 1] !== undefined && sortMessages[key + 1].user_id != user.id" class="w-6"></div>
                            <div v-else class="w-6 h-6 rounded-full bg-gray-600 flex items-center justify-center text-white">
                                <i class="fi fi-rr-user flex items-center"></i>
                            </div>
                            <div class="bg-blue-500 inline-block py-2 px-4 text-white whitespace-pre-wrap" v-html="message.message" v-bind:class="{'rounded-t-lg': messageIsTop(key, message), 'rounded-b-lg': messageIsBottom(key, message)}">
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>

            <NewMessageInput class="w-full h-16 p-3 flex items-center space-x-3" :ticket="modelValue" @messageSend="addMessage" />

        </div>
    </div>
</template>


<script>
    import { ref, computed } from 'vue'
    import { useApi } from '../lib/api'
    import { useUser } from '../lib/user'
    import NewMessageInput from './NewMessageInput.vue'
    import TextMessage from './TextMessage.vue'
    import AppointmentMessage from './AppointmentMessage.vue'

    export default {
	    props: ['modelValue'],
        components: {
            NewMessageInput,
            TextMessage,
            AppointmentMessage
        },
        watch: {
            '$props.modelValue'(value) {
                this.ticket = value
                this.messages = null
                this.loadable = true
                this.page = 1

                this.setEndpoint(`ticket/${ value.id }/messages`)

                this.getMessages()
            },
            loadMore(value) {
                if(value) {
                    let observer = new IntersectionObserver((entries) => {
                        entries.forEach((entry) => {
                            if(entry.intersectionRatio > 0 && !this.loading && this.messages) {

                                this.page = this.page + 1
                                this.getMessages()
                            }
                        })
                    })

                    observer.observe(value)
                }
            }
        },
	    setup(props) {
            const { user } = useUser()

            const ticket = ref(props.modelValue)
            const messages = ref(null)
            const chatWindow = ref(null)
            const loadMore = ref(null)
            const page = ref(1);
            const loadable = ref(true)

            const { get, data, loading, setEndpoint } = useApi(`ticket/${ (ticket.value)? ticket.value.id : 'null' }/messages`)

            const getMessages = () => {
                if(loadable.value) {
                    get({
                        page: page.value
                    }).then(() => {
                        if(data.value.status) {
                            loadable.value = data.value.messages.current_page !== data.value.messages.last_page

                            if(messages.value) {
                                messages.value.data = messages.value.data.concat(data.value.messages.data)

                                return
                            }

                            new Promise((resolve, reject) => {
                                messages.value = data.value.messages

                                resolve();
                            }).then(() => {
                                if(chatWindow.value) {
                                    chatWindow.value.scrollTo({ top: chatWindow.value.scrollHeight })
                                }
                            })
                        }
                    })
                }
            }

            if(ticket.value) {
                getMessages()
            }

            const messageIsTop = (key, message) => {

                if(messages.value.data[key - 1] === undefined) {
                    return true
                }

                if(messages.value.data[key - 1].user_id !== message.user_id) {
                    return true
                }

                return false
            }

            const messageIsBottom = (key, message) => {
                if(messages.value.data[key + 1] === undefined) {
                    return true
                }

                if(messages.value.data[key + 1].user_id !== message.user_id) {
                    return true
                }

                return false
            }

            const addMessage = (message) => {
                new Promise((resolve, reject) => {
                    messages.value.data.unshift(message)

                    resolve();
                }).then(() => {
                    if(chatWindow.value) {
                        chatWindow.value.scrollTo({ top: chatWindow.value.scrollHeight, behavior: "smooth" })
                    }
                })
            }

            const sortMessages = computed(() => {
                return [...messages.value.data].reverse()
            })

            return {
                ticket,
                loading,
                setEndpoint,
                messages,
                getMessages,
                user,
                messageIsTop,
                messageIsBottom,
                addMessage,
                sortMessages,
                chatWindow,
                page,
                loadMore,
                loadable
            }
		}
	}
</script>
