<template>
    <div>
        <div ref="listingsGrid" class="w-3/5 h-full border-r p-5 overflow-y-auto space-y-8">
            <button class="hover:bg-gray-100 h-12 w-12 rounded-full flex justify-center items-center">
                <i class="fi fi-rr-angle-small-left flex items-center text-xl"></i>
            </button>

            <div v-if="listingFavourites.length > 0" class="grid grid-cols-3 gap-4">
                <div ref="favouriteListings" class="p-3 rounded-md" v-for="listingFavourite in listingFavourites" v-bind:class="{'bg-gray-100 border border-gray-500': (focusListing && focusListing.id == listingFavourite.id)}">
                    <FavouriteListingBlock :listing="listingFavourite" :id="`listing_${ listingFavourite.id }`" />
                </div>
            </div>

            <div v-else class="flex flex-col justify-center items-center space-y-5">

                <img src="/images/sakura/searching.png" class="h-64" />

                <div class="lg:w-1/2 w-96 text-center space-y-5">
                    <h3 class="text-3xl text-gray-500">No favourite listings</h3>

                    <div class="text-lg text-gray-800">
                        You haven't added any properties to your favourites yet. Start exploring our listings to find your dream home and click the heart icon on listings you love to save them here!
                    </div>
                </div>
            </div>
        </div>
        <div class="w-2/5">
            <ListingsMap :listings="listingFavourites" @mouseover="selectFocusListing" @mouseout="focusListing = null" />
        </div>
    </div>
</template>

<script>
	import { ref, computed } from 'vue'
    import { useUser } from '../lib/user'
    import FavouriteListingBlock from './FavouriteListingBlock.vue'
    import ListingsMap from './ListingsMap.vue'

	export default {
	    props: [],
        components: {
            FavouriteListingBlock,
            ListingsMap
        },
	    setup(props) {
            const { listingFavourites, getListingFavourites } = useUser()
            const focusListing = ref(null)
            const listingsGrid = ref(null)

            getListingFavourites()

            const selectFocusListing = (listing) => {
                focusListing.value = listing
                listingsGrid.value.querySelector(`#listing_${ listing.id }`).scrollIntoView({behavior: 'smooth'})
            }

            return {
                listingFavourites,
                focusListing,
                selectFocusListing,
                listingsGrid
            }
		}
	}
</script>
