import { ref } from 'vue'
import { useApi } from './api'

const { get, data } = useApi('categories')

const categories = ref([])

get().then(() => {
    if(data.value.status) {
        categories.value = data.value.categories
    }
})

export const useCategories = () => {
    return {
        categories
    }
}
