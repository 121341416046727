<template>
    <div class="absolute w-full top-0">
        <footer class="w-full bg-white border-t fixed transition ease-in-out duration-300 md:h-11 bottom-0" v-bind:class="{'translate-y-full': !showing}">
            <div v-if="user" class="md:w-11/12 w-full mx-auto py-1 items-center justify-center md:space-y-0 flex space-x-2">
                <a href="/" class="block text-center px-2 py-1 w-24">
                    <i class="fi fi-rr-search text-primary text-xl"></i>
                    <div class="text-xs text-primary">Search</div>
                </a>

                <a href="/listing/new" class="block text-center px-2 py-1 w-24">
                    <i class="fi fi-rr-square-plus text-gray-500 text-xl"></i>
                    <div class="text-xs text-gray-500">Post Listing</div>
                </a>

                <a href="/user/account/messages" class="block text-center px-2 py-1 w-24">
                    <i class="fi fi-rr-messages text-gray-500 text-xl"></i>
                    <div class="text-xs text-gray-500">Inbox</div>
                </a>

                <button class="block text-center px-2 py-1 w-24" @click="showingUserMenu = true">
                    <i class="fi fi-rr-circle-user text-gray-500 text-xl"></i>
                    <div class="text-xs text-gray-500">My Account</div>
                </button>
            </div>

            <div v-else class="md:w-11/12 w-full mx-auto py-1 items-center justify-center md:space-y-0 flex space-x-2">
                <a href="/" class="block text-center px-2 py-1 w-24">
                    <i class="fi fi-rr-search text-primary text-xl"></i>
                    <div class="text-xs text-primary">Search</div>
                </a>

                <button @click="showAuthModal('/listing/new')" class="block text-center px-2 py-1 w-24">
                    <i class="fi fi-rr-square-plus text-gray-500 text-xl"></i>
                    <div class="text-xs text-gray-500">Post Listing</div>
                </button>

                <button @click="showAuthModal()" class="block text-center px-2 py-1 w-24">
                    <i class="fi fi-rr-circle-user text-gray-500 text-xl"></i>
                    <div class="text-xs text-gray-500">Login</div>
                </button>
            </div>
        </footer>

        <Transition enter-from-class="opacity-0 translate-y-5"
                enter-to-class="opacity-100 translate-y-0"
                enter-active-class="transform transition ease-out duration-200"
                leave-active-class="transform transition ease-in duration-150"
                leave-from-class="opacity-100 translate-y-0"
                leave-to-class="opacity-0 translate-y-5">

            <div v-if="showingUserMenu" class="bg-white w-full h-screen p-8 fixed overflow-y-auto z-[99]">
                <button class="border w-10 h-10 rounded-full flex items-center justify-center border-gray-500 text-gray-500 absolute top-8 right-8" @click="showingUserMenu = false">
                    <i class="fi fi-rr-cross-small flex items-center"></i>
                </button>

                <div class="space-y-8">
                    <h1 class="text-3xl font-semibold">Profile</h1>

                    <div class="space-y-3 divide-y">
                        <div class="flex space-x-3 items-center py-2">
                            <img src="/images/blank_image.png" class="w-12 h-12 rounded-full" />

                            <div>
                                <h3 class="text-xl">{{ user.first_name }}</h3>
                                <div class="text-sm text-gray-500">Your profile</div>
                            </div>
                        </div>

                        <div class="py-2 space-y-3">
                            <a class="flex items-center justify-between py-2" href="#">
                                <div class="flex space-x-2 items-center">
                                    <i class="fi fi-rr-search-heart flex items-center text-xl"></i> <h3 class="text-lg">Saved Searches</h3>
                                </div>

                                <i class="fi fi-rr-angle-small-right text-2xl"></i>
                            </a>

                            <a class="flex items-center justify-between py-2" href="#">
                                <div class="flex space-x-2 items-center">
                                    <i class="fi fi-rr-heart flex items-center text-xl"></i> <h3 class="text-lg">Saved Houses</h3>
                                </div>

                                <i class="fi fi-rr-angle-small-right text-2xl"></i>
                            </a>

                            <div class="flex items-center justify-between py-2">
                                <div class="flex space-x-2 items-center">
                                    <i class="fi fi-rr-settings flex items-center text-xl"></i> <h3 class="text-lg">Account</h3>
                                </div>

                                <i class="fi fi-rr-angle-small-right text-2xl"></i>
                            </div>
                        </div>

                        <div class="py-5">
                            <h2 class="text-2xl font-semibold">Real Estate</h2>

                            <div class="py-2 space-y-3">
                                <div class="flex items-center justify-between py-2">
                                    <div class="flex space-x-2 items-center">
                                        <i class="fi fi-rr-list flex items-center text-xl"></i> <h3 class="text-lg">Manage listings</h3>
                                    </div>

                                    <i class="fi fi-rr-angle-small-right text-2xl"></i>
                                </div>
                            </div>
                        </div>

                        <div class="py-5">
                            <h2 class="text-2xl font-semibold">Support</h2>

                            <div class="py-2 space-y-3">
                                <div class="flex items-center justify-between py-2">
                                    <div class="flex space-x-2 items-center">
                                        <i class="fi fi-rr-interrogation flex items-center text-xl"></i> <h3 class="text-lg">Visit Help Center</h3>
                                    </div>

                                    <i class="fi fi-rr-angle-small-right text-2xl"></i>
                                </div>
                            </div>
                        </div>

                        <div class="py-5">
                            <a href="/user/logout" class="block w-full border border-gray-700 rounded-lg text-center py-3">Logout</a>
                        </div>

                    </div>

                </div>

            </div>
        </Transition>

    </div>

</template>

<script>
	import { ref } from 'vue'
    import dayjs from 'dayjs'
    import { useScrollPosition } from '../lib/scrollPosition'
    import { useUser } from '../lib/user'
    import { useAuth } from '../lib/auth'

	export default {
	    props: ['locale', 'currency'],
        watch: {
            scrollPosition(value, oldValue) {
                if(value < oldValue) {
                    this.showing = true
                }

                if(value > oldValue) {
                    this.showing = false
                }
            },
            showingUserMenu(value) {
                if(value) {
                    this.showing = false
                    return
                }

                this.showing = true
            }
        },
	    setup(props) {
            const showing = ref(true)
            const showingUserMenu = ref(false)
            const { scrollPosition } = useScrollPosition()
            const { showAuthModal } = useAuth()
            const { user } = useUser()

			return {
                showing,
                dayjs,
                scrollPosition,
                user,
                showAuthModal,
                showingUserMenu
			}
		}
	}
</script>
