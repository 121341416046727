import { ref } from 'vue'


const scrollPosition = ref(0)

window.onscroll = () => {
    var B = document.body; //IE 'quirks'
    var D = document.documentElement; //IE with doctype
    D = (D.clientHeight)? D: B;

    scrollPosition.value = D.scrollTop
};

export const useScrollPosition = () => {

    return {
        scrollPosition
    }
}
