import { ref } from 'vue'

const authModalShown = ref(false)
const redirectTo = ref('/user/account')

export const useAuth = () => {
    const showAuthModal = (redirectAfterAuthUrl = null) => {
        authModalShown.value = true

        if(redirectAfterAuthUrl) {
            redirectTo.value = redirectAfterAuthUrl
        }
    }

    return {
        authModalShown,
        showAuthModal,
        redirectTo
    }
}
